<template>
    <div></div>
</template>

<script>
export default {
    name: 'GeoNoHeader'
};
</script>

<style scoped>

</style>
