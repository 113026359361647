<template>
    <div class='analytics-table-wrapper'>
        <div v-if='Object.keys(userItems).length !== 0'>
            <table class='table analytics-table'>
                <thead>
                <tr>
                    <th class='analytics-table_th'>Запросы</th>
                    <th>Всего, шт</th>
                    <th>Выполнены в срок
                        <el-tooltip
                            placement='top' effect='light' popper-class='analytics-table__popper'>
                            <div slot='content'>Учитываются решенные запросы и запросы<br>с истекшим сроком без решения
                                за период
                            </div>
                            <i v-bind:class="[isIssueTooltip ? 'svg-icon-help-full' : 'svg-icon-help']"
                               @mouseover='isIssueTooltip = true' @mouseout='isIssueTooltip = false'></i>
                        </el-tooltip>
                    </th>
                    <th>Проектный показатель</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Входящие</td>
                    <td>{{ userItems.issue_deadline_in }}</td>
                    <td v-html='getPercent(userItems.issue_not_expired_in, userItems.issue_deadline_in)'></td>
                    <td v-html='getPercent(projectItems.issue_not_expired_in, projectItems.issue_deadline_in)'></td>
                </tr>
                <tr>
                    <td>Исходящие</td>
                    <td>{{ userItems.issue_deadline_out }}</td>
                    <td v-html='getPercent(userItems.issue_not_expired_out, userItems.issue_deadline_out)'></td>
                    <td v-html='getPercent(projectItems.issue_not_expired_out, projectItems.issue_deadline_out)'></td>
                </tr>
                </tbody>
            </table>
            <table class='table analytics-table'>
                <thead>
                <tr>
                    <th class='analytics-table_th'>Задачи</th>
                    <th>Всего, шт</th>
                    <th>Выполнены в срок
                        <el-tooltip
                            placement='top' effect='light' popper-class='analytics-table__popper'>
                            <div slot='content'>Учитываются решенные задачи и задачи<br>с истекшим сроком без решения за
                                период
                            </div>
                            <i v-bind:class="[isRoomTooltip ? 'svg-icon-help-full' : 'svg-icon-help']"
                               @mouseover='isRoomTooltip = true' @mouseout='isRoomTooltip = false'></i>
                        </el-tooltip>
                    </th>
                    <th>Проектный показатель</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Где я участник</td>
                    <td>{{ userItems.room_closed }}</td>
                    <td v-html='getPercent(userItems.room_closed_planned, userItems.room_closed_planned + userItems.room_not_closed_planned)'></td>
                    <td v-html='getPercent(projectItems.room_closed_planned, projectItems.room_closed_planned + projectItems.room_not_closed_planned)'></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else><span class='form-label'>Нет данных</span></div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoAnalyticsTable',
    data() {
        return {
            isIssueTooltip: false,
            isRoomTooltip: false
        };
    },
    components: {},
    mixins: [Dates],
    computed: {},
    props: {
        userItems: {
            type: Object,
            required: true
        },
        projectItems: {
            type: Object,
            required: true
        }
    },
    async created() {
    },
    methods: {
        getPercent(x, y) {
            if (!y) {
                return 0;
            }
            let result = (x / y * 100).toFixed(1);
            return result <= 50 ? `<span class='danger'>${result}%</span>` :
                result < 70 ? `<span class='warninig'>${result}%</span>` : `<span class='success'>${result}%</span>`;
        }
    }
};
</script>

<style scoped>
.analytics-table-wrapper {

}

.analytics-table {
    width: 100%;
    border-collapse: separate;
    text-align: center;
    border-spacing: 0;
    margin-bottom: 20px;
}

.analytics-table thead {
    font-weight: 700;
    font-size: 20px;
}

.analytics-table tr th, tr td {
    padding: 16px;
    border-right: 1px solid #CCD9E0;
    border-bottom: 1px solid #CCD9E0;
}

.analytics-table tr th {
    border-top: 1px solid #CCD9E0;
}

.analytics-table tr th:first-child, tr td:first-child {
    border-left: 1px solid #CCD9E0;
}

.analytics-table tr:first-child th:first-child {
    border-top-left-radius: 8px;
}

.analytics-table tr:first-child th:last-child {
    border-top-right-radius: 8px;
}

.analytics-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.analytics-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.analytics-table_th {
    min-width: 300px;
}

.analytics-table_th {
    min-width: 300px;
}

>>> .analytics-table .danger {
    color: #EB3333;
}

>>> .analytics-table .warning {
    color: #FFA10A;
}

>>> .analytics-table .success {
    color: #09B37B;
}
.svg-icon-help {
    transition: 0.1s;
    transition-delay: 0.3s;
}
</style>
