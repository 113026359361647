<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Новая задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='form-label'>ПАРАМЕТРЫ ЗАДАЧИ</div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Кластер</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='cluster'
                        placeholder=''
                        required='required'
                        :value='selectedCluster.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Проект</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='project'
                        placeholder=''
                        required='required'
                        :value='selectedProject.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'><span>Лицензионный участок</span><em>*</em> </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='licenceArea'
                        placeholder=''
                        required='required'
                        :value='selectedLicenceArea.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'><span>Скважина</span><em>*</em> </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='well'
                        placeholder=''
                        required='required'
                        :value='selectedWell.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Наименование</span><em>*</em> </label>
                    <input
                        v-model='model.title'
                        class='input-block__input'
                        type='text'
                        name='name'
                        placeholder=''
                        required='required'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Тип работ</span><em>*</em> </label>
                    <el-select v-model="model.type_of_research" filterable>
                        <el-option v-for="item in typesOfResearch" :key="item.id" :label="item.full_name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class='popup__buttons with-border'>
                <button class='button small secondary' type='button' @click='closeModal'>
                    <span>Назад</span>
                </button>
                <button class='button small' type='submit' @click='nextStep' :disabled='isDisabled'>
                    <span>Далее</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters, mapState} from 'vuex';
import {getterTypes as modalGetterTypes, mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';

export default {
    name: 'GeoTaskModalStep1',
    data() {
        return {

        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            typesOfResearch: state => state.guides?.typesOfResearch?.results || [],
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject,
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            selectedWell: state => state.lists?.selectedWell,
        }),
        ...mapGetters({
            isWellModalEdit: modalGetterTypes.wellModalEdit
        }),
        isDisabled: function() {
            return !(this.model.title && this.model.type_of_research);
        }
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getTypesOfResearch);
    },
    methods: {
        nextStep() {
            this.$emit('setStep', 'step2')
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.geoTaskModalClose);
        }
    }
};
</script>
