<template>
    <header class="header">
        <div class="site-logo"> <div class="site-logo__link" href="#">GEO<span>Профиль</span></div>
        </div>
        <div class="header__right"><span class="button small alert" @click='logout'><span>Выйти</span></span>
        </div>
    </header>
</template>

<script>

import {actionTypes} from '@/store/modules/auth';

export default {
    name: 'GeoProfileHeader',
    computed: {

    },
    methods: {
        logout() {
            this.$store.dispatch(actionTypes.logout).then(() => {
                window.localStorage.removeItem('vuex');
                this.$router.push('/');
            });
        }
    }
};
</script>

<style scoped>

</style>
