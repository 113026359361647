<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3 warning'>
                <i class="el-icon-back" @click="closeInfo" style='cursor: pointer'></i>
                &nbsp;Медиа
            </div>
        </div>
        <div class='log-information__wrap'>
            <div class='col-3' v-for='media in roomMedia' :key='media.id'>
                <div class='log-information__image-item' :style='{
                                    backgroundImage: `url(${media.preview})`
                                }'
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogMediaInfo',
    data() {
        return {};
    },
    mixins: [Dates],
    computed: {
        ...mapState({
            roomMedia: state => state.lists?.roomMedia?.results || [],
        })
    },
    methods: {
        closeInfo() {
            this.$emit('close-info');
        },
    }
};
</script>
