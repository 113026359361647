<template>
    <div class='side-header'>
        <nav class='site-navigation'>
            <ul class='site-navigation__menu'>
                <li class='site-navigation__menu-item disabled-block-opacity' title='Рабочий стол'>
                    <a href='#' class='site-navigation__menu-link'>
                        <i class='svg-icon-workspacecell'></i>
                    </a>
                </li>
                <li class='site-navigation__menu-item' title='Задачи'>
                    <router-link :to='{name: "rooms"}' class='site-navigation__menu-link'
                                 exact-active-class='current-page'>
                        <i class='svg-icon-log'></i>
                    </router-link>
                </li>
                <li class='site-navigation__menu-item disabled-block-opacity' title='Доска задач'>
                    <a href='#' class='site-navigation__menu-link'>
                        <i class='svg-icon-desk'></i>
                    </a>
                </li>
                <li class='site-navigation__menu-item' title='Структура'>
                    <router-link :to='{name: "structure"}' class='site-navigation__menu-link'
                                 exact-active-class='current-page'>
                        <i class='svg-icon-structure'></i>
                    </router-link>
                </li>
                <li class='site-navigation__menu-item' title='Продуктовая аналитика'>
                    <router-link :to='{name: "product-analytic"}' class='site-navigation__menu-link'
                                 exact-active-class='current-page'>
                        <i class='svg-icon-product-analytic'></i>
                    </router-link>
                </li>
            </ul>
        </nav>
        <div class='header-profile'>
            <router-link :to='`/user-profile/${user.id}`'>
                <div
                    class='header-profile__avatar'
                    :style='{
                        backgroundImage: $getUserAvatar(user.userpic)
                    }'
                >
                </div>
            </router-link>

        </div>
        <div class='help-button disabled-block-opacity'>
            <a href='#'>
                <i class='svg-icon-help'></i>
            </a>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoSideHeader',
    computed: {
        ...mapState({
            user: state => state.auth?.user
        })
    }
};
</script>

<style scoped>

</style>
