<template>
    <div class='input-block'>
        <label class='input-block__label'><span>WORK</span> </label>

    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoLogWorkFilter',
    data() {
        return {};
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            wells: state => state.guides?.wells?.results || []
        })
    },
    methods: {}
};
</script>
