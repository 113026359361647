<template>
    <div class='input-block'>
        <label class='input-block__label'><span>ИССЛЕДОВАНИЯ</span></label>
        <div class='input-block__buttons'>
            <label class='chips-selector' v-for='type in typesOfResearchStart' :key='type.id'>
                <input :id='type.id' type='checkbox' v-model='selected' :value='type.id' @change='select(type.id)'/>
                <span> <i class='base-icon-delivered'></i>{{ type.name }} </span>
            </label>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoLogCommonTaskFilter',
    data() {
        return {
            selected: []
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            typesOfResearchStart: state => state.guides?.typesOfResearch.results || []
        })
    },
    methods: {
        select(id) {
            this.selected = [id];
            this.model.types_of_research = id;
        }
    }
};
</script>
