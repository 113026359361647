<template>
    <div class='inquiries__wrap'>
        <div class='inquiries__top'>
            <div class='inquiries__title h3'>Без решения</div>
            <div class='inquiries__buttons disabled-block-opacity'>
                <label class='chips-selector'>
                    <input type='checkbox' :disabled='true' checked='checked' /><span
                ><i class='base-icon-delivered'></i>Все</span
                >
                </label>
                <label class='chips-selector'>
                    <input type='checkbox' :disabled='true' class='disabled-block-opacity' /><span
                ><i class='base-icon-delivered'></i>Просрочено</span
                >
                </label>
                <label class='chips-selector'>
                    <input type='checkbox' :disabled='true' /><span><i class='base-icon-delivered'></i>Сегодня</span>
                </label>
                <label class='chips-selector'>
                    <input type='checkbox' :disabled='true' /><span
                ><i class='base-icon-delivered'></i>На этой неделе</span
                >
                </label>
            </div>
        </div>

        <div class='inquiries__tasks'>
            <div class='inquiries__date' v-if='issues.length'>
                <span>{{ getFullMonthDate(issues[0].created_at) }}</span>
                <div class='order-button'><i class='svg-icon-sort-less-more'></i></div>
            </div>
            <div
                class='task-item'
                v-for='issue in issues'
                v-bind:key='issue.id'
                v-bind:class='{expired: issue.content_object.expired}'
            >
                <router-link :to="{path: `/log/rooms/${issue.room_id}`, params: {issueId: issue.id}}">
                    <div class='project-short-details expired'>
                        <div class='project-short-details__status'></div>
                        <a class='project-short-details__link' href='#'>{{
                                issue.room.type_of_research ? issue.room.type_of_research.name : '-'
                            }}</a>
                        <div class='project-short-details__sep'>|</div>
                        <div class='project-short-details__sb'>
                            {{ issue.room.project ? issue.room.project.name : '-' }}
                        </div>
                        <div class='project-short-details__sep'>|</div>
                        <div class='project-short-details__sb'>{{ issue.room.well ? issue.room.well.name : '-' }}</div>
                    </div>
                    <div class='user'>
                        <router-link :to="`/user-profile/${issue.author.id}`">
                            <div
                                class='user__avatar'
                                :style='{
                                    backgroundImage: $getUserAvatar(issue.author.userpic)
                                }'
                            ></div>
                        </router-link>
                        <div class='user__ct'>
                            <div class='user__name'>{{ issue.author.full_name }}</div>
                            <div class='user__post' v-show='false'>{{ $getUserTag(issue.author.id) }}</div>
                        </div>
                        <div class='user__buttons' v-if='issue.content_object.expired'>
                            <div class='user__expired'>Просрочен</div>
                        </div>
                    </div>
                    <div class='message-cloud request-type'>
                        <div class='message-cloud__wrap'>
                            <div class='message-cloud__content'>
                                <div class='message-cloud__request-top'>
                                    <div class='message-cloud__request-title'>
                                        <i class='base-icon-arrow-back'></i
                                        ><span>Запрос № {{ issue.number_by_type }}</span>
                                    </div>
                                    <div class='message-cloud__request-date'>
                                        До {{ getDate(issue.content_object.deadline) }}
                                    </div>
                                </div>
                                {{ issue.data }}
                            </div>
                            <div class='message-cloud__meta'>
                                <div class='message-cloud__time'>{{ getTime(issue.created_at) }}</div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogIssuesWithoutSolution',
    data() {
        return {};
    },
    props: {
        issues: {
            type: Array,
            required: true
        }
    },
    mixins: [Dates],
    components: {},
    computed: {},
    async created() {
    },
    methods: {}
};
</script>

<style scoped>
.inquiries__tasks {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
}

.inquiries__date {
    margin: 10px;
    flex: 0 0 100%;
}

.task-item {
    flex: 1;
    max-width: 50%;
    margin: 10px;
}

.message-cloud__content {
    width: 100%;
}
</style>
