const state = {
    isDarkTheme: false
};

export const mutationTypes = {
    toggleDarkTheme: '[settings] toggleDarkTheme'
};

export const actionTypes = {
    toggleDarkTheme: '[settings] toggleDarkTheme'
};

const getters = {};

const mutations = {
    [mutationTypes.toggleDarkTheme](state) {
        state.isDarkTheme = !state.isDarkTheme;
    }
};
const actions = {
    async [actionTypes.toggleDarkTheme](context) {
        context.commit(mutationTypes.toggleDarkTheme);
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
