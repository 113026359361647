<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>Кто прочитал сообщение</div>
            <div class='log-information__close-button base-icon-close' @click='closeHistory'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div
                    class='user contact-user'
                    v-for='reader in messageReadings'
                    :key='reader.id'
                >
                    <router-link :to="`/user-profile/${reader.id}`">
                        <div
                            class='user__avatar'
                            :style='{
                                backgroundImage: $getUserAvatar(reader.userpic)
                            }'
                        ></div>
                    </router-link>
                    <div class='user__ct'>
                        <div class='user__name'>{{ reader.full_name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';

export default {
    name: 'GeoLogRoomHistory',
    data() {
        return {};
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getMessageReadings, this.selectedMessageId);
    },
    props: {
        selectedMessageId: {
            type: Number,
            required: true
        },
    },
    computed: {
        ...mapState({
            messageReadings: state => state.guides?.messageReadings || []
        })
    },
    methods: {
        closeHistory() {
            this.$emit('close-history');
        }
    }
};
</script>
