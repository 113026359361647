<template>
    <div>
        <div v-for='cluster in clusters' :key='cluster.id'>
            <div v-for='project in cluster.projects' :key='project.id'>
                <div class='input-block' v-if='project.license_areas.length'>
                    <label class='input-block__label'>
                        <span>{{ cluster.name.toUpperCase() }} | {{ project.name.toUpperCase() }}</span>
                    </label>
                    <div class='input-block__buttons'>
                        <label class='chips-selector' v-for='area in project.license_areas' :key='area.id'>
                            <input type='checkbox' v-model='selected' :value='area.id' @change='select(area.id)'/>
                            <span> <i class='base-icon-delivered'></i>{{ area.name }} </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoLogLicenseAreasFilter',
    data() {
        return {
            selected: []
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            clusters: state => state.lists?.clusters || []
        })
    },
    methods: {
        select(id) {
            this.selected = [id];
            this.model.license_areas = id;
        }
    }
};
</script>
