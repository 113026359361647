var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"input-block select-block with-title"},[_c('div',{staticClass:"select-block__button"},[_c('div',{staticClass:"select-block__button__title"},[_vm._v("Кластер")]),_c('div',{staticClass:"select-block__button__label"},[_vm._v(" "+_vm._s(_vm.selectedCluster ? _vm.selectedCluster.name : 'Выберите кластер')+" ")]),_c('i',{staticClass:"base-icon-arrow-down"})]),_c('div',{staticClass:"dropdown"},_vm._l((_vm.headerItems),function(item,index){return _c('div',{key:index,staticClass:"dropdown__item dropdown__type-select",on:{"click":function($event){return _vm.selectCluster(item.id)}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),0)]),(
                        _vm.canCreateEditWell ||
                            _vm.canCreateEditLicenseArea ||
                            _vm.canCreateEditProject ||
                            _vm.canCreateEditCluster
                    )?_c('div',{staticClass:"header__right"},[_c('div',{staticClass:"header__create-button"},[_vm._m(1),_c('div',{staticClass:"dropdown"},[(_vm.canCreateEditCluster)?_c('div',{staticClass:"dropdown__item dropdown__type-link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('cluster')}}},[_vm._v("Кластер")])]):_vm._e(),(_vm.canCreateEditProject || _vm.canCreateEditCluster)?_c('div',{staticClass:"dropdown__item dropdown__type-link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('project')}}},[_vm._v("Проект")])]):_vm._e(),((_vm.canCreateEditLicenseArea || _vm.canCreateEditProject || _vm.canCreateEditCluster) && _vm.selectedCluster && _vm.selectedCluster.cluster_type === "GEO" && _vm.selectedProject)?_c('div',{staticClass:"dropdown__item dropdown__type-link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('licenseArea')}}},[_vm._v("Лицензионный участок")])]):_vm._e(),(
                        (_vm.canCreateEditWell ||
                            _vm.canCreateEditLicenseArea ||
                            _vm.canCreateEditProject ||
                            _vm.canCreateEditCluster) &&
                            _vm.selectedCluster &&
                            _vm.selectedCluster.cluster_type === "GEO" &&
                            _vm.selectedLicenceArea
                    )?_c('div',{staticClass:"dropdown__item dropdown__type-link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('well')}}},[_vm._v("Скважина")])]):_vm._e(),(
                        _vm.canCreateEditWell ||
                            _vm.canCreateEditLicenseArea ||
                            _vm.canCreateEditProject ||
                            _vm.canCreateEditCluster
                    )?_c('div',{staticClass:"dropdown__item dropdown__type-link"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('commonTask')}}},[_vm._v("Общая задача")])]):_vm._e()])]),_vm._m(2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-logo"},[_c('div',{staticClass:"site-logo__link",attrs:{"href":"#"}},[_vm._v("GEO"),_c('span',[_vm._v("Структура")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"button small",attrs:{"href":"#"}},[_c('span',[_vm._v("Создать")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-button disabled-block-opacity"},[_c('i',{staticClass:"svg-icon-search"})])}]

export { render, staticRenderFns }