<template>
    <div class='cards-grid'>
        <div class='card-item' v-for='(room, index) in rooms' :key='index' :class='[getRoomClass(room), {unread: room.unread_messages_count || room.unread_issues_count}]'>
            <router-link :to="`/log/rooms/${room.id}`">
                <div class='card-item__date-line'></div>
                <div class='card-item__dates'>
                    <div class='card-item__start-date card-item__date'>
                        <div class='card-item__date-label'>Начало</div>
                        <div class='card-item__date-value'>{{ getDate(room.start_date) }}</div>
                    </div>
                    <div class='card-item__date-arrow svg-icon-date-arrow'></div>
                    <div class='card-item__end-date card-item__date'>
                        <div class='card-item__date-label'>Окончание</div>
                        <div class='card-item__date-value'>{{ getDate(room.end_date) }}</div>
                    </div>
                </div>
                <div class='card-item__title h3'>{{ room.title }}</div>
                <div class='card-item__sub-title'>{{ room.type_of_research ? room.type_of_research.name : '-' }}</div>
                <div class='card-item__p1'>
                    {{ room.cluster ? room.cluster.name : '-' }} | {{ room.project ? room.project.name : '-' }}
                </div>
                <div class='card-item__p2'>{{ room.well ? room.well.name : '-' }}</div>
                <div class='card-item__meta'>
                    <div class='author-avatars'>
                        <div
                            class='author-avatars__item'
                            v-for='(participant, index) in fewParticipants(room.participants)'
                            :key='index'
                            :class='{more: index >= 4}'
                        >
                            <router-link :to="`/user-profile/${participant.id}`">
                                <div
                                    class='img'
                                    :style='{
                                        backgroundImage: $getUserAvatar(participant.userpic)
                                    }'
                                />
                            </router-link>
                            <div v-if='index >= 4'>+{{ room.participants.length - index }}</div>
                        </div>
                    </div>
                    <div class='card-item__new-msg-count' v-if='room.unread_issues_count'>
                        <i class='svg-icon-down'></i><span>{{ room.unread_issues_count }}</span>
                    </div>
                    <div class='card-item__msg-count' v-if='room.unread_messages_count'>
                        <i class='svg-icon-cloud'></i><span>{{ room.unread_messages_count }}</span>
                    </div>
                    <div class='card-item__list-button'><i class='svg-icon-checklist'></i></div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';

export default {
    name: 'RoomsListElements',
    data() {
        return {};
    },
    mixins: [Dates],
    computed: {},
    props: {
        rooms: {
            type: Array,
            required: true
        }
    },
    methods: {
        fewParticipants(participants) {
            return participants.slice(0, 5);
        },
        getRoomClass(room) {
            if (room.stage?.internal_name === "PASSIVE") {
                return 'closed';
            }
            if (room.stage?.internal_name === "NEAR_COMPLETION") {
                return 'expired';
            }
            if (room.stage?.internal_name === "EXPIRED") {
                return 'warning';
            }
            if (room.stage?.internal_name === "IN_PROCESS" || room.status === "NOT_STARTED") {
                return 'success';
            }
        }
    }
};
</script>

<style scoped>
.card-item {
    cursor: pointer;
}
</style>
