<template>
    <div class='page-body' v-bind:class='{"night-scheme": isDarkTheme}'>
        <router-view name='topHeader'></router-view>
        <router-view name='sideHeader'></router-view>
        <router-view></router-view>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {wsp} from './api/ws';
import eventHub from '@/store/events/eventHub.js';

export default {
    name: 'GeoApp',
    computed: {
        ...mapState({
            isDarkTheme: state => state.settings?.isDarkTheme
        }),
    },
    created() {
        eventHub.$on('wsl-response-error', this.message);
        wsp.onMessage.addListener(data => {
            let parsedData = JSON.parse(data);
            if (parsedData.cls === "messages" && parsedData.method === "post") {
                this.$root.$emit('rooms-changed');
            }
            if (parsedData.cls === "room/messages") {
                this.$root.$emit('messages-changed');
            }
        });
    },
    methods: {
        message(params) {
            this.$message({
                dangerouslyUseHTMLString: true,
                message: params.message,
                type: params.type,
                showClose: true,
                duration: 5000
            });
        },
    }
}
</script>
