<template>
    <div class='log-filter col-3'>
        <div class='log-filter__top log-tasks__top'>
            <div class='log-filter__title log-tasks__title h3'>Фильтр по задачам</div>
            <div class='log-filter__close-button base-icon-close' @click='closeFilters'></div>
        </div>
        <div class='log-filter__wrap'>
            <div class='log-filter__scroll-wrap'>
                <div class='input-block select-block with-title'>
                    <label class='input-block__label'><span>Фильтровать</span><em>*</em> </label>
                    <el-select v-model='filterType' placeholder='Выберите' @change='selectFilter'>
                        <el-option
                            v-for='item in filterOptions'
                            :key='item.value'
                            :label='item.label'
                            :value='item.value'
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class='search-form' v-show='false'>
                    <div class='search-form__input-block'>
                        <div class='search-form__button base-icon-search'></div>
                        <input class='search-form__input' placeholder='Поиск' type='text' />
                    </div>
                </div>
                <component :model='model' v-bind:is='filterType' @reset-filters='resetFilters'></component>
            </div>
        </div>
        <div class='log-filter__buttons'>
            <button class='button small secondary' type='button' @click='clearFilters'><span>Сбросить</span></button>
            <button class='button small' type='submit' @click='search'><span>Применить</span></button>
        </div>
    </div>
</template>

<script>
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mutationTypes as listsMutationTypes} from '@/store/modules/lists';
import Work from './filters/Work';
import Wells from './filters/Wells';
import TypesOfResearch from './filters/TypesOfResearch';
import Projects from './filters/Projects';
import LicenseAreas from './filters/LicenseAreas';
import CommonTasks from './filters/CommonTasks';
import {mapGetters, mapState} from 'vuex';
import {getterTypes as guidesGetterTypes} from '@/store/modules/guides';

export default {
    name: 'GeoLogFilters',
    data() {
        return {
            filterType: '',
            filters: [],
            filterOptions: [
                // {value: 'Work', label: 'По активности'},
                {value: 'Wells', label: 'По скважине'},
                {value: 'TypesOfResearch', label: 'По исследованию'},
                {value: 'Projects', label: 'По проекту'},
                {value: 'LicenseAreas', label: 'По лицензионному участку'},
                {value: 'CommonTasks', label: 'По общим задачам'}
            ],
            model: {
                work: [],
                wells: [],
                types_of_research: [],
                projects: [],
                license_areas: [],
                common_tasks: []
            },
            params: {
                wells: {
                    query: '',
                    limit: 20,
                    offset: 0
                }
            }
        };
    },
    components: {
        Work,
        Wells,
        TypesOfResearch,
        Projects,
        LicenseAreas,
        CommonTasks
    },
    computed: {
        ...mapState({
            wells: state => state.guides?.wells || [],
            wellsCount: state => state.guides?.wellsCount,
        }),
        ...mapGetters({
            isAllWells: guidesGetterTypes.isAllWells
        }),
    },
    created() {
    },
    methods: {
        clearFilters() {
            this.filterType = '';
            this.resetFilters();
            this.$emit('get-rooms');
        },
        resetFilters() {
            this.model = Object.assign({}, {
                work: [],
                wells: [],
                types_of_research: [],
                projects: [],
                license_areas: [],
                common_tasks: []
            });
        },
        closeFilters() {
            this.$emit('close-filters');
        },
        selectFilter(value) {
            this.resetFilters();
            switch (value) {
                case 'Work':
                    break;
                case 'Wells':
                    this.getWells();
                    break;
                case 'TypesOfResearch':
                    this.$store.dispatch(guidesActionTypes.getTypesOfResearch);
                    break;
                case 'Projects':
                    this.$store.dispatch(guidesActionTypes.getProjects);
                    break;
                case 'LicenseAreas':
                    break;
                case 'CommonTasks':
                    break;
                default:
                    break;
            }
        },
        async search() {
            switch (this.filterType) {
                case 'Work':
                    break;
                case 'Wells':
                    await this.$store.dispatch(listsActionTypes.getLogWellRooms, this.model.wells);
                    this.$store.commit(listsMutationTypes.resetRoom);

                    break;
                case 'TypesOfResearch':
                    await this.$store.dispatch(listsActionTypes.getTypeOfResearchRooms, this.model.types_of_research);
                    this.$store.commit(listsMutationTypes.resetRoom);

                    break;
                case 'Projects':
                    await this.$store.dispatch(listsActionTypes.getLogProjectRooms, this.model.projects);
                    this.$store.commit(listsMutationTypes.resetRoom);

                    break;
                case 'LicenseAreas':
                    await this.$store.dispatch(listsActionTypes.getLicenseAreaRooms, this.model.license_areas);
                    this.$store.commit(listsMutationTypes.resetRoom);

                    break;
                case 'CommonTasks':
                    await this.$store.dispatch(listsActionTypes.getTypeOfResearchRooms, this.model.types_of_research);
                    this.$store.commit(listsMutationTypes.resetRoom);
                    break;
                default:
                    break;
            }
        },
        async getWells() {
            if (!this.isAllWells) {
                this.params.wells = Object.assign(this.params.wells, {
                    offset: this.params.wells.offset
                });
                await this.$store.dispatch(guidesActionTypes.getWells, this.params.wells);
                this.params.wells.offset += 20;
                await this.getWells()
            }

        }
    }
};
</script>
