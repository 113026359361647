<template>
    <div
        ref="popoverMenu"
        class="popover-menu"
        v-bind:style="{top: top, left: left}"
        @focusout="closeMenu"
        tabindex="-1"
    >
        <ul>
            <li class="dropdown__item dropdown__type-right-click" v-bind:class="{ disabled: !isRequest }" @click="createIssueAnswer">
                <span>Ответить на запрос</span>
            </li>
<!--            <li class="dropdown__item dropdown__type-right-click disabled">-->
<!--                <span>-->
<!--                    Открыть приватный чат-->
<!--                </span>-->
<!--            </li>-->
            <li class="dropdown__item dropdown__type-right-click" @click="reply">
                <span>
                    Ответить на сообщение
                </span>
            </li>
<!--            <li class="dropdown__item dropdown__type-right-click disabled">-->
<!--                <span>-->
<!--                    Редактировать-->
<!--                </span>-->
<!--            </li>-->
<!--            <li class="dropdown__item dropdown__type-right-click disabled">-->
<!--                <span>-->
<!--                    Копировать текст-->
<!--                </span>-->
<!--            </li>-->
            <li class="dropdown__item dropdown__type-right-click" @click="showForward">
                <span>
                    Переслать сообщение
                </span>
            </li>
            <li class="dropdown__item dropdown__type-right-click" @click="showHistory">
                <span>
                    Кто прочитал сообщение
                </span>
            </li>
            <li class="dropdown__item dropdown__type-right-click disabled" v-if="canCreateEditProject">
                <span>
                    История изменений
                </span>
            </li>
            <li class="dropdown__item dropdown__type-right-click disabled" v-if="canCreateEditProject">
                <span> Удалить</span>
            </li>
        </ul>
    </div>
</template>

<script>
import {can} from '@/helpers/permissions';


export default {
    name: 'GeoLogMessageMenu',
    data() {
        return {
            top: '0px',
            left: '0px'
        };
    },
    components: {},
    computed: {
        canCreateEditProject: function() {
            return can('createEditProject');
        }
    },
    props: {
        isRequest: {
            type: Boolean,
            required: true
        },
    },
    mounted: function() {
        this.$root.$on('set-menu', (x, y, wrapBlock) => {
            this.setMenu(x, y, wrapBlock);
        });
    },
    beforeDestroy() {
        this.$root.$off("set-menu");
    },
    methods: {
        closeMenu() {
            this.$emit('close-menu');
        },
        setMenu: function(top, left, wrapBlock) {
            let largestHeight = wrapBlock.height - this.$refs.popoverMenu?.offsetHeight;
            let largestWidth = wrapBlock.width - this.$refs.popoverMenu?.offsetWidth;

            if (top > largestHeight) top = largestHeight;
            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
            this.$refs.popoverMenu?.focus();
        },
        showHistory() {
            this.$emit('show-history');
            this.closeMenu();
        },
        showForward() {
            this.$emit('show-forward');
            this.closeMenu();
        },
        reply() {
            this.$emit('reply');
            this.closeMenu();
        },
        createIssueAnswer() {
            if (!this.isRequest) {
                return;
            }
            this.$emit('create-issue-answer');
            this.closeMenu();
        }
    }
};
</script>

<style scoped>
.popover-menu {
    z-index: 99;
    position: fixed;
    font-size: 14px;
}

.popover-menu:focus-visible {
    outline: none !important;
}
</style>
