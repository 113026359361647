import axios from 'axios';

async function handleLoadFile(path, data, successCallback, failedCallback) {
    // let uploadNotificationResponse = await axios.get('/api/v1/client-config/');
    // let uploadNotification = response.upload_notification;

    let csrfResponse = await axios.get('/api/v1/users/current/');
    let csrfToken = csrfResponse.data.csrf_token;

    let csrfHeaderResponse = await axios.get('/api/v1/client-config');
    let csrfHeader = csrfHeaderResponse.data.csrf_header;

    // {
    //     "type_of_message": "string",
    //     "file_name": "string",
    //     "file": [
    //         "string"
    //     ],
    //     "room": 0,
    //     "parent_message": 0,
    //     "data": "string",
    //     "responsible": 0,
    //     "issue_message": 0,
    //     "deadline": "string",
    //     "related_message": 0,
    //     "recipients": [
    //         "string"
    //     ],
    //     "struct": "string"
    // }

    let bodyFormData = new FormData();

    let file = data.files[0];

    bodyFormData.append('type_of_message', 'binary_message');
    bodyFormData.append('file_name', file.name);
    bodyFormData.append('file', file.raw);
    bodyFormData.append('room', data.room);
    bodyFormData.append('parent_message', data.parent_message || '');
    bodyFormData.append('data', data.data);
    bodyFormData.append('responsible', data.responsible);
    bodyFormData.append('issue_message', data.issue_message);
    for (var i = 0; i < data.recipients.length; i++) {
        bodyFormData.append('recipients[]', data.recipients[i]);
    }
    bodyFormData.append('struct', data.struct);

    let client = axios({
        method: 'post',
        url: `${path}`,
        data: bodyFormData,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            [csrfHeader]: csrfToken
        }
    });

    await client
        .then(function(response) {
            successCallback(response);
        })
        .catch(function(response) {
            failedCallback(response);
        });
}

// function getBinaryFromFile(file) {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//
//         reader.addEventListener("load", () => resolve(reader.result));
//         reader.addEventListener("error", err => reject(err));
//
//         reader.readAsBinaryString(file);
//     });
// }

export default handleLoadFile;
