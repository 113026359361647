<template>
    <main class='main-block'>
        <div class='empty-block'>
            <div class='edit-heading__heading h3'>
                <span>404</span>
                <div class='edit-heading__sub-heading'>Страница не найдена</div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'GeoPageNotFound',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style scoped></style>
