<template>
    <div class='analytic-table-wrapper'>
        <table class='table analytic-table'>
            <thead>
            <tr>
                <th>Показатель</th>
                <th>Период 1</th>
                <th>Период 2</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Создано запросов</td>
                <td>{{userFacts[0].issue}}</td>
                <td>{{userFacts[1].issue}}</td>
            </tr>
            <tr>
                <td>Создано задач</td>
                <td>{{userFacts[0].room}}</td>
                <td>{{userFacts[1].room}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

export default {
    name: 'GeoProductAnalyticTable',
    data() {
        return {};
    },
    components: {},
    computed: {},
    props: {
        userFacts: {
            type: Array,
            required: true
        }
    },
    async created() {

    },
    methods: {}
};
</script>

<style scoped>
.analytic-table {
    width: 100%;
    border-collapse: separate;
    text-align: center;
    border-spacing: 0;
    margin-bottom: 20px;
}

.analytic-table thead {
    font-weight: 700;
    font-size: 20px;
}

.analytic-table tr th, tr td {
    padding: 16px;
    border-right: 1px solid #CCD9E0;
    border-bottom: 1px solid #CCD9E0;
}

.analytic-table tr th {
    border-top: 1px solid #CCD9E0;
}

.analytic-table tr th:first-child, tr td:first-child {
    border-left: 1px solid #CCD9E0;
}

.analytic-table tr:first-child th:first-child {
    border-top-left-radius: 8px;
}

.analytic-table tr:first-child th:last-child {
    border-top-right-radius: 8px;
}

.analytic-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.analytic-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.analytic-table_th {
    min-width: 300px;
}

>>> .analytic-table .danger {
    color: #EB3333;
}

>>> .analytic-table .warning {
    color: #FFA10A;
}

>>> .analytic-table .success {
    color: #09B37B;
}
</style>
