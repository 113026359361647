const state = {
    isClusterModalOpened: false,
    isClusterModalEdit: false,
    isProjectModalOpened: false,
    isProjectModalEdit: false,
    isLicenseAreaModalOpened: false,
    isLicenseAreaModalEdit: false,
    isWellModalOpened: false,
    isWellModalEdit: false,
    isCommonTaskModalOpened: false,
    isGeoTaskModalOpened: false,
    isAdmTaskModalOpened: false,
    isImagePopupOpened: false,
    imagePopupFile: false,
    isVideoPopupOpened: false,
    videoPopupFile: false
};

export const mutationTypes = {
    clusterModalOpen: '[modals] clusterModalOpen',
    clusterModalClose: '[modals] clusterModalClose',
    projectModalOpen: '[modals] projectModalOpen',
    projectModalClose: '[modals] projectModalClose',
    licenseAreaModalOpen: '[modals] licenseAreaModalOpen',
    licenseAreaModalClose: '[modals] licenseAreaModalClose',
    wellModalOpen: '[modals] wellModalOpen',
    wellModalClose: '[modals] wellModalClose',
    commonTaskModalOpen: '[modals] commonTaskModalOpen',
    commonTaskModalClose: '[modals] commonTaskModalClose',
    geoTaskModalOpen: '[modals] geoTaskModalOpen',
    geoTaskModalClose: '[modals] geoTaskModalClose',
    admTaskModalOpen: '[modals] admTaskModalOpen',
    admTaskModalClose: '[modals] admTaskModalClose',
    imagePopupOpen: '[modals] imagePopupOpen',
    imagePopupClose: '[modals] imagePopupClose',
    videoPopupOpen: '[modals] videoPopupOpen',
    videoPopupClose: '[modals] videoPopupClose'
};

export const getterTypes = {
    clusterModalOpened: '[modals] clusterModalOpened',
    clusterModalEdit: '[modals] clusterModalEdit',
    projectModalOpened: '[modals] projectModalOpened',
    projectModalEdit: '[modals] projectModalEdit',
    licenseAreaModalOpened: '[modals] licenseAreaModalOpened',
    licenseAreaModalEdit: '[modals] licenseAreaModalEdit',
    wellModalOpened: '[modals] wellModalOpened',
    wellModalEdit: '[modals] wellModalEdit',
    commonTaskModalOpened: '[modals] commonTaskModalOpened',
    geoTaskModalOpened: '[modals] geoTaskModalOpened',
    admTaskModalOpened: '[modals] admTaskModalOpened',
    imagePopupOpened: '[modals] imagePopupOpened',
    videoPopupOpened: '[modals] videoPopupOpened'
};

const mutations = {
    [mutationTypes.clusterModalOpen](state, payload) {
        state.isClusterModalOpened = true;
        state.isClusterModalEdit = payload === 'edit';
    },
    [mutationTypes.clusterModalClose](state) {
        state.isClusterModalOpened = false;
        state.isClusterModalEdit = false;
    },
    [mutationTypes.projectModalOpen](state, payload) {
        state.isProjectModalOpened = true;
        state.isProjectModalEdit = payload === 'edit';
    },
    [mutationTypes.projectModalClose](state) {
        state.isProjectModalOpened = false;
        state.isProjectModalEdit = false;
    },
    [mutationTypes.licenseAreaModalOpen](state, payload) {
        state.isLicenseAreaModalOpened = true;
        state.isLicenseAreaModalEdit = payload === 'edit';
    },
    [mutationTypes.licenseAreaModalClose](state) {
        state.isLicenseAreaModalOpened = false;
        state.isLicenseAreaModalEdit = false;
    },
    [mutationTypes.wellModalOpen](state, payload) {
        state.isWellModalOpened = true;
        state.isWellModalEdit = payload === 'edit';
    },
    [mutationTypes.wellModalClose](state) {
        state.isWellModalOpened = false;
        state.isWellModalEdit = false;
    },
    [mutationTypes.commonTaskModalOpen](state) {
        state.isCommonTaskModalOpened = true;
    },
    [mutationTypes.commonTaskModalClose](state) {
        state.isCommonTaskModalOpened = false;
    },
    [mutationTypes.geoTaskModalOpen](state) {
        state.isGeoTaskModalOpened = true;
    },
    [mutationTypes.geoTaskModalClose](state) {
        state.isGeoTaskModalOpened = false;
    },
    [mutationTypes.admTaskModalOpen](state) {
        state.isAdmTaskModalOpened = true;
    },
    [mutationTypes.admTaskModalClose](state) {
        state.isAdmTaskModalOpened = false;
    },
    [mutationTypes.imagePopupOpen](state, payload) {
        state.isImagePopupOpened = true;
        state.imagePopupFile = payload;
    },
    [mutationTypes.imagePopupClose](state) {
        state.isImagePopupOpened = false;
        state.imagePopupFile = false;
    },
    [mutationTypes.videoPopupOpen](state, payload) {
        state.isVideoPopupOpened = true;
        state.videoPopupFile = payload;
    },
    [mutationTypes.videoPopupClose](state) {
        state.isVideoPopupOpened = false;
        state.videoPopupFile = false;
    }
};

const getters = {
    [getterTypes.clusterModalOpened]: state => {
        return Boolean(state.isClusterModalOpened);
    },
    [getterTypes.clusterModalEdit]: state => {
        return Boolean(state.isClusterModalEdit);
    },
    [getterTypes.projectModalOpened]: state => {
        return Boolean(state.isProjectModalOpened);
    },
    [getterTypes.projectModalEdit]: state => {
        return Boolean(state.isProjectModalEdit);
    },
    [getterTypes.licenseAreaModalOpened]: state => {
        return Boolean(state.isLicenseAreaModalOpened);
    },
    [getterTypes.licenseAreaModalEdit]: state => {
        return Boolean(state.isLicenseAreaModalEdit);
    },
    [getterTypes.wellModalOpened]: state => {
        return Boolean(state.isWellModalOpened);
    },
    [getterTypes.wellModalEdit]: state => {
        return Boolean(state.isWellModalEdit);
    },
    [getterTypes.commonTaskModalOpened]: state => {
        return Boolean(state.isCommonTaskModalOpened);
    },
    [getterTypes.geoTaskModalOpened]: state => {
        return Boolean(state.isGeoTaskModalOpened);
    },
    [getterTypes.admTaskModalOpened]: state => {
        return Boolean(state.isAdmTaskModalOpened);
    },
    [getterTypes.imagePopupOpened]: state => {
        return Boolean(state.isImagePopupOpened);
    },
    [getterTypes.videoPopupOpened]: state => {
        return Boolean(state.isVideoPopupOpened);
    }
};

export default {
    state,
    mutations,
    getters
};
