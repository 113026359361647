<template>
    <div class='row'>
        <div class='col-3'>
            <div class='projects-statistic'>
                <div
                    class='projects-statistic__bg'
                    :style="{backgroundImage: 'url(' + require('@/assets/img/projects1.jpg') + ')'}"
                ></div>
                <div class='projects-statistic__title'>Проекты</div>
                <div class='projects-statistic__items row'>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.project_active ? statistics.project_active : 0 }}</div>
                        <div class='projects-statistic__status active'>Активных</div>
                    </div>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.project_passive ? statistics.project_passive : 0 }}</div>
                        <div class='projects-statistic__status end'>Завершенных</div>
                    </div>
                </div>
            </div>
        </div>
        <div class='col-3'>
            <div class='projects-statistic'>
                <div
                    class='projects-statistic__bg'
                    :style="{backgroundImage: 'url(' + require('@/assets/img/projects2.jpg') + ')'}"
                ></div>
                <div class='projects-statistic__title'>Скважины</div>
                <div class='projects-statistic__items row'>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.well_active ? statistics.well_active : 0 }}</div>
                        <div class='projects-statistic__status active'>Активная</div>
                    </div>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.well_passive ? statistics.well_passive : 0 }}</div>
                        <div class='projects-statistic__status end'>Закрытых</div>
                    </div>
                </div>
            </div>
        </div>
        <div class='col-6'>
            <div class='projects-statistic'>
                <div
                    class='projects-statistic__bg'
                    :style="{backgroundImage: 'url(' + require('@/assets/img/projects3.jpg') + ')'}"
                ></div>
                <div class='projects-statistic__title'>Исследования</div>
                <div class='projects-statistic__items row'>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.room_active ? statistics.room_active : 0 }}</div>
                        <div class='projects-statistic__status active'>Активных</div>
                    </div>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.room_near_completion ? statistics.room_near_completion : 0 }}</div>
                        <div class='projects-statistic__status warning'>Завершаются</div>
                    </div>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.room_expired ? statistics.room_expired : 0 }}</div>
                        <div class='projects-statistic__status error'>Просроченных</div>
                    </div>
                    <div class='projects-statistic__item col'>
                        <div class='projects-statistic__count'>{{ statistics.room_passive ? statistics.room_passive : 0 }}</div>
                        <div class='projects-statistic__status end'>Завершенных</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoDashboard',
    computed: {
        ...mapState({
            statistics: state => state.lists?.statistics ?? {},
        }),
    },
    async created() {
    },
    methods: {}
};
</script>

<style scoped></style>
