<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Выбрать пользователей</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='search-form'>
                    <div class='search-form__input-block'>
                        <div class='search-form__input-block'>
                            <div class='search-form__button base-icon-search'></div>
                            <input class='search-form__input' placeholder='Поиск' type='search' v-model='selectedUser'
                                   @input='getUsers' />
                        </div>
                        <div class='search-form__button base-icon-search'></div>
                    </div>
                </div>
                <div class='search-results'>
                    <div class='form-label'>КОНТАКТЫ</div>
                    <div class='user' v-for='user in selectedUsers' :key='"selected" + user.id'>
                        <div
                            class='user__avatar'
                            :style='{
                                backgroundImage: $getUserAvatar(user.userpic)
                            }'
                        ></div>
                        <div class='user__ct'>
                            <div class='user__name'>{{ user.full_name }}</div>
                        </div>
                        <div class='user__buttons'>
                            <div class='user__remove base-icon-delete-1' @click='removeUser(user)'></div>
                        </div>
                    </div>
                    <div class='search-results__divider' v-if='filteredUsers.length'></div>
                    <div class='user' v-for='user in filteredUsers' :key='"filtered" + user.id' @click='addUser(user)'>
                        <div
                            class='user__avatar'
                            :style='{
                                backgroundImage: $getUserAvatar(user.userpic)
                            }'
                        ></div>
                        <div class='user__ct'>
                            <div class='user__name'>{{ user.full_name }}</div>
                        </div>
                    </div>
                </div>
                <div class='search-results__divider' v-if='selectedUsers.length'></div>
                <button
                    class='button small filter-button'
                    type='submit'
                    @click='closeModal'
                >
                    <span>Сохранить</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GeoProductAnalyticUsers',
    data() {
        return {
            selectedUser: '',
        };
    },
    props: {
        filteredUsers: {
            type: Array,
            required: true
        },
        selectedUsers: {
            type: Array,
            required: true
        }
    },
    computed: {

    },
    async created() {
    },
    methods: {
        addUser(selectedUser) {
            this.$emit('select-user', selectedUser);
        },
        removeUser(removedUser) {
            this.$emit('remove-user', removedUser);
        },
        closeModal() {
            this.$emit('close-users');
        },
        async getUsers() {
            this.$emit('get-users', this.selectedUser);
        }
    }
};
</script>

<style scoped>
::v-deep .search-form input {
    text-indent: 20px;
}

.popup__wrap {
    overflow-y: scroll;
    max-height: 500px;
    margin-right: 8px;
}

.popup__wrap::-webkit-scrollbar {
    width: 4px;
}

.popup__wrap::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 40px solid #CCD9E0;
}

.search-form input {
    text-indent: unset;
}

.user {
    cursor: pointer;
}

.filter-button {
    width: 100%;
    margin-bottom: 24px;
}
</style>
