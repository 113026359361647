import lists from '@/api/lists';
import {handleAction} from '@/api/ws';
import handleLoadFile from '@/api/http';
import eventHub from '@/store/events/eventHub.js'

const state = {
    clusters: null,
    projects: null,
    licenseAreas: null,
    wells: null,
    issues: null,
    statistics: null,
    wellsByAreas: null,
    rooms: null,
    roomsAmount: null,
    structureRooms: null,
    roomInfo: null,
    roomDocuments: null,
    roomMedia: null,
    messages: null,
    messagesSearch: null,
    selectedCluster: null,
    selectedProject: null,
    selectedLicenceArea: null,
    selectedWell: null,
    selectedRoom: null,
    isLoading: false
};

export const mutationTypes = {
    getClustersStart: '[lists] getClustersStart',
    getClustersSuccess: '[lists] getClustersSuccess',
    getClustersFailure: '[lists] getClustersFailure',
    getProjectsStart: '[lists] getProjectsStart',
    getProjectsSuccess: '[lists] getProjectsSuccess',
    getProjectsFailure: '[lists] getProjectsFailure',
    getLicenseAreasStart: '[lists] getLicenseAreasStart',
    getLicenseAreasSuccess: '[lists] getLicenseAreasSuccess',
    getLicenseAreasFailure: '[lists] getLicenseAreasFailure',
    getWellsStart: '[lists] getWellsStart',
    getWellsSuccess: '[lists] getWellsSuccess',
    getWellsFailure: '[lists] getWellsFailure',
    getWellsByAreasStart: '[lists] getWellsByAreasStart',
    getWellsByAreasSuccess: '[lists] getWellsByAreasSuccess',
    getWellsByAreasFailure: '[lists] getWellsByAreasFailure',
    getIssuesStart: '[lists] getIssuesStart',
    getIssuesSuccess: '[lists] getIssuesSuccess',
    getIssuesFailure: '[lists] getIssuesFailure',
    getStatisticsStart: '[lists] getStatisticsStart',
    getStatisticsSuccess: '[lists] getStatisticsSuccess',
    getStatisticsFailure: '[lists] getStatisticsFailure',
    getPersonalRoomsStart: '[lists] getPersonalRoomsStart',
    getPersonalRoomsSuccess: '[lists] getPersonalRoomsSuccess',
    getPersonalRoomsFailure: '[lists] getPersonalRoomsFailure',
    updatePersonalRoomsStart: '[lists] updatePersonalRoomsStart',
    updatePersonalRoomsSuccess: '[lists] updatePersonalRoomsSuccess',
    updatePersonalRoomsFailure: '[lists] updatePersonalRoomsFailure',
    getWorkRoomsStart: '[lists] getWorkRoomsStart',
    getWorkRoomsSuccess: '[lists] getWorkRoomsSuccess',
    getWorkRoomsFailure: '[lists] getWorkRoomsFailure',
    updateWorkRoomsStart: '[lists] updateWorkRoomsStart',
    updateWorkRoomsSuccess: '[lists] updateWorkRoomsSuccess',
    updateWorkRoomsFailure: '[lists] updateWorkRoomsFailure',
    getTypeOfResearchRoomsStart: '[lists] getTypeOfResearchRoomsStart',
    getTypeOfResearchRoomsSuccess: '[lists] getTypeOfResearchRoomsSuccess',
    getTypeOfResearchRoomsFailure: '[lists] getTypeOfResearchRoomsFailure',
    getLicenseAreaRoomsStart: '[lists] getLicenseAreaRoomsStart',
    getLicenseAreaRoomsSuccess: '[lists] getLicenseAreaRoomsSuccess',
    getLicenseAreaRoomsFailure: '[lists] getLicenseAreaRoomsFailure',
    getWellRoomsStart: '[lists] getWellRoomsStart',
    getWellRoomsSuccess: '[lists] getWellRoomsSuccess',
    getWellRoomsFailure: '[lists] getWellRoomsFailure',
    updateWellRoomsStart: '[lists] updateWellRoomsStart',
    updateWellRoomsSuccess: '[lists] updateWellRoomsSuccess',
    updateWellRoomsFailure: '[lists] updateWellRoomsFailure',
    getLogWellRoomsStart: '[lists] getLogWellRoomsStart',
    getLogWellRoomsSuccess: '[lists] getLogWellRoomsSuccess',
    getLogWellRoomsFailure: '[lists] getLogWellRoomsFailure',
    getProjectRoomsStart: '[lists] getProjectRoomsStart',
    getProjectRoomsSuccess: '[lists] getProjectRoomsSuccess',
    getProjectRoomsFailure: '[lists] getProjectRoomsFailure',
    updateProjectRoomsStart: '[lists] updateProjectRoomsStart',
    updateProjectRoomsSuccess: '[lists] updateProjectRoomsSuccess',
    updateProjectRoomsFailure: '[lists] updateProjectRoomsFailure',
    getLogProjectRoomsStart: '[lists] getLogProjectRoomsStart',
    getLogProjectRoomsSuccess: '[lists] getLogProjectRoomsSuccess',
    getLogProjectRoomsFailure: '[lists] getLogProjectRoomsFailure',
    getRoomMessagesStart: '[lists] getRoomMessagesStart',
    getRoomMessagesSuccess: '[lists] getRoomMessagesSuccess',
    getRoomMessagesFailure: '[lists] getRoomMessagesFailure',
    getRoomSolutionsStart: '[lists] getRoomSolutionsStart',
    getRoomSolutionsSuccess: '[lists] getRoomSolutionsSuccess',
    getRoomSolutionsFailure: '[lists] getRoomSolutionsFailure',
    getRoomMessagesSearchStart: '[lists] getRoomMessagesSearchStart',
    getRoomMessagesSearchSuccess: '[lists] getRoomMessagesSearchSuccess',
    getRoomMessagesSearchFailure: '[lists] getRoomMessagesSearchFailure',
    getRoomInfoStart: '[lists] getRoomInfoStart',
    getRoomInfoSuccess: '[lists] getRoomInfoSuccess',
    getRoomInfoFailure: '[lists] getRoomInfoFailure',
    getRoomDocumentsStart: '[lists] getRoomDocumentsStart',
    getRoomDocumentsSuccess: '[lists] getRoomDocumentsSuccess',
    getRoomDocumentsFailure: '[lists] getRoomDocumentsFailure',
    getRoomMediaStart: '[lists] getRoomMediaStart',
    getRoomMediaSuccess: '[lists] getRoomMediaSuccess',
    getRoomMediaFailure: '[lists] getRoomMediaFailure',
    selectCluster: '[lists] selectCluster',
    selectProject: '[lists] selectProject',
    selectLicenceArea: '[lists] selectLicenceArea',
    selectWell: '[lists] selectWell',
    selectRoom: '[lists] selectRoom',
    resetRoom: '[lists] resetRoom',
    createClusterStart: '[lists] createClusterStart',
    createClusterSuccess: '[lists] createClusterSuccess',
    createClusterFailure: '[lists] createClusterFailure',
    updateClusterStart: '[lists] updateClusterStart',
    updateClusterSuccess: '[lists] updateClusterSuccess',
    updateClusterFailure: '[lists] updateClusterFailure',
    createProjectStart: '[lists] createProjectStart',
    createProjectSuccess: '[lists] createProjectSuccess',
    createProjectFailure: '[lists] createProjectFailure',
    updateProjectStart: '[lists] updateProjectStart',
    updateProjectSuccess: '[lists] updateProjectSuccess',
    updateProjectFailure: '[lists] updateProjectFailure',
    createLicenseAreaStart: '[lists] createLicenseAreaStart',
    createLicenseAreaSuccess: '[lists] createLicenseAreaSuccess',
    createLicenseAreaFailure: '[lists] createLicenseAreaFailure',
    updateLicenseAreaStart: '[lists] updateLicenseAreaStart',
    updateLicenseAreaSuccess: '[lists] updateLicenseAreaSuccess',
    updateLicenseAreaFailure: '[lists] updateLicenseAreaFailure',
    createWellStart: '[lists] createWellStart',
    createWellSuccess: '[lists] createWellSuccess',
    createWellFailure: '[lists] createWellFailure',
    updateWellStart: '[lists] updateWellStart',
    updateWellSuccess: '[lists] updateWellSuccess',
    updateWellFailure: '[lists] updateWellFailure',
    createTaskStart: '[lists] createTaskStart',
    createTaskSuccess: '[lists] createTaskSuccess',
    createTaskFailure: '[lists] createTaskFailure',
    updateTaskStart: '[lists] updateTaskStart',
    updateTaskSuccess: '[lists] updateTaskSuccess',
    updateTaskFailure: '[lists] updateTaskFailure',
    createMessageStart: '[lists] createMessageStart',
    createMessageSuccess: '[lists] createMessageSuccess',
    createMessageFailure: '[lists] createMessageFailure',
    readMessageStart: '[lists] readMessageStart',
    readMessageSuccess: '[lists] readMessageSuccess',
    readMessageFailure: '[lists] readMessageFailure',
    addTempMessage: '[lists] addTempMessage',
    removeTempMessage: '[lists] removeTempMessage',
    closeRoomStart: '[lists] closeRoomStart',
    closeRoomSuccess: '[lists] closeRoomSuccess',
    closeRoomFailure: '[lists] closeRoomFailure',
    addParticipantsStart: '[lists] addParticipantsStart',
    addParticipantsSuccess: '[lists] addParticipantsSuccess',
    addParticipantsFailure: '[lists] addParticipantsFailure',
    removeParticipantsStart: '[lists] removeParticipantsStart',
    removeParticipantsSuccess: '[lists] removeParticipantsSuccess',
    removeParticipantsFailure: '[lists] removeParticipantsFailure',
    sendIsTypingStart: '[lists] sendIsTypingStart',
    sendIsTypingSuccess: '[lists] sendIsTypingSuccess',
    sendIsTypingFailure: '[lists] sendIsTypingFailure'
};

export const actionTypes = {
    getClusters: '[lists] getClusters',
    getProjects: '[lists] getProjects',
    getLicenseAreas: '[lists] getLicenseAreas',
    getWells: '[lists] getWells',
    getWellsByAreas: '[lists] getWellsByAreas',
    getIssues: '[lists] getIssues',
    getStatistics: '[lists] getStatistics',
    getProjectRooms: '[lists] getProjectRooms',
    updateProjectRooms: '[lists] updateProjectRooms',
    getLogProjectRooms: '[lists] getLogProjectRooms',
    getPersonalRooms: '[lists] getPersonalRooms',
    updatePersonalRooms: '[lists] updatePersonalRooms',
    getWorkRooms: '[lists] getWorkRooms',
    updateWorkRooms: '[lists] updateWorkRooms',
    getTypeOfResearchRooms: '[lists] getTypeOfResearchRooms',
    getLicenseAreaRooms: '[lists] getLicenseAreaRooms',
    getWellRooms: '[lists] getWellRooms',
    updateWellRooms: '[lists] updateWellRooms',
    getLogWellRooms: '[lists] getLogWellRooms',
    getRoomMessages: '[lists] getRoomMessages',
    getRoomSolutions: '[lists] getRoomSolutions',
    getRoomMessagesSearch: '[lists] getRoomMessagesSearch',
    getRoomSearchMessages: '[lists] getRoomSearchMessages',
    getRoomInfo: '[lists] getRoomInfo',
    getRoomDocuments: '[lists] getRoomDocuments',
    getRoomMedia: '[lists] getRoomMedia',
    createCluster: '[lists] createCluster',
    updateCluster: '[lists] updateCluster',
    createProject: '[lists] createProject',
    updateProject: '[lists] updateProject',
    createLicenseArea: '[lists] createLicenseArea',
    updateLicenseArea: '[lists] updateLicenseArea',
    createWell: '[lists] createWell',
    updateWell: '[lists] updateWell',
    createTask: '[lists] createTask',
    updateTask: '[lists] updateTask',
    createMessage: '[lists] createMessage',
    readMessage: '[lists] readMessage',
    selectCluster: '[lists] selectCluster',
    selectProject: '[lists] selectProject',
    selectLicenceArea: '[lists] selectLicenceArea',
    selectWell: '[lists] selectWell',
    closeRoom: '[lists] closeRoom',
    addParticipants: '[lists] addParticipants',
    removeParticipants: '[lists] removeParticipants',
    sendIsTyping: '[lists] sendIsTyping'
};

const mutations = {
    [mutationTypes.getClustersStart](state) {
        state.isLoading = true;
        state.clusters = null;
    },
    [mutationTypes.getClustersSuccess](state, payload) {
        state.clusters = payload.data?.results;
        state.isLoading = false;
    },
    [mutationTypes.getClustersFailure](state) {
        state.clusters = null;
        state.isLoading = false;
    },
    [mutationTypes.getProjectsStart](state) {
        state.projects = null;
    },
    [mutationTypes.getProjectsSuccess](state, payload) {
        state.projects = payload.data?.results;
    },
    [mutationTypes.getProjectsFailure]() {
    },
    [mutationTypes.getLicenseAreasStart](state) {
        state.licenseAreas = null;
    },
    [mutationTypes.getLicenseAreasSuccess](state, payload) {
        state.licenseAreas = payload.data?.results;
    },
    [mutationTypes.getLicenseAreasFailure]() {
    },
    [mutationTypes.getWellsStart](state) {
        state.wells = null;
    },
    [mutationTypes.getWellsSuccess](state, payload) {
        state.wells = payload.data?.results;
        state.selectedWell = state.wells?.find(x => x !== undefined);
    },
    [mutationTypes.getWellsFailure]() {
    },
    [mutationTypes.getWellsByAreasStart](state) {
        state.wellsByAreas = null;
    },
    [mutationTypes.getWellsByAreasSuccess](state, payload) {
        state.wellsByAreas = payload.data?.results;
    },
    [mutationTypes.getWellsByAreasFailure]() {
    },
    [mutationTypes.getIssuesStart](state) {
        state.issues = null;
    },
    [mutationTypes.getIssuesSuccess](state, payload) {
        state.issues = payload.data?.results;
    },
    [mutationTypes.getIssuesFailure]() {
    },
    [mutationTypes.getStatisticsStart]() {
    },
    [mutationTypes.getStatisticsSuccess](state, payload) {
        state.statistics = payload.data;
    },
    [mutationTypes.getStatisticsFailure](state) {
        state.statistics = null;
    },
    [mutationTypes.getPersonalRoomsStart](state, {offset}) {
        if (!offset) {
            state.rooms = null;
        }
        state.messages = null;
    },
    [mutationTypes.getPersonalRoomsSuccess](state, payload) {
        state.rooms = payload.data.previous ? [...state.rooms, ...payload.data?.results] : payload.data?.results;
        if (!payload.data.results.length) {
            state.selectedRoom = null;
            state.messages = null;
        }
    },
    [mutationTypes.getPersonalRoomsFailure]() {
    },
    [mutationTypes.updatePersonalRoomsStart]() {
    },
    [mutationTypes.updatePersonalRoomsSuccess](state, payload) {
        state.rooms = payload.data.previous ? [...state.rooms, ...payload.data?.results] : payload.data?.results;
    },
    [mutationTypes.updatePersonalRoomsFailure]() {
    },
    [mutationTypes.getWorkRoomsStart](state, {offset}) {
        if (!offset) {
            state.rooms = null;
            state.roomsAmount = null;
        }
        state.messages = null;
    },
    [mutationTypes.getWorkRoomsSuccess](state, payload) {
        state.rooms = payload.data.previous ? [...state.rooms, ...payload.data?.results] : payload.data?.results;
        state.roomsAmount = payload.data?.count;
        if (!payload.data.results.length) {
            state.selectedRoom = null;
            state.messages = null;
            state.roomsAmount = null;

        }
    },
    [mutationTypes.getWorkRoomsFailure]() {
    },
    [mutationTypes.updateWorkRoomsStart]() {
    },
    [mutationTypes.updateWorkRoomsSuccess](state, payload) {
        state.rooms = payload.data.previous ? [...state.rooms, ...payload.data?.results] : payload.data?.results;
    },
    [mutationTypes.updateWorkRoomsFailure]() {
    },
    [mutationTypes.getTypeOfResearchRoomsStart](state) {
        state.rooms = null;
        state.messages = null;
    },
    [mutationTypes.getTypeOfResearchRoomsSuccess](state, payload) {
        state.rooms = payload.data?.results;
    },
    [mutationTypes.getTypeOfResearchRoomsFailure]() {
    },
    [mutationTypes.getLicenseAreaRoomsStart](state) {
        state.rooms = null;
        state.messages = null;
    },
    [mutationTypes.getLicenseAreaRoomsSuccess](state, payload) {
        state.rooms = payload.data?.results;
    },
    [mutationTypes.getLicenseAreaRoomsFailure]() {
    },
    [mutationTypes.getRoomMessagesStart](state, {reloadMessages = true}) {
        if (reloadMessages) {
            state.messages = null;
        }
    },
    [mutationTypes.getRoomSolutionsSuccess](state, payload) {
        state.roomSolutions = payload.data?.results.reverse();
    },
    [mutationTypes.getRoomSolutionsFailure]() {
    },
    [mutationTypes.getRoomSolutionsStart](state) {
        state.roomSolutions = null;
    },
    [mutationTypes.getRoomMessagesSuccess](state, payload) {
        if (state.messages) {
            payload.data?.results.every(e => state.messages.find(m => m.id === e.id) || state.messages.push(e));
            state.messages.sort(function(a, b) {
                return new Date(a.created_at) - new Date(b.created_at);
            });
            return;
        }
        state.messages = payload.data?.results.reverse();
    },
    [mutationTypes.getRoomMessagesFailure]() {
    },
    [mutationTypes.getRoomMessagesSearchStart](state, {reloadMessages = true}) {
        if (reloadMessages) {
            state.messagesSearch = null;
        }
    },
    [mutationTypes.getRoomMessagesSearchSuccess](state, payload) {
        if (state.messagesSearch) {
            payload.data?.results.every(e => state.messagesSearch.find(m => m.id === e.id) || state.messagesSearch.push(e));
            state.messages.sort(function(a, b) {
                return new Date(a.created_at) - new Date(b.created_at);
            });
            return;
        }
        state.messagesSearch = payload.data?.results;
    },
    [mutationTypes.getRoomMessagesSearchFailure]() {
    },
    [mutationTypes.getRoomInfoStart](state) {
        state.roomInfo = null;
    },
    [mutationTypes.getRoomInfoSuccess](state, payload) {
        state.roomInfo = payload.data;
    },
    [mutationTypes.getRoomInfoFailure]() {
    },
    [mutationTypes.getRoomDocumentsStart](state) {
        state.roomDocuments = null;
    },
    [mutationTypes.getRoomDocumentsSuccess](state, payload) {
        state.roomDocuments = payload.data;
    },
    [mutationTypes.getRoomDocumentsFailure]() {
    },
    [mutationTypes.getRoomMediaStart](state) {
        state.roomMedia = null;
    },
    [mutationTypes.getRoomMediaSuccess](state, payload) {
        state.roomMedia = payload.data;
    },
    [mutationTypes.getRoomMediaFailure]() {
    },
    [mutationTypes.getWellRoomsStart](state) {
        state.isLoading = true;
        state.structureRooms = null;
        state.messages = null;
    },
    [mutationTypes.getWellRoomsSuccess](state, payload) {
        state.structureRooms = payload.data?.results;
        state.isLoading = false;
    },
    [mutationTypes.getWellRoomsFailure](state) {
        state.selectedWell = null;
        state.structureRooms = null;
        state.isLoading = false;
    },
    [mutationTypes.updateWellRoomsStart]() {
    },
    [mutationTypes.updateWellRoomsSuccess](state, payload) {
        state.structureRooms = payload.data.previous ? [...state.structureRooms, ...payload.data?.results] : payload.data?.results;
    },
    [mutationTypes.updateWellRoomsFailure]() {
    },
    [mutationTypes.getLogWellRoomsStart](state) {
        state.rooms = null;
        state.messages = null;
    },
    [mutationTypes.getLogWellRoomsSuccess](state, payload) {
        state.rooms = payload.data?.results;
    },
    [mutationTypes.getLogWellRoomsFailure]() {
    },
    [mutationTypes.getProjectRoomsStart](state) {
        state.isLoading = true;
        state.structureRooms = null;
        state.messages = null;
    },
    [mutationTypes.getProjectRoomsSuccess](state, payload) {
        state.structureRooms = payload.data?.results;
        state.isLoading = false;
    },
    [mutationTypes.getProjectRoomsFailure]() {
        state.isLoading = false;
    },
    [mutationTypes.updateProjectRoomsStart]() {
    },
    [mutationTypes.updateProjectRoomsSuccess](state, payload) {
        state.structureRooms = payload.data.previous ? [...state.structureRooms, ...payload.data?.results] : payload.data?.results;
    },
    [mutationTypes.updateProjectRoomsFailure]() {
    },
    [mutationTypes.getLogProjectRoomsStart](state) {
        state.rooms = null;
        state.messages = null;
    },
    [mutationTypes.getLogProjectRoomsSuccess](state, payload) {
        state.rooms = payload.data?.results;
    },
    [mutationTypes.getLogProjectRoomsFailure]() {
    },
    [mutationTypes.selectCluster](state, payload) {
        if (payload) {
            state.selectedCluster = state.clusters?.find(x => x.id === payload);
            state.selectedProject = state.selectedCluster?.projects?.find(x => x !== undefined);
            if (state.selectedProject) {
                state.selectedLicenceArea = state.selectedProject?.license_areas?.find(x => x !== undefined);
            } else {
                state.selectedLicenceArea = null;
                state.selectedWell = null;
            }
            state.wells = null;
            state.structureRooms = null;
        } else {
            if (!state.selectedCluster) {
                state.selectedCluster = state.clusters?.find(x => x.id !== undefined);
                state.selectedProject = state.selectedCluster?.projects?.find(x => x !== undefined);
                state.selectedLicenceArea = state.selectedProject?.license_areas?.find(x => x !== undefined);
                state.wells = null;
                state.structureRooms = null;
            }
        }
    },
    [mutationTypes.selectProject](state, payload) {
        if (payload) {
            state.selectedProject = state.selectedCluster?.projects?.find(x => x.id === payload);
            state.selectedLicenceArea = state.selectedProject?.license_areas?.find(x => x !== undefined);
            state.wells = null;
            state.rooms = null;
        } else {
            if (!state.selectedProject) {
                state.selectedProject = state.selectedCluster?.projects?.find(x => x.id !== undefined);
                state.selectedLicenceArea = state.selectedProject?.license_areas?.find(x => x !== undefined);
                state.wells = null;
                state.rooms = null;
            }
        }
    },
    [mutationTypes.selectLicenceArea](state, payload) {
        if (payload) {
            state.selectedLicenceArea = state.selectedProject?.license_areas?.find(x => x.id === payload);
            state.wells = null;
            state.rooms = null;
        } else {
            if (!state.selectedLicenceArea) {
                state.selectedLicenceArea = state.selectedProject?.license_areas?.find(x => x.id !== undefined);
                state.wells = null;
                state.rooms = null;
            }
        }
    },
    [mutationTypes.selectWell](state, payload) {
        if (payload) {
            state.selectedWell = state.wells?.find(x => x.id === payload);
        } else {
            if (!state.selectedWell) {
                state.selectedWell = state.wells?.find(x => x.id !== undefined);
            }
        }
    },
    [mutationTypes.selectRoom](state, payload) {
        if (payload) {
            state.selectedRoom = state.rooms?.find(x => x.id === payload);
        }
        if (!state.selectedRoom) {
            state.selectedRoom = state.rooms?.find(x => x.id !== undefined);
        }
    },
    [mutationTypes.resetRoom](state) {
        state.selectedRoom = state.rooms?.find(x => x.id !== undefined);
    },
    [mutationTypes.createClusterStart]() {
    },
    [mutationTypes.createClusterSuccess]() {
    },
    [mutationTypes.createClusterFailure]() {
    },
    [mutationTypes.updateClusterStart]() {
    },
    [mutationTypes.updateClusterSuccess]() {
    },
    [mutationTypes.updateClusterFailure]() {
    },
    [mutationTypes.createProjectStart]() {
    },
    [mutationTypes.createProjectSuccess]() {
    },
    [mutationTypes.createProjectFailure]() {
    },
    [mutationTypes.updateProjectStart]() {
    },
    [mutationTypes.updateProjectSuccess]() {
    },
    [mutationTypes.updateProjectFailure]() {
    },
    [mutationTypes.createLicenseAreaStart]() {
    },
    [mutationTypes.createLicenseAreaSuccess]() {
    },
    [mutationTypes.createLicenseAreaFailure]() {
    },
    [mutationTypes.updateLicenseAreaStart]() {
    },
    [mutationTypes.updateLicenseAreaSuccess]() {
    },
    [mutationTypes.updateLicenseAreaFailure]() {
    },
    [mutationTypes.createWellStart]() {
    },
    [mutationTypes.createWellSuccess]() {
    },
    [mutationTypes.createWellFailure]() {
    },
    [mutationTypes.updateWellStart]() {
    },
    [mutationTypes.updateWellSuccess]() {
    },
    [mutationTypes.updateWellFailure]() {
    },
    [mutationTypes.createTaskStart]() {
    },
    [mutationTypes.createTaskSuccess]() {
    },
    [mutationTypes.createTaskFailure]() {
    },
    [mutationTypes.updateTaskStart]() {
    },
    [mutationTypes.updateTaskSuccess]() {
    },
    [mutationTypes.updateTaskFailure]() {
    },
    [mutationTypes.addTempMessage](state, payload) {
        if (!state.messages) {
            state.messages = [];
            state.messages.push(payload);
        } else {
            state.messages.push(payload);
        }
    },
    [mutationTypes.removeTempMessage](state) {
        state.messages = state.messages.filter(message => message.temp !== true);
    },
    [mutationTypes.createMessageStart]() {
    },
    [mutationTypes.createMessageSuccess]() {
    },
    [mutationTypes.createMessageFailure]() {
    },
    [mutationTypes.readMessageStart]() {
    },
    [mutationTypes.readMessageSuccess]() {
    },
    [mutationTypes.readMessageFailure]() {
    },
    [mutationTypes.closeRoomStart]() {
    },
    [mutationTypes.closeRoomSuccess]() {
    },
    [mutationTypes.closeRoomFailure]() {
    },
    [mutationTypes.addParticipantsStart]() {
    },
    [mutationTypes.addParticipantsSuccess]() {
    },
    [mutationTypes.addParticipantsFailure]() {
    },
    [mutationTypes.removeParticipantsStart]() {
    },
    [mutationTypes.removeParticipantsSuccess]() {
    },
    [mutationTypes.removeParticipantsFailure]() {
    },
    [mutationTypes.sendIsTypingStart]() {
    },
    [mutationTypes.sendIsTypingSuccess]() {
    },
    [mutationTypes.sendIsTypingFailure]() {
    }
};
const getters = {};

const actions = {
    [actionTypes.getClusters](context) {
        context.commit(mutationTypes.getClustersStart);
        return handleAction(
            lists.getClusters(),
            async response => {
                context.commit(mutationTypes.getClustersSuccess, response);
                context.commit(mutationTypes.selectCluster, context.state?.selectedCluster.id);
                await context.dispatch(actionTypes.getStatistics, context.state?.selectedCluster.id);
            },
            response => {
                context.commit(mutationTypes.getClustersFailure, response);
            }
        );
    },
    async [actionTypes.getProjects](context, payload) {
        context.commit(mutationTypes.getProjectsStart);
        return handleAction(
            lists.getProjects(payload),
            response => {
                context.commit(mutationTypes.getProjectsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getProjectsFailure, response);
            }
        );
    },
    async [actionTypes.getLicenseAreas](context) {
        context.commit(mutationTypes.getLicenseAreasStart);
        return handleAction(
            lists.getLicenseAreas(context.state?.selectedProject?.id),
            response => {
                context.commit(mutationTypes.getLicenseAreasSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getLicenseAreasFailure, response);
            }
        );
    },
    async [actionTypes.getWells](context) {
        context.commit(mutationTypes.getWellsStart);
        if (context.state.selectedLicenceArea) {
            return handleAction(
                lists.getWells(context.state?.selectedLicenceArea?.id),
                response => {
                    context.commit(mutationTypes.getWellsSuccess, response);
                    context.dispatch(actionTypes.getWellRooms, context.state?.selectedWell?.id);
                },
                response => {
                    context.commit(mutationTypes.getWellsFailure, response);
                }
            );
        } else {
            context.commit(mutationTypes.getWellRoomsFailure);
        }
    },
    async [actionTypes.getWellsByAreas](context, payload) {
        context.commit(mutationTypes.getWellsStart);
        return handleAction(
            lists.getWellsByAreas(payload),
            response => {
                context.commit(mutationTypes.getWellsByAreasSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getWellsByAreasFailure, response);
            }
        );
    },
    async [actionTypes.getIssues](context, payload) {
        context.commit(mutationTypes.getIssuesStart);
        return handleAction(
            lists.getIssues(payload),
            response => {
                context.commit(mutationTypes.getIssuesSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getIssuesFailure, response);
            }
        );
    },
    [actionTypes.getStatistics](context, payload) {
        context.commit(mutationTypes.getStatisticsStart);
        return handleAction(
            lists.getStatistics(payload),
            async response => {
                context.commit(mutationTypes.getStatisticsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getStatisticsFailure, response);
            }
        );
    },
    async [actionTypes.getPersonalRooms](context, payload) {
        context.commit(mutationTypes.getPersonalRoomsStart, payload);
        return handleAction(
            lists.getPersonalRooms(payload),
            response => {
                context.commit(mutationTypes.getPersonalRoomsSuccess, response);
                context.commit(mutationTypes.selectRoom);
            },
            response => {
                context.commit(mutationTypes.getPersonalRoomsFailure, response);
            }
        );
    },
    async [actionTypes.updatePersonalRooms](context, payload) {
        context.commit(mutationTypes.updatePersonalRoomsStart, payload);
        return handleAction(
            lists.getPersonalRooms(payload),
            response => {
                context.commit(mutationTypes.updatePersonalRoomsSuccess, response);
                context.commit(mutationTypes.selectRoom);
            },
            response => {
                context.commit(mutationTypes.updatePersonalRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getWorkRooms](context, payload) {
        context.commit(mutationTypes.getWorkRoomsStart, payload);
        return handleAction(
            lists.getWorkRooms(payload),
            response => {
                context.commit(mutationTypes.getWorkRoomsSuccess, response);
                context.commit(mutationTypes.selectRoom);
            },
            response => {
                context.commit(mutationTypes.getWorkRoomsFailure, response);
            }
        );
    },
    async [actionTypes.updateWorkRooms](context, payload) {
        context.commit(mutationTypes.updateWorkRoomsStart, payload);
        return handleAction(
            lists.getWorkRooms(payload),
            response => {
                context.commit(mutationTypes.updateWorkRoomsSuccess, response);
                context.commit(mutationTypes.selectRoom);
            },
            response => {
                context.commit(mutationTypes.updateWorkRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getTypeOfResearchRooms](context, payload) {
        context.commit(mutationTypes.getTypeOfResearchRoomsStart, payload);
        return handleAction(
            lists.getTypeOfResearchRooms(payload),
            response => {
                context.commit(mutationTypes.getTypeOfResearchRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getTypeOfResearchRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getLicenseAreaRooms](context, payload) {
        context.commit(mutationTypes.getLicenseAreaRoomsStart, payload);
        return handleAction(
            lists.getLicenseAreaRooms(payload),
            response => {
                context.commit(mutationTypes.getLicenseAreaRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getLicenseAreaRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getRoomMessages](context, payload) {
        if (payload.id) {
            context.commit(mutationTypes.getRoomMessagesStart, payload);
            return handleAction(
                lists.getRoomMessages(payload),
                response => {
                    context.commit(mutationTypes.getRoomMessagesSuccess, {
                        ...response,
                        ...{isPushMessage: payload.isPushMessage}
                    });
                },
                response => {
                    context.commit(mutationTypes.getRoomMessagesFailure, response);
                }
            );
        }
    },
    async [actionTypes.getRoomSolutions](context, payload) {
        if (payload.id) {
            context.commit(mutationTypes.getRoomSolutionsStart, payload);
            return handleAction(
                lists.getRoomSolutions(payload),
                response => {
                    context.commit(mutationTypes.getRoomSolutionsSuccess, {
                        ...response
                    });
                },
                response => {
                    context.commit(mutationTypes.getRoomSolutionsFailure, response);
                }
            );
        }
    },
    async [actionTypes.getRoomMessagesSearch](context, payload) {
        if (payload.id) {
            context.commit(mutationTypes.getRoomMessagesSearchStart, payload);
            return handleAction(
                lists.getRoomMessages(payload),
                response => {
                    context.commit(mutationTypes.getRoomMessagesSearchSuccess, {
                        ...response,
                        ...{isPushMessage: payload.isPushMessage}
                    });
                },
                response => {
                    context.commit(mutationTypes.getRoomMessagesSearchFailure, response);
                }
            );
        }
    },
    async [actionTypes.getRoomInfo](context, payload) {
        if (payload) {
            context.commit(mutationTypes.getRoomInfoStart, payload);
            return handleAction(
                lists.getRoomInfo(payload),
                response => {
                    context.commit(mutationTypes.getRoomInfoSuccess, response);
                },
                response => {
                    context.commit(mutationTypes.getRoomInfoFailure, response);
                }
            );
        }
    },
    async [actionTypes.getRoomDocuments](context, payload) {
        if (payload) {
            context.commit(mutationTypes.getRoomDocumentsStart, payload);
            return handleAction(
                lists.getRoomDocuments(payload),
                response => {
                    context.commit(mutationTypes.getRoomDocumentsSuccess, response);
                },
                response => {
                    context.commit(mutationTypes.getRoomDocumentsFailure, response);
                }
            );
        }
    },
    async [actionTypes.getRoomMedia](context, payload) {
        if (payload) {
            context.commit(mutationTypes.getRoomMediaStart, payload);
            return handleAction(
                lists.getRoomMedia(payload),
                response => {
                    context.commit(mutationTypes.getRoomMediaSuccess, response);
                },
                response => {
                    context.commit(mutationTypes.getRoomMediaFailure, response);
                }
            );
        }
    },
    async [actionTypes.getWellRooms](context, payload) {
        context.commit(mutationTypes.getWellRoomsStart);
        return handleAction(
            lists.getWellRooms(payload),
            response => {
                context.commit(mutationTypes.getWellRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getWellRoomsFailure, response);
            }
        );
    },
    async [actionTypes.updateWellRooms](context, payload) {
        context.commit(mutationTypes.updateWellRoomsStart);
        return handleAction(
            lists.getWellRooms(payload),
            response => {
                context.commit(mutationTypes.updateWellRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.updateWellRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getLogWellRooms](context, payload) {
        context.commit(mutationTypes.getLogWellRoomsStart);
        return handleAction(
            lists.getWellRooms(payload),
            response => {
                context.commit(mutationTypes.getLogWellRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getLogWellRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getProjectRooms](context, payload) {
        context.commit(mutationTypes.getProjectRoomsStart);
        return handleAction(
            lists.getProjectRooms(payload),
            response => {
                context.commit(mutationTypes.getProjectRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getProjectRoomsFailure, response);
            }
        );
    },
    async [actionTypes.updateProjectRooms](context, payload) {
        context.commit(mutationTypes.updateProjectRoomsStart);
        return handleAction(
            lists.getProjectRooms(payload),
            response => {
                context.commit(mutationTypes.updateProjectRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.updateProjectRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getLogProjectRooms](context, payload) {
        context.commit(mutationTypes.getProjectRoomsStart);
        return handleAction(
            lists.getProjectRooms(payload),
            response => {
                context.commit(mutationTypes.getLogProjectRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getLogProjectRoomsFailure, response);
            }
        );
    },
    async [actionTypes.createCluster](context, payload) {
        context.commit(mutationTypes.createClusterStart);
        return handleAction(
            lists.createCluster(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('cluster-create-error', {title: "Ошибка", message: "Кластер с таким наименованием уже существует"});
                } else {
                    context.commit(mutationTypes.createClusterSuccess, response);
                    await context.dispatch(actionTypes.getClusters);
                    context.commit(mutationTypes.selectCluster, response.data.id);
                    eventHub.$emit('cluster-create-success', {message: "Кластер создан", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.createClusterFailure, response);
            }
        );
    },
    async [actionTypes.updateCluster](context, payload) {
        context.commit(mutationTypes.updateClusterStart);
        return handleAction(
            lists.updateCluster(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('cluster-create-error', {title: "Ошибка", message: "Кластер с таким наименованием уже существует"});
                } else {
                    context.commit(mutationTypes.updateClusterSuccess, response);
                    await context.dispatch(actionTypes.getClusters);
                    context.commit(mutationTypes.selectCluster, response.data.id);
                    eventHub.$emit('cluster-create-success', {message: "Кластер обновлен", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.updateClusterFailure, response);
            }
        );
    },
    async [actionTypes.createProject](context, payload) {
        context.commit(mutationTypes.createProjectStart);
        return handleAction(
            lists.createProject(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('project-create-error', {title: "Ошибка", message: "Проект с таким наименованием уже существует"});
                } else {
                    await context.dispatch(actionTypes.getClusters);
                    context.commit(mutationTypes.createProjectSuccess, response);
                    context.commit(mutationTypes.selectCluster, response.data.cluster);
                    context.commit(mutationTypes.selectProject, response.data.id);
                    eventHub.$emit('project-create-success', {message: "Проект создан", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.createProjectFailure, response);
            }
        );
    },
    async [actionTypes.updateProject](context, payload) {
        context.commit(mutationTypes.updateProjectStart);
        return handleAction(
            lists.updateProject(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('project-create-error', {title: "Ошибка", message: "Проект с таким наименованием уже существует"});
                } else {
                    await context.dispatch(actionTypes.getClusters);
                    context.commit(mutationTypes.updateProjectSuccess, response);
                    context.commit(mutationTypes.selectCluster, response.data.cluster);
                    context.commit(mutationTypes.selectProject, response.data.id);
                    eventHub.$emit('project-create-success', {message: "Проект обновлен", type: "success"});
                }
                await context.dispatch(actionTypes.getClusters);
            },
            response => {
                context.commit(mutationTypes.updateProjectFailure, response);
            }
        );
    },
    async [actionTypes.createLicenseArea](context, payload) {
        context.commit(mutationTypes.createLicenseAreaStart);
        return handleAction(
            lists.createLicenseArea(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('license-area-create-error', {title: "Ошибка", message: "Лицензионный участок с таким наименованием уже существует"});
                } else {
                    await context.dispatch(actionTypes.getClusters);
                    context.commit(mutationTypes.createLicenseAreaSuccess, response.data.id);
                    context.commit(mutationTypes.selectProject, response.data.project);
                    context.commit(mutationTypes.selectLicenceArea, response.data.id);
                    eventHub.$emit('license-area-create-success', {message: "Лицензионный участок создан", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.createLicenseAreaFailure, response);
            }
        );
    },
    async [actionTypes.updateLicenseArea](context, payload) {
        context.commit(mutationTypes.updateLicenseAreaStart);
        return handleAction(
            lists.updateLicenseArea(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('license-area-create-error', {title: "Ошибка", message: "Лицензионный участок с таким наименованием уже существует"});
                } else {
                    await context.dispatch(actionTypes.getClusters);
                    context.commit(mutationTypes.updateLicenseAreaSuccess, response.data.id);
                    context.commit(mutationTypes.selectProject, response.data.project);
                    context.commit(mutationTypes.selectLicenceArea, response.data.id);
                    await context.dispatch(actionTypes.getWells);
                    eventHub.$emit('license-area-create-success', {message: "Лицензионный участок обновлен", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.updateLicenseAreaFailure, response);
            }
        );
    },
    async [actionTypes.createWell](context, payload) {
        context.commit(mutationTypes.createWellStart);
        return handleAction(
            lists.createWell(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('well-create-error', {title: "Ошибка", message: "Скважина с таким наименованием уже существует"});
                } else {
                    context.commit(mutationTypes.createWellSuccess, response);
                    await context.dispatch(actionTypes.getWells);
                    eventHub.$emit('well-create-success', {message: "Скважина создана", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.createWellFailure, response);
            }
        );
    },
    async [actionTypes.updateWell](context, payload) {
        context.commit(mutationTypes.updateWellStart);
        return handleAction(
            lists.updateWell(payload),
            async response => {
                if (response.error) {
                    eventHub.$emit('well-create-error', {title: "Ошибка", message: "Скважина с таким наименованием уже существует"});
                } else {
                    await context.dispatch(actionTypes.getWells);
                    context.commit(mutationTypes.updateWellSuccess, response);
                    context.commit(mutationTypes.selectWell, response.data.id);
                    eventHub.$emit('well-create-success', {message: "Скважина обновлена", type: "success"});
                }
            },
            response => {
                context.commit(mutationTypes.updateWellFailure, response);
            }
        );
    },
    async [actionTypes.createTask](context, payload) {
        context.commit(mutationTypes.createTaskStart);
        return handleAction(
            lists.createTask(payload),
            response => {
                context.commit(mutationTypes.createTaskSuccess, response);
            },
            response => {
                context.commit(mutationTypes.createTaskFailure, response);
            }
        );
    },
    async [actionTypes.updateTask](context, payload) {
        context.commit(mutationTypes.createTaskStart);
        return handleAction(
            lists.updateTask(payload),
            response => {
                context.commit(mutationTypes.updateTaskSuccess, response);
            },
            response => {
                context.commit(mutationTypes.updateTaskFailure, response);
            }
        );
    },
    async [actionTypes.closeRoom](context, payload) {
        context.commit(mutationTypes.closeRoomStart);
        return handleAction(
            lists.closeRoom(payload),
            response => {
                context.commit(mutationTypes.closeRoomSuccess, response);
            },
            response => {
                context.commit(mutationTypes.closeRoomFailure, response);
            }
        );
    },
    async [actionTypes.addParticipants](context, payload) {
        context.commit(mutationTypes.addParticipantsStart);
        return handleAction(
            lists.addParticipants(payload),
            response => {
                context.commit(mutationTypes.addParticipantsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.addParticipantsFailure, response);
            }
        );
    },
    async [actionTypes.removeParticipants](context, payload) {
        context.commit(mutationTypes.addParticipantsStart);
        return handleAction(
            lists.removeParticipants(payload),
            response => {
                context.commit(mutationTypes.removeParticipantsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.removeParticipantsFailure, response);
            }
        );
    },
    async [actionTypes.selectCluster](context, payload) {
        context.commit(mutationTypes.selectCluster, payload);
        if (context.state.selectedCluster.cluster_type === 'ADM') {
            await context.dispatch(actionTypes.getProjectRooms, context.state?.selectedProject?.id);
        } else {
            await context.dispatch(actionTypes.selectProject);
        }
        await context.dispatch(actionTypes.getStatistics, context.state?.selectedCluster.id);
    },
    async [actionTypes.selectProject](context, payload) {
        context.commit(mutationTypes.selectProject, payload);
        if (context.state.selectedCluster.cluster_type === 'ADM') {
            await context.dispatch(actionTypes.getProjectRooms, context.state?.selectedProject?.id);
        } else {
            await context.dispatch(actionTypes.selectLicenceArea);
        }
    },
    async [actionTypes.selectLicenceArea](context, payload) {
        context.commit(mutationTypes.selectLicenceArea, payload);
        await context.dispatch(actionTypes.getWells);
        context.commit(mutationTypes.selectWell);
    },
    async [actionTypes.selectWell](context, payload) {
        context.commit(mutationTypes.selectWell, payload);
        await context.dispatch(actionTypes.getWellRooms, context.state?.selectedWell?.id);
    },
    async [actionTypes.createMessage](context, payload) {
        context.commit(mutationTypes.createMessageStart);

        if (payload.files?.length > 0) {
            return handleLoadFile(
                '/api/v1/messages/',
                payload,
                async response => {
                    context.commit(mutationTypes.createMessageSuccess, response);
                },
                response => {
                    context.commit(mutationTypes.createMessageFailure, response);
                }
            );
        }

        return handleAction(
            lists.createMessage(payload),
            async response => {
                context.commit(mutationTypes.createMessageSuccess, response);
            },
            response => {
                context.commit(mutationTypes.createMessageFailure, response);
            }
        );
    },
    async [actionTypes.readMessage](context, payload) {
        context.commit(mutationTypes.readMessageStart, payload);
        return handleAction(
            lists.readMessage(payload),
            response => {
                context.commit(mutationTypes.readMessageSuccess, response);
            },
            response => {
                context.commit(mutationTypes.readMessageFailure, response);
            }
        );
    },
    async [actionTypes.sendIsTyping](context, payload) {
        context.commit(mutationTypes.sendIsTypingStart, payload);
        return handleAction(
            lists.sendIsTyping(payload),
            response => {
                context.commit(mutationTypes.sendIsTypingSuccess, response);
            },
            response => {
                context.commit(mutationTypes.sendIsTypingFailure, response);
            }
        );
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
