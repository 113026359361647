import Vue from 'vue';
import VueRouter from 'vue-router';
import LogHeader from '@/components/LogHeader.vue';
import StructureHeader from '@/components/StructureHeader.vue';
import SideHeader from '@/components/SideHeader.vue';
import ProfileHeader from '@/components/ProfileHeader.vue';
import ProductAnalyticHeader from '@/components/ProductAnalyticHeader.vue';
import Structure from '@/views/structure/Index.vue';
import ProductAnalytic from '@/views/product-analytic/Index.vue';
import Rooms from '@/views/log/rooms/Index.vue';
import Requests from '@/views/log/requests/Index.vue';
import Messages from '@/views/log/messages/Index.vue';
import Profile from '@/views/Profile.vue';
import Analytics from '@/views/analytics/Index.vue';
import Statistics from '@/views/statistics/Index.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import NoHeader from '@/components/NoHeader';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        name: '404',
        components: {
            default: PageNotFound,
            topHeader: NoHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/',
        name: 'home',
        redirect: '/structure'
    },
    {
        path: '/desk',
        name: 'desk',
        components: {
            default: PageNotFound,
            topHeader: NoHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/desktop',
        name: 'desktop',
        components: {
            default: PageNotFound,
            topHeader: NoHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/structure',
        name: 'structure',
        components: {
            default: Structure,
            topHeader: StructureHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/product-analytic',
        name: 'product-analytic',
        components: {
            default: ProductAnalytic,
            topHeader: ProductAnalyticHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/analytics',
        name: 'analytics',
        components: {
            default: Analytics,
            topHeader: ProfileHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/statistics',
        name: 'statistics',
        components: {
            default: Statistics,
            topHeader: ProfileHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/log/rooms/:id?',
        name: 'rooms',
        components: {
            default: Rooms,
            topHeader: LogHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/log/requests',
        name: 'requests',
        components: {
            default: Requests,
            topHeader: LogHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/log/messages',
        name: 'messages',
        components: {
            default: Messages,
            topHeader: LogHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/user-profile',
        name: 'user-profile',
        components: {
            default: Profile,
            topHeader: ProfileHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    },
    {
        path: '/user-profile/:id',
        name: 'user-profile-id',
        components: {
            default: Profile,
            topHeader: ProfileHeader,
            sideHeader: SideHeader
        },
        meta: {requiredAuth: true}
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
