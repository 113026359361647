<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Новая задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='form-label'>ПАРАМЕТРЫ ЗАДАЧИ</div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Кластер</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='cluster'
                        placeholder=''
                        required='required'
                        :value='selectedCluster.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Проект</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='project'
                        placeholder=''
                        required='required'
                        :value='selectedProject.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Лицензионный участок</span><em>*</em> </label>
                    <el-select v-model='model.license_areas' multiple @change='getWellsByAreas'>
                        <el-option v-for='item in licenseAreas' :key='item.value' :label='item.name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Скважина</span><em>*</em> </label>
                    <el-select v-model='model.wells' multiple :disabled='model.license_areas && model.license_areas.length === 0'>
                        <el-option v-for='item in wellsByAreas' :key='item.value' :label='item.name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Наименование</span><em>*</em> </label>
                    <input
                        v-model='model.title'
                        class='input-block__input'
                        type='text'
                        name='name'
                        placeholder=''
                        required='required'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Тип работ</span><em>*</em> </label>
                    <el-select v-model='model.type_of_research' filterable>
                        <el-option
                            v-for='item in typesOfResearch'
                            :key='item.id'
                            :label='item.full_name'
                            :value='item.id'
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class='popup__buttons with-border'>
                <button class='button small secondary' type='button' @click='closeModal'>
                    <span>Назад</span>
                </button>
                <button class='button small' type='submit' @click='nextStep' :disabled='isDisabled'>
                    <span>Далее</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';

export default {
    name: 'CommonTaskModalStep1',
    data() {
        return {};
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            licenseAreas: state => state.lists?.licenseAreas || [],
            wellsByAreas: state => state.lists?.wellsByAreas || [],
            typesOfResearch: state => state.guides?.typesOfResearch?.results || [],
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject,
        }),
        isDisabled: function() {
            return !(this.model.title && this.model.license_areas && this.model.wells && this.model.type_of_research);
        }
    },
    async created() {
        await this.$store.dispatch(listsActionTypes.getLicenseAreas);
        await this.$store.dispatch(guidesActionTypes.getTypesOfResearch, 'common');
    },
    methods: {
        async getWellsByAreas() {
            await this.$store.dispatch(listsActionTypes.getWellsByAreas, this.model.license_areas);
            this.model.wells = [];
        },
        nextStep() {
            this.$emit('setStep', 'step2');
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.commonTaskModalClose);
        },
    }
};
</script>
