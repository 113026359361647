<template>
    <main class='main-block'>
        <div class='analytics__wrap'>
            <div class='analytics__content'>
                <div class='content__title'>
                    <h3>Сравнить активность за периоды</h3>
                    <filters :filters='filters' v-on:get-results='getResults'></filters>
                </div>
            </div>
        </div>
        <div class='analytics__wrap' v-if='items.length'>
            <div class='analytics__content'>
                <div class='content__title'>
                    <div class='results-header'>
                        <h3>Результаты</h3>
                        <button
                            class='button small excel-button'
                            type='submit'
                            @click='download'
                        >
                            <i class='svg-icon-download'></i>
                            <span>Excel</span>
                        </button>
                    </div>
                    <a-table :user-facts='items'></a-table>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import Filters from './components/Filters';
import ATable from './components/Table';
import {actionTypes as statisticsActionTypes} from '@/store/modules/statistics';
import {mapState} from 'vuex';

export default {
    name: 'GeoProductAnalytic',
    data() {
        return {
            filters: {},
            items: []
        };
    },
    components: {
        Filters,
        ATable
    },
    computed: {
        ...mapState({
            userFacts: state => state.statistics?.userFacts || []
        })
    },
    async created() {

    },
    methods: {
        async getResults(filters) {
            this.filters = filters;
            await this.$store.dispatch(statisticsActionTypes.getUserFacts, filters);
            this.items = this.userFacts;
        },
        download() {
            let url = `/api/v1/statistics/user-facts/export/?${new URLSearchParams(this.filters).toString()}`;
            let link = document.createElement('a');
            link.href = url;
            link.download = url.substr(url.lastIndexOf('/') + 1);
            link.click();
        }
    }
};
</script>

<style scoped>
.main-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.analytics__wrap {
    border-radius: 8px;
    background-color: #FFFFFF;
    height: max-content;
    width: 50%;
    gap: 40px;
}

.analytics__wrap:first-child {
    margin-right: 10px;
}

.analytics__wrap:last-child {
    margin-left: 10px;
}

.analytics__content {
    padding: 16px 40px;
}

::v-deep .el-input__inner {
    background-color: #FFFFFF !important;
}

::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}

.results-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

</style>
