const verify = () => {
    return {
        path: 'users/current',
        method: 'get'
    };
};

const logout = () => {
    return {
        path: 'logout',
        method: 'post'
    };
};

export default {
    verify,
    logout
};
