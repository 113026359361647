<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>Выберите чат для пересылки сообщения</div>
            <div class='log-information__close-button base-icon-close' @click='closeForward'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div>
                    <div class='short-tasks'>
                        <div v-for='(group, index) in groupedRooms' :key='index'>
                            <div class='short-tasks__title h3'>{{ index }}</div>
                            <div
                                v-for='room in group'
                                :key='room.id'
                                class='short-task'
                                v-bind:class="{success: room.status === 'ACTIVE', warning: room.status === 'PASSIVE'}"
                                @click.prevent='addRoom(room.id)'
                            >
                                <div class='short-task__sub-title'>{{ room.project.name }}</div>
                                <div class='short-task-group'>
                                    <div class='short-task__title'>{{ room.title }}</div>
                                    <div
                                        class='draggable-input__delete base-icon-delete-2 close-button'
                                        v-if='selectedRoom === room.id'
                                        @click.stop.prevent='removeRoom()'
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='log-information__buttons'>
                    <button class='button small' type='submit' @click.prevent='createMessage'>
                        <span>Переслать </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';

export default {
    name: 'GeoLogRoomParticipants',
    data() {
        return {
            model: {
                type_of_message: 'forward_message',
                file_name: '',
                file: [],
                room: '',
                parent_message: '',
                data: '',
                responsible: '',
                issue_message: '',
                deadline: '',
                related_message: '',
                recipients: [],
                struct: ''
            },
            participant: '',
            filteredParticipants: '',
            selectedParticipants: [],
            selectedRoom: '',
            currentSegment: 'contacts'
        };
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getUsers);
        await this.$store.dispatch(guidesActionTypes.getUserRooms, this.currentUser.id);
        this.filteredParticipants = this.availableRecipients;
    },
    props: {
        selectedRoomId: {
            type: Number,
            required: true
        },
        selectedMessageId: {
            type: Number,
            required: true
        }
    },
    watch: {
        participant: function() {
            this.filteredParticipants = this.participant
                ? this.availableRecipients.filter(participant => {
                    return participant.full_name.includes(this.participant);
                })
                : this.availableRecipients;
        }
    },
    computed: {
        ...mapState({
            availableRecipients: state => state.guides?.users?.results || [],
            userRooms: state => state.guides?.userRooms?.results || [],
            currentUser: state => state.auth?.user
        }),
        groupedRooms: function() {
            return this.userRooms.reduce((objectsByKeyValue, obj) => {
                const value = obj.cluster.name;
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                return objectsByKeyValue;
            }, {});
        }
    },
    methods: {
        addParticipant(id) {
            if (!this.selectedParticipants.includes(id)) {
                this.selectedParticipants.push(id);
            }
        },
        addRoom(id) {
            this.selectedRoom = id;
        },
        removeParticipant(id) {
            this.selectedParticipants = this.selectedParticipants.filter(participantId => {
                return participantId !== id;
            });
        },
        removeRoom() {
            this.selectedRoom = '';
        },
        async createMessage() {
            this.model = Object.assign(this.model, {
                room: this.selectedRoom ? this.selectedRoom : this.selectedRoomId,
                related_message: this.selectedMessageId,
                recipients: this.selectedParticipants
            });
            await this.$store.dispatch(listsActionTypes.createMessage, this.model);
            this.$emit('on-message-sent', {model: this.model});
            this.$emit('close-forward');
        },
        closeForward() {
            this.$emit('close-forward');
        }
    }
};
</script>

<style>
.contact-user {
    cursor: pointer;
}

.close-button {
    color: #eb5757 !important;
}

.short-task {
    cursor: pointer;
}
.short-task-group {
    display: flex;
    justify-content: space-between;
}
</style>
