<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Новая задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='search-form'>
                    <div class='search-form__input-block'>
                        <div class='search-form__button base-icon-search'></div>
                        <el-select v-model='participant' filterable remote :remote-method='getUsers'
                                   @change='addParticipant'>
                            <el-option v-for='item in users' :key='item.id' :label='item.full_name' :value='item'>
                            </el-option>
                        </el-select>
                    </div>
                    <!--                    <div class='search-form__reset'>Отмена</div>-->
                </div>
                <div class='search-results'>
                    <div class='form-label'>КОНТАКТЫ</div>
                    <div class='user' v-for='(participant, index) in model.participants' :key='participant.id'>
                        <div
                            class='user__avatar'
                            :style='{
                                backgroundImage: $getUserAvatar(participant.userpic)
                            }'
                        ></div>
                        <div class='user__ct'>
                            <div class='user__name'>{{ participant.full_name }}</div>
                        </div>
                        <div class='user__buttons' v-if='participant.id !== currentUser.id'>
                            <div class='user__remove base-icon-delete-1'
                                 @click='removeParticipant(participant, index)'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='popup__buttons'>
                <button class='button small secondary' type='button' @click='prevStep'>
                    <span>Назад</span>
                </button>
                <button class='button small' type='submit' @click='nextStep' :disabled='isDisabled'>
                    <span>Далее</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {mapState} from 'vuex';

export default {
    name: 'CommonTaskModalStep3',
    data() {
        return {
            participant: ''
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            users: state => state.guides?.users?.results || [],
            currentUser: state => state.auth?.user
        }),
        isDisabled: function() {
            return this.model.participants.length <= 1;
        }
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getUsers);
        if (!this.model.participants.some(participant => participant.id === this.currentUser.id)) {
            this.model.participants.push(this.currentUser);
        }
    },
    methods: {
        addParticipant(user) {
            if (!this.model.participants.some(participant => participant.id === user.id)) {
                this.model.participants.push(user);
            }
            this.participant = '';
            this.getUsers();
        },
        removeParticipant(user, index) {
            this.model.participants = this.model.participants.filter(function(el) {
                return el.id !== user.id;
            });
            this.model.user_tags.splice(index, 1);
        },
        nextStep() {
            this.$emit('setStep', 'step4');
        },
        prevStep() {
            this.$emit('setStep', 'step2');
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.commonTaskModalClose);
        },
        async getUsers(query = '') {
            await this.$store.dispatch(guidesActionTypes.getUsers, {query: query});
        }
    }
};
</script>

<style scoped>
::v-deep .search-form input {
    text-indent: 20px;
}

.search-form .base-icon-search {
    top: 12px;
}
</style>
