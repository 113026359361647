<template>
    <div class='breadcrumbs'>
        <span @click='goBack' class='breadcrumbs__back'><i class='base-icon-arrow-back'></i> Назад</span>
        <span class='breadcrumbs__title'>{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: 'GeoBreadcrumbs',
    props: {
        title: {
            type: String,
            required: true
        },
    },
    async created() {
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>

<style scoped>
.breadcrumbs {
    display: flex;
    align-items: center;
}
.breadcrumbs__back {
    cursor: pointer;
    color: #0096EB;
    font-size: 16px;
    font-weight: 500;

}
.breadcrumbs__title {
    margin-left: 24px;
    font-size: 24px;
    font-weight: 700;
}
</style>
