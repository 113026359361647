<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Закрыть задачу</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='log-information__title-block'>
                    <div class='log-information__title-value'>О Проекте</div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Скважина</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.well ? roomInfo.well.name : '-' }}
                    </div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Исследование</div>
                    <div class='log-information__meta-value'>{{ roomInfo.type_of_research ? roomInfo.type_of_research.full_name : '-' }}</div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Проект</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.project ? roomInfo.project.name : '-' }}
                    </div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Лицензионный участок</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.well ? roomInfo.well.license_area.name : '-' }}
                    </div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Кластер</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.cluster ? roomInfo.cluster.name : '-' }}
                    </div>
                </div>
                <div class='space' style='height: 20px'></div>
                <div class='input-block'>
                    <label class='input-block__label'><span>Ваш комментарий</span><em>*</em> </label>
                    <textarea v-model='comment' class='input-block__textarea' required='required' rows='4'></textarea>
                </div>
            </div>
            <div class='popup__buttons'>
                <button class='button small' type='submit' @click='isConfirm = true'>
                    <span>Закрыть задачу</span>
                </button>
            </div>
        </div>
        <div class='popup confirm-popup' v-if='isConfirm'>
            <div class='popup__overlay'></div>
            <div class='popup__block'>
                <div class='popup__title'>Вы уверены, что хотите закрыть задачу?</div>
                <div class='popup__buttons'>
                    <button class='button' type='submit' @click='closeRoom'><span>Закрыть</span></button>
                    <button class='button bordered brand' type='button' @click='closeModal'>
                        <span>Отменить</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';

export default {
    name: 'GeoLogCloseRoomModal',
    data() {
        return {
            isConfirm: false,
            comment: ''
        };
    },
    async created() {
    },
    computed: {
        ...mapState({
            roomInfo: state => state.lists?.roomInfo
        })
    },
    methods: {
        closeRoom() {
            this.$store.dispatch(listsActionTypes.closeRoom, {
                id: this.roomInfo.id,
                comment: this.comment
            });
            this.closeModal();
        },
        closeModal() {
            this.isConfirm = false;
            this.$emit('close-room');
        }
    }
};
</script>

<style>
.upload-file {
    color: #0096eb;
    font-size: 16px;
    font-weight: 500;
}
</style>
