<template>
    <main class='main-block without-padding'>
        <div class='page-top'>
            <div class='page-top__title h3'>Запросы</div>
            <div class='segment-buttons'>
                <div class='segment-buttons__button' v-bind:class='{current: params.input}' @click='input = true'>
                    Входящие
                </div>
                <div class='segment-buttons__button' v-bind:class='{current: !params.input}' @click='input = false'>
                    Исходящие
                </div>
            </div>
            <input
                ref="issuesSearch"
                class="search-form__input issues-search-right"
                placeholder="Поиск"
                type="text"
                @input="searchIssues"
                v-if="isSearchIssues"
            />
            <div class="issues-search-right">
                <div class="chat-header__search-button svg-icon-search" @click="showSearch"></div>
            </div>

        </div>
        <div class='inquiries row'>
            <div class='col-6'>
                <issues-list-without-solution :issues='issuesWithoutSolution'></issues-list-without-solution>
            </div>
            <div class='col-6'>
                <issues-list-with-solution :issues='issuesWithSolution'></issues-list-with-solution>
            </div>
        </div>
    </main>
</template>

<script>
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import IssuesListWithoutSolution from './components/IssuesListWithoutSolution';
import IssuesListWithSolution from './components/IssuesListWithSolution';
import {mapState} from 'vuex';

export default {
    name: 'GeoLogRequests',
    data() {
        return {
            params: {
                query: '',
                limit: 20,
                offset: 0,
                input: true
            },
            input: true,
            issuesWithoutSolution: [],
            issuesWithSolution: [],
            isSearchIssues: false
        };
    },
    components: {
        IssuesListWithoutSolution,
        IssuesListWithSolution
    },
    computed: {
        ...mapState({
            issues: state => state.lists?.issues || []
        })
    },
    watch: {
        issues: function() {
            [this.issuesWithSolution, this.issuesWithoutSolution] = this.issues.reduce(
                (result, element) => {
                    result[element.content_object.solution ? 0 : 1].push(element);
                    return result;
                },
                [[], []]
            );
        },
        input: function() {
            this.getIssues();
        }
    },
    async created() {
        await this.getIssues();
    },
    methods: {
        async getIssues(query = '') {
            this.params = Object.assign(this.params, {input: this.input, query: query});
            await this.$store.dispatch(listsActionTypes.getIssues, this.params);
        },
        showSearch() {
            this.isSearchIssues = !this.isSearchIssues;
            this.$nextTick(() => {
                this.$refs.issuesSearch.focus();
            });
        },
        searchIssues(event) {
            this.getIssues(event.target.value)
        }
    }
};
</script>

<style scoped>
.issues-search-right {
    justify-self: center;
    width: 30%;
}
</style>
