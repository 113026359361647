<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>Контакты</div>
            <div class='log-information__close-button base-icon-close' @click='closeRecipients'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div
                    class='user contact-user'
                    v-for='recipient in filteredRecipients'
                    :key='recipient.id'
                    @click='selectUser(recipient)'
                >
                    <div
                        class='user__avatar'
                        :style='{
                            backgroundImage: $getUserAvatar(recipient.userpic)
                        }'
                    />
                    <div class='user__ct'>
                        <div class='user__name'>{{ recipient.full_name }}</div>
                        <div class='user__post'>{{ $getUserTag(recipient.id) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';

export default {
    name: 'GeoLogRoomRecipients',
    data() {
        return {
            filteredRecipients: []
        };
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getAvailableRecipients, this.selectedRoomId);
        this.filteredRecipients = this.availableRecipients;
    },
    props: {
        selectedRoomId: {
            type: Number,
            required: true
        },
        recipientFio: {
            type: String,
            required: false
        }
    },
    computed: {
        ...mapState({
            availableRecipients: state => state.guides?.availableRecipients || []
        })
    },
    watch: {
        recipientFio: function() {
            this.filteredRecipients = this.recipientFio
                ? this.availableRecipients.filter(recipient => {
                    return recipient.full_name.toLowerCase().includes(this.recipientFio.toLowerCase());
                })
                : this.availableRecipients;
        }
    },
    methods: {
        closeRecipients() {
            this.$emit('close-recipients');
        },
        selectUser(recipient) {
            this.filteredRecipients = this.filteredRecipients.filter(filteredRecipient => {
                return filteredRecipient !== recipient;
            })
            this.$emit('select-recipient', recipient);
        }
    }
};
</script>

<style>
.contact-user {
    cursor: pointer;
}
</style>
