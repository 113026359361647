<template>
    <main class='main-block'>
        <div class='edit-heading'>
            <div class='edit-heading__heading h3'>
                <span>{{ selectedCluster ? selectedCluster.name : '-' }}</span
                ><a class='edit-heading__edit-link svg-icon-edit' href='#' @click.prevent='editStructure' v-if='canCreateEditCluster'></a>
            </div>
            <div class='edit-heading__sub-heading'>
                {{
                    selectedCluster
                        ? selectedCluster.cluster_type === 'GEO'
                        ? 'Геологический'
                        : 'Административный'
                        : ''
                }}
            </div>
        </div>
        <div class='empty-block' v-if='!selectedProject'>
            <div v-if='canCreateEditCluster'>
                <div class='cloud-icon gray small'><i class='svg-icon-structure3'></i></div>
                <div class='empty-block__title h4'>
                    Здесь пока ничего нет, чтобы<br />создать структуру, нажмите<br />на "+"
                </div>
                <div class='plus-circle-button' @click.prevent='openModal()'><i class='svg-icon-plus3'></i></div>
            </div>
            <div v-else>
                <div class='cloud-icon gray small'><i class='svg-icon-structure3'></i></div>
                <div class='empty-block__title h4'>
                    Здесь пока ничего нет
                </div>
            </div>

        </div>
        <div class='projects-statistic-row' v-if='selectedProject'>
            <dashboard></dashboard>
        </div>
        <div class='projects-row row no-gap' v-if='selectedProject' v-loading="isLoading">
            <projects-list v-on:select-project='selectProject' :projects='selectedCluster.projects' />
            <licence-areas-list
                v-if='selectedProject.license_areas.length'
                v-on:select-licence-area='selectLicenceArea'
                :licenseAreas='selectedProject.license_areas'
            />
            <wells-list v-if='selectedProject.license_areas.length' v-on:select-well='selectWell' />
            <rooms-list />
        </div>
        <cluster v-if='isClusterModalOpened' />
        <project v-if='isProjectModalOpened' />
        <license-area v-if='isLicenseAreaModalOpened' />
        <well v-if='isWellModalOpened' />
        <common-task v-if='isCommonTaskModalOpened' />
        <geo-task v-if='isGeoTaskModalOpened' />
        <adm-task v-if='isAdmTaskModalOpened' />
    </main>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {getterTypes as modalGetterTypes, mutationTypes as modaMutationTypes} from '@/store/modules/modals';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import ProjectsList from './components/ProjectsList';
import LicenceAreasList from './components/LicenceAreasList';
import WellsList from './components/WellsList';
import RoomsList from './components/RoomsList';

import Dashboard from '../../components/Dashboard';
import Cluster from './components/modals/Cluster';
import Project from './components/modals/Project';
import LicenseArea from './components/modals/LicenseArea';
import Well from './components/modals/Well';
import CommonTask from './components/modals/common-task/Index.vue';
import GeoTask from './components/modals/geo-task/Index.vue';
import AdmTask from './components/modals/adm-task/Index.vue';
import {can} from '@/helpers/permissions';

export default {
    name: 'GeoStructure',
    data() {
        return {};
    },
    components: {
        Dashboard,
        RoomsList,
        WellsList,
        LicenceAreasList,
        ProjectsList,
        Cluster,
        Project,
        LicenseArea,
        Well,
        CommonTask,
        GeoTask,
        AdmTask
    },
    computed: {
        ...mapState({
            clusters: state => state.lists?.clusters || [],
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject,
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            selectedWell: state => state.lists?.selectedWell,
            isLoading: state => state.lists?.isLoading,
        }),
        ...mapGetters({
            isClusterModalOpened: modalGetterTypes.clusterModalOpened,
            isProjectModalOpened: modalGetterTypes.projectModalOpened,
            isLicenseAreaModalOpened: modalGetterTypes.licenseAreaModalOpened,
            isWellModalOpened: modalGetterTypes.wellModalOpened,
            isCommonTaskModalOpened: modalGetterTypes.commonTaskModalOpened,
            isGeoTaskModalOpened: modalGetterTypes.geoTaskModalOpened,
            isAdmTaskModalOpened: modalGetterTypes.admTaskModalOpened
        }),
        canCreateEditCluster: function() {
            return can('createEditCluster');
        },
    },
    async created() {
        await this.$store.dispatch(listsActionTypes.getClusters);
        if (this.selectedCluster?.cluster_type === 'ADM' && this.selectedProject) {
            await this.$store.dispatch(listsActionTypes.getProjectRooms, this.selectedProject.id);
        } else {
            if (this.selectedWell) {
                await this.$store.dispatch(listsActionTypes.getWellRooms, this.selectedWell.id);
            }
        }
    },
    mounted() {
        this.$root.$on('rooms-changed', () => {
            if (this.selectedCluster?.cluster_type === 'ADM') {
                this.$store.dispatch(listsActionTypes.updateProjectRooms, this.selectedProject.id);
            } else {
                this.$store.dispatch(listsActionTypes.updateWellRooms, this.selectedWell.id);
            }
        })
    },
    beforeDestroy() {
        this.$root.$off("rooms-changed");
    },
    methods: {
        async selectProject(id) {
            await this.$store.dispatch(listsActionTypes.selectProject, id);
        },

        async selectLicenceArea(id) {
            await this.$store.dispatch(listsActionTypes.selectLicenceArea, id);
        },

        async selectWell(id) {
            await this.$store.dispatch(listsActionTypes.selectWell, id);
            await this.$store.dispatch(listsActionTypes.getWellRooms, this.selectedWell.id);
        },

        editStructure() {
            this.$store.commit(modalMutationTypes.clusterModalOpen, 'edit');
        },
        openModal() {
            if (this.selectedCluster?.cluster_type === 'ADM') {
                if (!this.selectedProject) {
                    this.$store.commit(modaMutationTypes.projectModalOpen);
                } else {
                    this.$store.commit(modalMutationTypes[this.selectedCluster.cluster_type.toLocaleLowerCase() + 'TaskModalOpen']);
                }
            } else if (!this.selectedProject) {
                this.$store.commit(modaMutationTypes.projectModalOpen);
            } else if (!this.selectedLicenceArea) {
                this.$store.commit(modaMutationTypes.licenseAreaModalOpen);
            } else if (!this.selectedWell) {
                this.$store.commit(modaMutationTypes.wellModalOpen);
            } else {
                this.$store.commit(modalMutationTypes[this.selectedCluster.cluster_type.toLocaleLowerCase() + 'TaskModalOpen']);
            }
        }
    }
};
</script>

<style>
.with-title > .select-block__button {
    display: block;
}
</style>
