<template>
    <div class='col'>
        <div class='projects-filter'>
            <div class='edit-heading'>
                <div class='edit-heading__heading h3'  v-if='selectedWell && selectedCluster.cluster_type !== "ADM"'>
                    <span>{{ wellName }}</span>
                    <a class='edit-heading__edit-link svg-icon-edit' href='#' @click.prevent='editWell' v-if='canCreateEditWell'></a>
                    <a class="edit-heading__add-link svg-icon-plus2" href="#" @click.prevent="openModal()" v-if='canCreateEditWell'></a>
                </div>
            </div>
            <div class='projects-filter__buttons'>
                <div class='projects-filter__button' v-bind:class='{active: showActive}' @click='showActive = true'>
                    Активные
                </div>
                <div class='projects-filter__button' v-bind:class='{active: !showActive}' @click='showActive = false'>
                    Закрытые
                </div>
            </div>
        </div>
        <div class='empty-block' v-if='!activeRooms.length && !passiveRooms.length'>
            <div v-if='canCreateEditWell'>
                <div class='cloud-icon gray small'><i class='svg-icon-structure3'></i></div>
                <div class='empty-block__title h4'>
                    Здесь пока ничего нет, чтобы<br />создать структуру, нажмите<br />на "+"
                </div>
                <div class='plus-circle-button' @click.prevent="openModal()"><i class='svg-icon-plus3'></i></div>
            </div>
            <div v-else>
                <div class='cloud-icon gray small'><i class='svg-icon-structure3'></i></div>
                <div class='empty-block__title h4'>
                    Здесь пока ничего нет
                </div>
            </div>

        </div>
        <rooms-list-elements v-else :rooms='showActive ? activeRooms : passiveRooms'></rooms-list-elements>
        <div class='plus-circle-button' @click.prevent="openModal()" v-if='canCreateEditWell'><i class='svg-icon-plus3'></i></div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import RoomsListElements from '@/views/structure/components/RoomsListElements';
import {mutationTypes as modalMutationTypes, mutationTypes as modaMutationTypes} from '@/store/modules/modals';
import {can} from '@/helpers/permissions';

export default {
    name: 'RoomsList',
    data() {
        return {
            showActive: true,
        };
    },
    components: {
        RoomsListElements
    },
    computed: {
        ...mapState({
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject,
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            selectedWell: state => state.lists?.selectedWell,
            activeRooms: state => state.lists?.structureRooms?.filter(room => room.status === 'ACTIVE') || [],
            passiveRooms: state => state.lists?.structureRooms?.filter(room => room.status === 'PASSIVE') || []
        }),
        wellName: function() {
            return this.selectedWell?.name || '';
        },
        isWellSelected: function() {
            return !!this.selectedWell;
        },
        canCreateEditWell: function() {
            return can('createEditWell');
        }
    },
    async created() {
        // await this.$store.dispatch(listsActionTypes.getWorks);
    },
    methods: {
        selectWell(id) {
            this.$emit('select-well', id);
        },
        editWell() {
            this.$store.commit(modaMutationTypes.wellModalOpen, 'edit');
        },
        openModal() {
            if (this.selectedCluster?.cluster_type === 'ADM') {
                this.$store.commit(modalMutationTypes[this.selectedCluster.cluster_type.toLocaleLowerCase() + 'TaskModalOpen']);
            } else if (!this.selectedProject) {
                this.$store.commit(modaMutationTypes.projectModalOpen);
            } else if (!this.selectedLicenceArea) {
                this.$store.commit(modaMutationTypes.licenseAreaModalOpen);
            } else if (!this.selectedWell) {
                this.$store.commit(modaMutationTypes.wellModalOpen);
            } else {
                this.$store.commit(modalMutationTypes[this.selectedCluster.cluster_type.toLocaleLowerCase() + 'TaskModalOpen']);
            }
        }
    }
};
</script>

<style scoped></style>
