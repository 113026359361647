import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import '@/assets/css/style.min.css';
import {actionTypes} from '@/store/modules/auth';
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import {getUserTag, getUserAvatar} from '@/helpers/user';


Vue.use(VueVideoPlayer);
Vue.use(ElementUI, {locale});
Vue.use(InfiniteLoading, {
    props: {
        spinner: 'spiral'
    }
});

Vue.config.productionTip = false;
Vue.prototype.$getUserTag = getUserTag
Vue.prototype.$getUserAvatar = getUserAvatar

router.beforeEach((to, from, next) => {
    let client = axios({
        method: 'get',
        url: `/api/v1/users/current/`,
        headers: {
            Accept: 'application/json'
        }
    });
    client
        .then(function(response) {
            store.dispatch(actionTypes.setCurrentUser, response).then(() => {
                next();
            });
        })
        .catch((error) => {
            if (error.response.status === 401) {
                location.href = '/login?next=/structure';
            }
        });
});



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
