import {format, differenceInDays} from 'date-fns';
import {ru} from 'date-fns/locale';

let mixin = {
    data() {
        return {};
    },
    methods: {
        getDate(date) {
            return format(new Date(date), 'dd.MM.yyyy');
        },

        getTime(date) {
            return format(new Date(date), 'HH:mm');
        },

        getDateTime(date) {
            return format(new Date(date), 'dd.MM.yyyy HH:mm');
        },

        getFullMonthDate(date) {
            return format(new Date(date), 'dd MMMM yyyy', {locale: ru});
        },
        getLastActivity(date) {
            return format(new Date(date), 'dd.MM.yyyy HH:mm');
        },
        getDifferenceInDays(startDate, endDate) {
            return differenceInDays(startDate, endDate)
        }
    }
};

export default mixin;
