<template>
    <main class='main-block'>
        <div class='profile row'>
            <div class='profile__info col'>
                <div class='profile__wrap'>
                    <div class='profile__details-block'>
                        <div
                            class='profile__avatar'
                            :style='{
                                backgroundImage: $getUserAvatar(user.userpic)
                            }'
                        ></div>
                        <div class='profile__details-col'>
                            <div class='profile__name h3'>{{ user.full_name }}</div>
                            <div class='profile__activity'>
                                Был(а) в сети {{ user.last_login ? getLastActivity(user.last_login) : 'давно' }}
                            </div>
                            <div class='profile__holiday'>
                                {{
                                    user.profile && user.profile.status_display_name
                                        ? user.profile.status_display_name
                                        : '-'
                                }}
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>Должность</div>
                                <div class='profile__meta-value h4'>{{ user.profile && user.profile.title ? user.profile.title : '-' }}</div>
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>Руководитель</div>
                                <div class='profile__meta-value h4'>
                                    {{ user.profile && user.profile.manager ? managerFullName : '-' }}
                                </div>
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>Отдел</div>
                                <div class='profile__meta-value h4'>
                                    {{ user.profile && user.profile.department ? user.profile.department : '-' }}
                                </div>
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>Организация</div>
                                <div class='profile__meta-value h4'>
                                    {{ user.profile && user.profile.company ? user.profile.company : '-' }}
                                </div>
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>Рабочий телефон</div>
                                <div class='profile__meta-value h4'>
                                    {{ user.profile && user.profile.phone ? user.profile.phone : '-' }}
                                </div>
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>Мобильный телефон</div>
                                <div class='profile__meta-value h4'>
                                    {{ user.profile && user.profile.mobile ? user.profile.mobile : '-' }}
                                </div>
                            </div>
                            <div class='profile__meta'>
                                <div class='profile__meta-label'>E-mail</div>
                                <div class='profile__meta-value h4'>{{ user.email ? user.email : '-' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='profile__advanced col' v-if='isCurrentUser'>
                <div class='rquests-block'>
                    <div class='rquests-block__title'>ЗАПРОСЫ</div>
                    <a class='rquests-block__link disabled-block-opacity' href='#'>
                        <span class='rquests-block__label h4'
                        >Запросы на добавление нового исследования в справочник</span
                        >
                        <span class='rquests-block__count'>0</span><i class='base-icon-arrow-right'></i>
                    </a>
                    <a class='rquests-block__link disabled-block-opacity' href='#'>
                        <span class='rquests-block__label h4'>Запросы на изменение режима работы</span>
                        <span class='rquests-block__count'>0</span><i class='base-icon-arrow-right'></i>
                    </a>
                </div>
                <a class='box-link disabled-block-opacity' href='#'>
                    <span class='h4'>Настройка уведомлений</span><i class='base-icon-arrow-right'></i>
                </a>
                <div class='profile-switch-box'>
                    <span class='h4'>Включить темную тему</span>
                    <div class='input-block switcher-block'>
                        <label class='switcher'
                        ><input type='checkbox' :checked='isDarkTheme' @change='toggleDarkTheme' /><span></span
                        ></label>
                    </div>
                </div>
                <router-link to='/analytics' class='box-link'>
                    <span class='h4'>
                        <div>Аналитическая сводка по моей работе</div>
                        <div class='button-description'>Расчетные показатели без учета сложности и исключительных случаев</div>
                    </span>
                    <i class='base-icon-arrow-right'></i>
                </router-link>
                <router-link to='/statistics' class='box-link' v-if='createEditProject'>
                    <span class='h4'>
                        <div>Статистика по проекту</div>
                        <div class='button-description'>Расчетные показатели без учета сложности и исключительных случаев</div>
                    </span>
                    <i class='base-icon-arrow-right'></i>
                </router-link>

            </div>
            <div class='profile__advanced col' v-else>
                <div class='chats-block'>
                    <div class='chats-block__title h4'><i class='svg-icon-task'> </i><span>Чаты</span></div>
                    <div class='chats-block__tasks'>
                        <div
                            class='chat-task'
                            v-for='room in currentUserRooms'
                            :key='room.id'
                            :class="{
                                active: room.status === 'ACTIVE',
                                warning: room.status === 'PASSIVE'
                            }"
                        >
                            <div class='chat-task__top'>
                                <div class='chat-task__status'></div>
                                <div class='chat-task__caption'>
                                    {{ room.cluster ? room.cluster.name : '-' }} |
                                    {{ room.project ? room.project.name : '-' }}
                                </div>
                                <div class='chat-task__date'>
                                    {{ room.start_date ? getDate(room.start_date) : '-' }}
                                </div>
                            </div>
                            <div class='chat-task__title'>{{ room.title }}</div>
                            <div class='chat-task__desc'>
                                {{ room.last_message && room.last_message.data ? room.last_message.data.slice(0, 80) + '...' : ''
                                }}
                            </div>
                            <div class='chat-task__meta'>
                                <div class='chat-task__new-msg-count'>
                                    <i class='svg-icon-down'></i><span>{{ room.unread_issues_count }}</span>
                                </div>
                                <div class='chat-task__msg-count'>
                                    <i class='svg-icon-cloud'> </i><span>{{ room.unread_messages_count }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as settingsActionTypes} from '@/store/modules/settings';
import Dates from '@/mixins/Dates';
import {can} from '@/helpers/permissions';

export default {
    name: 'GeoProfile',
    data() {
        return {};
    },
    mixins: [Dates],
    watch: {
        '$route.params.id': {
            handler: function(id) {
                this.getUser(id);
            },
            immediate: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.guides?.user || {},
            currentUser: state => state.auth?.user || {},
            currentUserRooms: state => state.guides?.userRooms?.results || [],
            isDarkTheme: state => state.settings?.isDarkTheme
        }),
        managerFullName: function() {
            return this.user.profile.manager.first_name + ' ' + this.user.profile.manager.last_name;
        },
        isCurrentUser: function() {
            return this.user.id === this.currentUser.id;
        },
        createEditProject: function() {
            return can('createEditProject');
        },
    },
    created() {
    },
    methods: {
        async getUser(id) {
            await this.$store.dispatch(guidesActionTypes.getUser, id);
            if (!this.isCurrentUser) {
                await this.$store.dispatch(guidesActionTypes.getUserRooms, id);
            }
        },
        toggleDarkTheme() {
            this.$store.dispatch(settingsActionTypes.toggleDarkTheme);
        }
    }
};
</script>

<style scoped>
.button-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14286;
    margin-top: 6px;
}
</style>
