<template>
    <div :id='"messageId-" + message.id'>
        <div class='user' v-if='isShowAuthor'>
            <router-link :to="`/user-profile/${message.author.id}`">
                <div
                    class='user__avatar'
                    :style='{
                        backgroundImage: $getUserAvatar(message.author.userpic)
                    }'
                ></div>
            </router-link>
            <div class='user__ct'>
                <div class='user__name'>{{ message.author.full_name }}</div>
                <div class='user__post'>{{ $getUserTag(message.author.id) }}</div>
            </div>
        </div>
        <div class='message-cloud' @contextmenu.prevent='showMenu'>
            <div class='message-cloud__wrap'>
                <div class='message-cloud__content'>
                    <div class='message-cloud__forward'>
                        <div class='message-cloud__forward-title'>Пересланное сообщение</div>
                        <div class='message-cloud__forward-sub-title'>
                            {{ message.related_message.room.project ? message.related_message.room.project.name : '-' }} |
                            {{ message.related_message.room.well ? message.related_message.room.well.name : '-' }} | {{ message.related_message.room.title }}
                        </div>
                        <div class='message-cloud__forward-author'>{{ message.related_message.author.full_name }}</div>
                        <div class='message-cloud__forward-text' v-html='formattedMessage'></div>
                    </div>
                </div>
                <div class='message-cloud__meta'>
                    <div class='message-cloud__time'>{{ getTime(message.created_at) }}</div>
                    <div
                        v-if='currentUser.id === message.author.id'
                        class='message-cloud__message-status'
                        v-bind:class='{
                            "svg-icon-read": message.read,
                            "svg-icon-delivered": !message.read,
                            "svg-icon-timelapse": !message.id
                        }'
                    >
                    </div>
                </div>
            </div>
        </div>
        <message-menu
            v-if='isShowMenu'
            :is-request='false'
            @close-menu='isShowMenu = false'
            @show-history='showHistory(message.id)'
            @show-forward='showForward(message.id)'
            @reply='reply(message)'
            @create-issue-answer='createIssueAnswer'
        ></message-menu>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';
import MessageMenu from './MessageMenu';
import {mapState} from 'vuex';
import linkifyHtml from 'linkify-html';

export default {
    name: 'GeoLogForwardMessage',
    data() {
        return {
            isShowMenu: false
        };
    },
    mixins: [Dates],
    components: {
        MessageMenu
    },
    computed: {
        ...mapState({
            currentUser: state => state.auth?.user || {}
        }),
        isCurrentResponsible: function() {
            return this.message.content_object.responsible.id === this.currentUser.id;
        },
        formattedMessage: {
            get() {
                const options = { defaultProtocol: 'http', target: '_blank' };
                let message = this.message.data ? linkifyHtml(this.message.data, options) : this.message.data;
                if (this.message.recipients.length) {
                    this.message.recipients.forEach(recipient => {
                        let pattern = new RegExp(`@${recipient.full_name}`);
                        message = message.replace(
                            pattern,
                            `<span style='color:#0071B2'>@${recipient.full_name}</span>`
                        );
                    });
                    return message;
                }
                return message;
            }
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        isShowAuthor: {
            type: Boolean,
            required: true
        }
    },
    async created() {

    },
    methods: {
        reply(message) {
            this.$emit('reply', message);
        },
        showHistory(id) {
            this.$emit('show-history', id);
        },
        showForward(id) {
            this.$emit('show-forward', id);
        },
        showMenu(e) {
            let chatWrap = e.target.closest('.chat-wrap').getBoundingClientRect();
            let koeffX = e.clientX - chatWrap.left;
            let koeffY = e.clientY - chatWrap.top;

            this.isShowMenu = true;
            this.$nextTick(() => {
                this.$root.$emit('set-menu', koeffY, koeffX, chatWrap);
            });
        },
        createIssueAnswer() {
            this.$emit('create-issue-answer');
        }
    }
};
</script>

<style></style>
