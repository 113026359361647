import store from '@/store';

const PERMISSION_LEVELS = {
    admin: 5,
    exact_cluster_owner: 4,
    exact_project_owner: 3,
    exact_project_owner_1: 3,
    expert: 2,
    partner: 1
};

const PERMISSIONS = {
    createEditCluster: {
        admin: true,
        exact_cluster_owner: false,
        exact_project_owner: false,
        exact_project_owner_1: false,
        expert: false,
        partner: false
    },
    createEditProject: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: false,
        exact_project_owner_1: false,
        expert: false,
        partner: false
    },
    createEditLicenseArea: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    createEditWell: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    viewMessageHistory: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: true
    },
    createResearch: {
        admin: true,
        exact_cluster_owner: false,
        exact_project_owner: false,
        exact_project_owner_1: false,
        expert: false,
        partner: false
    },
    offerResearch: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    createRoomByResearch: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    addParticipantsByResearch: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    fillResearchInfo: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    createMessage: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: true
    },
    createRequest: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: true
    },
    createIssueWithRequest: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: true
    },
    createIssueWithoutRequest: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    addParticipantsWithoutResearch: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    viewCommonInfo: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: false
    },
    createPersonalRoom: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: false
    },
    createPrivateRoom: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    },
    createPrivateIssueWithRequest: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: true,
        partner: false
    },
    createAutoProtocol: {
        admin: true,
        exact_cluster_owner: true,
        exact_project_owner: true,
        exact_project_owner_1: true,
        expert: false,
        partner: false
    }
};

function can(action) {
    const highestRole = getHighestRole(store.state.auth.roles);
    // const highestRole = getHighestRole(['admin']);
    if (!PERMISSIONS[action][highestRole]) {
        return false;
    }
    switch (action) {
        case 'createEditProject':
            switch (highestRole) {
                case 'admin':
                    return true;
                case 'exact_cluster_owner':
                    if (store.state.lists.selectedCluster?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                default:
                    return PERMISSIONS[action][highestRole];
            }
        case 'createEditLicenseArea':
        case 'createEditWell':
        case 'createRoomByResearch':
        case 'addParticipantsByResearch':
        case 'fillResearchInfo':
            switch (highestRole) {
                case 'admin':
                    return true;
                case 'exact_cluster_owner':
                    if (store.state.lists.selectedCluster?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                case 'exact_project_owner':
                    if (store.state.lists.selectedProject?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                case 'exact_project_owner_1':
                    if (store.state.lists.selectedProject?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                default:
                    return PERMISSIONS[action][highestRole];
            }
        case 'viewMessageHistory':
            switch (highestRole) {
                case 'admin':
                    return true;
                case 'exact_cluster_owner':
                    if (store.state.lists.selectedCluster?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                case 'exact_project_owner':
                    if (store.state.lists.selectedProject?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                case 'exact_project_owner_1':
                    if (store.state.lists.selectedProject?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                case 'expert':
                    if (store.state.lists.selectedProject?.owner_id === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                case 'partner':
                    if (store.state.lists.selectedRoom?.author === store.state.auth.user.id) {
                        return true;
                    }
                    return false;
                default:
                    return PERMISSIONS[action][highestRole];
            }
        default:
            return PERMISSIONS[action][highestRole];
    }
}

function getHighestRole(roles) {
    return Object.values(roles).reduce((a, b) => (PERMISSION_LEVELS[a] > PERMISSION_LEVELS[b] ? a : b));
}

export {can};
