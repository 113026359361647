import auth from '@/api/auth';
import { handleAction } from '@/api/ws';

const state = {
    user: null,
    roles: null,
    isLoggedIn: null
};

export const mutationTypes = {
    verifyStart: '[auth] verifyStart',
    verifySuccess: '[auth] verifySuccess',
    verifyFailure: '[auth] verifyFailure',
    logoutStart: '[auth] logoutStart',
    logoutSuccess: '[auth] logoutSuccess',
    logoutFailure: '[auth] logoutFailure',
    setCurrentUser: '[auth] setCurrentUser'
};

export const actionTypes = {
    verify: '[auth] verify',
    logout: '[auth] logout',
    setCurrentUser: '[auth] setCurrentUser'
};

export const getterTypes = {
    isLoggedIn: '[auth] isLoggedIn',
    isAnonymous: '[auth] isAnonymous',
    currentUserId: '[auth] currentUserId'
};

const getters = {
    [getterTypes.isLoggedIn]: state => {
        return Boolean(state.isLoggedIn);
    },
    [getterTypes.isAnonymous]: state => {
        return state.isLoggedIn === false;
    },
    [getterTypes.currentUserId]: state => {
        return state.user.id;
    }
};

const mutations = {
    [mutationTypes.verifyStart]() {
    },
    [mutationTypes.verifySuccess](state, payload) {
        state.isLoggedIn = true;
        state.user = payload.data?.user;
        state.roles = payload.data?.roles;
    },
    [mutationTypes.verifyFailure](state) {
        state.user = null;
        state.roles = null;
        state.isLoggedIn = false;
    },
    [mutationTypes.logoutStart]() {
    },
    [mutationTypes.logoutSuccess](state) {
        state.isLoggedIn = false;
        state.user = null;
        state.roles = null;
    },
    [mutationTypes.logoutFailure](state) {
        state.user = null;
        state.roles = null;
        state.isLoggedIn = false;
    },
    [mutationTypes.setCurrentUser](state, payload) {
        state.isLoggedIn = true;
        state.user = payload.data?.user;
        state.roles = payload.data?.roles;
    }
};

const actions = {
    [actionTypes.verify](context) {
        context.commit(mutationTypes.verifyStart);
        return handleAction(
            auth.verify(),
            response => {
                context.commit(mutationTypes.verifySuccess, response);
            },
            response => {
                context.commit(mutationTypes.verifyFailure, response);
            }
        );
    },
    [actionTypes.logout](context) {
        context.commit(mutationTypes.logoutStart);
        return handleAction(
            auth.logout(),
            response => {
                context.commit(mutationTypes.logoutSuccess, response);
            },
            response => {
                context.commit(mutationTypes.logoutFailure, response);
            }
        );
    },
    [actionTypes.setCurrentUser](context, payload) {
        context.commit(mutationTypes.setCurrentUser, payload);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
