<template>
    <header class='header'>
        <div class='site-logo'>
            <div class='site-logo__link' href='#'>GEO<span>Структура</span></div>
        </div>
        <div class='input-block select-block with-title'>
            <div class='select-block__button'>
                <div class='select-block__button__title'>Кластер</div>
                <div class='select-block__button__label'>
                    {{ selectedCluster ? selectedCluster.name : 'Выберите кластер' }}
                </div>
                <i class='base-icon-arrow-down'></i>
            </div>
            <div class='dropdown'>
                <div
                    class='dropdown__item dropdown__type-select'
                    v-for='(item, index) in headerItems'
                    :key='index'
                    v-on:click='selectCluster(item.id)'
                >
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
        <div class='header__right' v-if='
                            canCreateEditWell ||
                                canCreateEditLicenseArea ||
                                canCreateEditProject ||
                                canCreateEditCluster
                        '>
            <div class='header__create-button'>
                <a class='button small' href='#'><span>Создать</span></a>
                <div class='dropdown'>
                    <div class='dropdown__item dropdown__type-link' v-if='canCreateEditCluster'>
                        <a href='#' @click.prevent="openModal('cluster')">Кластер</a>
                    </div>
                    <div class='dropdown__item dropdown__type-link'
                         v-if='canCreateEditProject || canCreateEditCluster'>
                        <a href='#' @click.prevent="openModal('project')">Проект</a>
                    </div>
                    <div
                        class='dropdown__item dropdown__type-link'
                        v-if='(canCreateEditLicenseArea || canCreateEditProject || canCreateEditCluster) && selectedCluster && selectedCluster.cluster_type === "GEO" && selectedProject'
                    >
                        <a href='#' @click.prevent="openModal('licenseArea')">Лицензионный участок</a>
                    </div>
                    <div
                        class='dropdown__item dropdown__type-link'
                        v-if='
                            (canCreateEditWell ||
                                canCreateEditLicenseArea ||
                                canCreateEditProject ||
                                canCreateEditCluster) &&
                                selectedCluster &&
                                selectedCluster.cluster_type === "GEO" &&
                                selectedLicenceArea
                        '
                    >
                        <a href='#' @click.prevent="openModal('well')">Скважина</a>
                    </div>
                    <div class='dropdown__item dropdown__type-link' v-if='
                            canCreateEditWell ||
                                canCreateEditLicenseArea ||
                                canCreateEditProject ||
                                canCreateEditCluster
                        '>
                        <a href='#' @click.prevent="openModal('commonTask')">Общая задача</a>
                    </div>
                </div>
            </div>
            <div class='search-button disabled-block-opacity'><i class='svg-icon-search'></i></div>
        </div>
    </header>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {can} from '@/helpers/permissions';

export default {
    name: 'GeoStructureHeader',
    computed: {
        ...mapState({
            clusters: state => state.lists?.clusters || [],
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject,
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            roles: state => state.auth?.roles
        }),
        headerItems: function() {
            return this.clusters.map(item => {
                return {id: item.id, name: item.name};
            });
        },
        canCreateEditCluster: function() {
            return can('createEditCluster');
        },
        canCreateEditProject: function() {
            return can('createEditProject');
        },
        canCreateEditLicenseArea: function() {
            return can('createEditLicenseArea');
        },
        canCreateEditWell: function() {
            return can('createEditWell');
        }
    },
    created() {
    },
    methods: {
        selectCluster(id) {
            this.$store.dispatch(listsActionTypes.selectCluster, id);
        },
        openModal(name) {
            this.$store.commit(modalMutationTypes[name + 'ModalOpen']);
        }
    }
};
</script>

<style scoped></style>
