<template>
    <div class='inquiries__wrap'>
        <div class='inquiries__top'>
            <div class='inquiries__title h3'>С решением</div>
        </div>
        <div class='inquiries__date' v-if='issues.length'>
            <span>{{ getFullMonthDate(issues[0].created_at) }}</span>
            <div class='order-button'><i class='svg-icon-sort-less-more'></i></div>
        </div>
        <div class='inquiries__tasks complited-tasks'>
            <div
                class='task-item'
                v-for='issue in issues'
                v-bind:key='issue.id'
                v-bind:class='{expired: issue.content_object.expired}'
            >
                <router-link :to="{path: `/log/rooms/${issue.room_id}`, params: {issueId: issue.id}}">
                    <div class='project-short-details'>
                        <div class='project-short-details__status'></div>
                        <a class='project-short-details__link' href='#'>{{
                                issue.room.type_of_research ? issue.room.type_of_research.name : '-'
                            }}</a>
                        <div class='project-short-details__sep'>|</div>
                        <div class='project-short-details__sb'>
                            {{ issue.room.project ? issue.room.project.name : '-' }}
                        </div>
                        <div class='project-short-details__sep'>|</div>
                        <div class='project-short-details__sb'>{{ issue.room.well ? issue.room.well.name : '-' }}</div>
                    </div>
                    <div class='user'>
                        <router-link :to="`/user-profile/${issue.author.id}`">
                            <div
                                class='user__avatar'
                                :style='{
                                    backgroundImage: $getUserAvatar(issue.author.userpic)
                                }'
                            ></div>
                        </router-link>
                        <div class='user__ct'>
                            <div class='user__name'>{{ issue.author.full_name }}</div>
                            <div class='user__post' v-show='false'>{{ $getUserTag(issue.author.id) }}</div>
                        </div>
                    </div>
                    <div class='message-cloud'>
                        <div class='message-cloud__wrap'>
                            <div class='message-cloud__content'>
                                <div class='message-cloud__quote'>
                                    <div class='message-cloud__request-in-quote noaccess'>
                                        <div class='message-cloud__request-title'>
                                            <span>Запрос № {{ issue.number_by_type }}</span>
                                        </div>
                                        <div class='message-cloud__request-in-quote-sep base-icon-arrow-back'></div>
                                        <div class='message-cloud__request-in-quote-author'>
                                            {{ issue.content_object.responsible.full_name }}
                                        </div>
                                    </div>
                                    {{ issue.data }}
                                </div>
                                <div class='message-cloud__solution-block'>
                                    <div class='message-cloud__solution-title'>
                                        Решение №{{ issue.content_object.solution.number_by_type }}
                                    </div>
                                    <div class='message-cloud__solution-date'>
                                        {{ getDate(issue.content_object.deadline) }}
                                    </div>
                                    <div class='message-cloud__solution-text'>
                                        {{ issue.content_object.solution.data }}
                                    </div>
                                    <!--                                <div class='message-cloud__attachments'>-->
                                    <!--                                    <div class='file-block'>-->
                                    <!--                                        <div class='file-block__icon'>-->
                                    <!--                                            <div class='base-icon-file'></div>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class='file-block__meta'>-->
                                    <!--                                            <div class='file-block__name'>Название документа .pdf</div>-->
                                    <!--                                            <div class='file-block__size'>1.0 MB</div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    <div class='file-block verifying'>-->
                                    <!--                                        <div class='file-block__icon'>-->
                                    <!--                                            <div class='svg-icon-verify'></div>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class='file-block__meta'>-->
                                    <!--                                            <div class='file-block__name'>Название документа .pdf</div>-->
                                    <!--                                            <div class='file-block__size'>1.0 MB</div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                    <div class='file-block verified'>-->
                                    <!--                                        <div class='file-block__icon'>-->
                                    <!--                                            <div class='base-icon-verify-done'></div>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class='file-block__meta'>-->
                                    <!--                                            <div class='file-block__name'>Название документа .pdf</div>-->
                                    <!--                                            <div class='file-block__size'>1.0 MB</div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                </div>
                            </div>
                            <div class='message-cloud__meta'>
                                <div class='message-cloud__time'>{{ getTime(issue.created_at) }}</div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogIssuesWithSolution',
    data() {
        return {};
    },
    props: {
        issues: {
            type: Array,
            required: true
        }
    },
    mixins: [Dates],
    components: {},
    computed: {},
    async created() {
    },
    methods: {
    }
};
</script>

<style scoped>
.inquiries__tasks {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
}

.task-item {
    flex: 1;
    max-width: 50%;
    margin: 10px;
}

.message-cloud__content {
    width: 100%;
}

.task-item {
    cursor: pointer;
}
</style>
