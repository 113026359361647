<template>
    <div class='image-popup'>
        <div class='image-popup__overlay'>
        </div>
        <div class='image-popup__meta'>
            <div class='image-popup__count'>Фотография 1
            </div>
            <div class='image-popup__author'>{{ imagePopupFile.author }}
            </div>
            <div class='image-popup__date'>{{ getDate(imagePopupFile.created_at) }} в {{ getTime(imagePopupFile.created_at) }}
            </div>
        </div>
        <div class='image-popup__button close-button base-icon-close' @click='close'>
        </div>
<!--        <div class='image-popup__button prev-button base-icon-arrow-left'>-->
<!--        </div>-->
<!--        <div class='image-popup__button next-button base-icon-arrow-right'>-->
<!--        </div>-->
<!--        <div class='image-popup__buttons'>-->
<!--            <div class='image-popup__button base-icon-rotate-90'>-->
<!--            </div>-->
<!--            <div class='image-popup__button base-icon-download'>-->
<!--            </div>-->
<!--            <div class='image-popup__button more base-icon-dots-vertical'>-->
<!--            </div>-->
<!--        </div>-->
        <div class='image-popup__image'><img :src='imagePopupFile.url' alt='' />
        </div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {mapState} from 'vuex';

export default {
    name: 'GeoImagePopup',
    data() {
        return {
        }
    },
    mixins: [Dates],
    computed: {
        ...mapState({
            imagePopupFile: state => state.modals?.imagePopupFile,
        }),
    },
    methods: {
        close() {
            this.$store.commit(modalMutationTypes.imagePopupClose);
        }
    }
};
</script>

<style scoped>

</style>
