<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3 warning'>
                <i class="el-icon-back" @click="closeInfo" style='cursor: pointer'></i>
                &nbsp;Решения
            </div>
        </div>
        <div class='log-information__wrap'>
            <div class='chat-message' v-for='(solution) in roomSolutions' :key='solution.id'>
                <div class='user'>
                    <router-link
                        :to="`/user-profile/${solution.author.id}`">
                        <div
                            class='user__avatar'
                            :style='{
                                backgroundImage: $getUserAvatar(solution.author.userpic)
                            }'
                        ></div>
                    </router-link>
                    <div class='user__ct'>
                        <div class='user__name'>
                            {{ solution.author.full_name }}
                        </div>
                        <div class='user__post'>{{ $getUserTag(solution.author.id) }}</div>
                    </div>
                </div>
                <div class='message-cloud'>
                    <div class='message-cloud__wrap'>
                        <div class='message-cloud__content'>
                            <div class='message-cloud__solution-block'>
                                <div class='message-cloud__solution-title'>
                                    Решение №{{ solution.id }}
                                </div>
                                <div class='message-cloud__solution-date'>
                                    {{ getDate(solution.created_at) }}
                                </div>
                                <div class='message-cloud__solution-text'>
                                    {{ solution.data }}
                                </div>
                            </div>
                        </div>
                        <div class='message-cloud__meta'>
                            <div class='message-cloud__time'>15:15</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogSolutionsInfo',
    data() {
        return {};
    },
    mixins: [Dates],
    computed: {
        ...mapState({
            roomSolutions: state => state.lists?.roomSolutions || [],
        }),
    },
    created() {
    },
    methods: {
        closeInfo() {
            this.$emit('close-info');
        },
    }
};
</script>
