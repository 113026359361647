import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/modules/auth';
import lists from '@/store/modules/lists';
import guides from '@/store/modules/guides';
import modals from '@/store/modules/modals';
import settings from '@/store/modules/settings';
import statistics from '@/store/modules/statistics';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        auth,
        lists,
        guides,
        modals,
        settings,
        statistics
    },
    plugins: [createPersistedState()]
});

export default store
