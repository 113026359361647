<template>
    <div :id='"messageId-" + message.id'>
        <div class='user' v-if='isShowAuthor'>
            <router-link :to="`/user-profile/${message.author.id}`">
                <div
                    class='user__avatar'
                    :style='{
                        backgroundImage: $getUserAvatar(message.author.userpic)
                    }'
                ></div>
            </router-link>
            <div class='user__ct'>
                <div class='user__name'>{{ message.author.full_name }}</div>
                <div class='user__post'>{{ $getUserTag(message.author.id) }}</div>
            </div>
        </div>
        <div v-for='file in message.messagefile_set' v-bind:key='file.id'>
            <div class='message-cloud__content'>
                <div v-html='formattedMessage'></div>
                <div class='message-cloud__quote' v-if='message.parent_message'>
                    {{ message.parent_message.data }}
                </div>
            </div>
            <div v-if='file.file_content_type.includes("image")' class='image-cloud landscape' :style="{
                        backgroundImage: `url(${file.preview})`
                    }" @click='openImage(file)'>
                <div class='image-cloud__meta'>
                    <div class='image-cloud__time'>{{ getTime(message.created_at) }}
                    </div>
                    <div
                        v-if='user.id === message.author.id'
                        class='message-cloud__message-status'
                        v-bind:class='{
                            "svg-icon-read": message.read,
                            "svg-icon-delivered": !message.read,
                            "svg-icon-timelapse": !message.id
                        }'
                    >
                    </div>
                </div>
            </div>

            <div v-else-if='file.file_content_type.includes("video")' class='image-cloud landscape' :style="{
                        backgroundImage: `url(${file.preview})`
                    }" @click='openVideo(file)'>
                <div class='image-cloud__meta'>
                    <div class='image-cloud__time'>{{ getTime(message.created_at) }}
                    </div>
                    <div
                        v-if='user.id === message.author.id'
                        class='message-cloud__message-status'
                        v-bind:class='{
                            "svg-icon-read": message.read,
                            "svg-icon-delivered": !message.read,
                            "svg-icon-timelapse": !message.id
                        }'
                    >
                    </div>
                </div>
            </div>
            <div class="message-cloud audio-cloud" v-else-if='file.file_content_type.includes("audio")' @click='openAudio(file)'>
                <div class="message-cloud__wrap">
                    <div class="message-cloud__play-button"><i class="base-icon-video"> </i><i
                        class="base-icon-pause"></i>
                    </div>
                    <div class="message-cloud__content">
                        <div class="voice-wave">
                            <div style="width: 0%"></div>
                        </div>
                        <div class="message-cloud__duration">
                        </div>
                    </div>
                    <div class="message-cloud__meta">
                        <div class="message-cloud__time">{{ getTime(message.created_at) }}
                        </div>
                        <div class="message-cloud__message-status svg-icon-read">
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class='message-cloud'
                @contextmenu.prevent='showMenu'
            >
                <div class='message-cloud__wrap'>
                    <div class='message-cloud__content'>
                        <div class='message-cloud__attachments' :v-if='message.messagefile_set.length'>
                        <a
                            class='file-block'
                            v-for='file in message.messagefile_set'
                            v-bind:key='file.id'
                            :href='file.file_data'
                            :download='file.file_name'
                        >
                            <div class='file-block__icon'>
                                <div>
                                    <div class='base-icon-file'></div>
                                </div>
                            </div>
                            <div class='file-block__meta'>
                                <div class='file-block__name'>{{ file.file_name }}</div>
                                <div class='file-block__size'>{{ fileSize(file.file_size) }}</div>
                            </div>
                        </a>
                        </div>
                    </div>
                    <div class='message-cloud__meta'>
                        <div class='message-cloud__time'>{{ getTime(message.created_at) }}</div>
                        <div
                            v-if='user.id === message.author.id'
                            class='message-cloud__message-status'
                            v-bind:class='{
                            "svg-icon-read": message.read,
                            "svg-icon-delivered": !message.read,
                            "svg-icon-timelapse": !message.id
                        }'
                        >
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <message-menu
            v-if='isShowMenu'
            :is-request='false'
            @close-menu='isShowMenu = false'
            @show-history='showHistory(message.id)'
            @show-forward='showForward(message.id)'
            @reply='reply(message)'
        ></message-menu>


    </div>
</template>

<script>
import Dates from '@/mixins/Dates';
import MessageMenu from './MessageMenu';
import {mapState} from 'vuex';
import linkifyHtml from 'linkify-html';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';

export default {
    name: 'GeoLogBinaryMessage',
    data() {
        return {
            showImage: '',
            isShowMenu: false,
            isShowImage: false
        };
    },
    mixins: [Dates],
    components: {
        MessageMenu
    },
    computed: {
        ...mapState({
            user: state => state.auth?.user
        }),
        formattedMessage: {
            get() {
                const options = { defaultProtocol: 'http', target: '_blank' };
                let message = linkifyHtml(this.message.data, options);
                if (this.message.recipients.length) {

                    this.message.recipients.forEach(recipient => {
                        let pattern = new RegExp(`@${recipient.full_name}`);
                        message = message.replace(
                            pattern,
                            `<span style='color:#0071B2'>@${recipient.full_name}</span>`
                        );
                    });
                    return message;
                }
                return message;
            }
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        isShowAuthor: {
            type: Boolean,
            required: true
        }
    },
    async created() {
    },
    methods: {
        showMenu(e) {
            let chatWrap = e.target.closest('.chat-wrap').getBoundingClientRect();
            let koeffX = e.clientX - chatWrap.left;
            let koeffY = e.clientY - chatWrap.top;

            this.isShowMenu = true;
            this.$nextTick(() => {
                this.$root.$emit('set-menu', koeffY, koeffX, chatWrap);
            });
        },
        reply(message) {
            this.$emit('reply', message);
        },
        showHistory(id) {
            this.$emit('show-history', id);
        },
        showForward(id) {
            this.$emit('show-forward', id);
        },
        openImage(file) {
            this.$store.commit(modalMutationTypes.imagePopupOpen, {
                url: file.file_data,
                created_at: file.created_at,
                author: this.message.author.full_name,
            });
        },
        openAudio(file) {
            this.$store.commit(modalMutationTypes.videoPopupOpen, {
                url: file.file_data,
                created_at: file.created_at,
                author: this.message.author.full_name,
                type: 'audio/mp3'
            });
        },
        openVideo(file) {
            this.$store.commit(modalMutationTypes.videoPopupOpen, {
                url: file.file_data,
                created_at: file.created_at,
                author: this.message.author.full_name,
                type: 'video/mp4'
            });
        },
        fileSize(size) {
            if (size <= 1024) {
                return size + ' B';
            }
            return parseFloat(size / 1024).toFixed(2) + ' KB';
        }
    }
};
</script>

<style scoped>
.image-cloud {
    cursor: pointer;
}
</style>
