<template>
    <div class='chat-message center'>
        <div class='new-chat-message'>{{ typing }} набирает сообщение...</div>
    </div>
</template>

<script>
export default {
    name: 'GeoLogTypingMessage',
    data() {
        return {};
    },
    components: {},
    computed: {},
    props: {
        typing: {
            type: String,
            required: true
        }
    },
    async created() {
    },
    methods: {}
};
</script>

<style></style>
