import guides from '@/api/guides';
import {handleAction} from '@/api/ws';

const state = {
    clusterTypes: null,
    userTags: null,
    projects: null,
    wells: null,
    wellsCount: null,
    messageReadings: null,
    typesOfResearch: null,
    user: null,
    userRooms: null,
    users: null,
    availableRecipients: null
};

export const mutationTypes = {
    getClusterTypesStart: '[guides] getClusterTypesStart',
    getClusterTypesSuccess: '[guides] getClusterTypesSuccess',
    getClusterTypesFailure: '[guides] getClusterTypesFailure',
    getUsersStart: '[guides] getUsersStart',
    getUsersSuccess: '[guides] getUsersSuccess',
    getUsersFailure: '[guides] getUsersFailure',
    getUserStart: '[guides] getUserStart',
    getUserSuccess: '[guides] getUserSuccess',
    getUserFailure: '[guides] getUserFailure',
    getAvailableRecipientsStart: '[guides] getAvailableRecipientsStart',
    getAvailableRecipientsSuccess: '[guides] getAvailableRecipientsSuccess',
    getAvailableRecipientsFailure: '[guides] getAvailableRecipientsFailure',
    getUserTagsStart: '[guides] getUserTagsStart',
    getUserTagsSuccess: '[guides] getUserTagsSuccess',
    getUserTagsFailure: '[guides] getUserTagsFailure',
    getUserRoomsStart: '[guides] getUserRoomsStart',
    getUserRoomsSuccess: '[guides] getUserRoomsSuccess',
    getUserRoomsFailure: '[guides] getUserRoomsFailure',
    getProjectsStart: '[guides] getProjectsStart',
    getProjectsSuccess: '[guides] getProjectsSuccess',
    getProjectsFailure: '[guides] getProjectsFailure',
    getMessageReadingsStart: '[guides] getMessageReadingsStart',
    getMessageReadingsSuccess: '[guides] getMessageReadingsSuccess',
    getMessageReadingsFailure: '[guides] getMessageReadingsFailure',
    getWellsStart: '[guides] getWellsStart',
    getWellsSuccess: '[guides] getWellsSuccess',
    getWellsFailure: '[guides] getWellsFailure',
    getTypesOfResearchStart: '[guides] getTypesOfResearchStart',
    getTypesOfResearchSuccess: '[guides] getTypesOfResearchSuccess',
    getTypesOfResearchFailure: '[guides] getTypesOfResearchFailure'
};

export const actionTypes = {
    getClusterTypes: '[guides] getClusterTypes',
    getUsers: '[guides] getUsers',
    getUser: '[guides] getUser',
    getAvailableRecipients: '[guides] getAvailableRecipients',
    getUserTags: '[guides] getUserTags',
    getUserRooms: '[guides] getUserRooms',
    getMessageReadings: '[guides] getMessageReadings',
    getProjects: '[guides] getProjects',
    getWells: '[guides] getWells',
    getTypesOfResearch: '[guides] getTypesOfResearch'
};

export const getterTypes = {
    isAllWells: '[guides] isAllWells',
    users: '[guides] users'
};

const mutations = {
    [mutationTypes.getClusterTypesStart]() {
    },
    [mutationTypes.getClusterTypesSuccess](state, payload) {
        state.clusterTypes = payload.data;
    },
    [mutationTypes.getClusterTypesFailure](state) {
        state.clusterTypes = null;
    },
    [mutationTypes.getUsersStart]() {
    },
    [mutationTypes.getUsersSuccess](state, payload) {
        state.users = payload.data;
    },
    [mutationTypes.getUsersFailure](state) {
        state.users = null;
    },
    [mutationTypes.getUserStart]() {
    },
    [mutationTypes.getUserSuccess](state, payload) {
        state.user = payload.data;
    },
    [mutationTypes.getUserFailure](state) {
        state.user = null;
    },
    [mutationTypes.getProjectsStart]() {
    },
    [mutationTypes.getProjectsSuccess](state, payload) {
        state.projects = payload.data;
    },
    [mutationTypes.getProjectsFailure](state) {
        state.projects = null;
    },
    [mutationTypes.getWellsStart](state, {offset}) {
        if (!offset) {
            state.wells = null;
        }
        state.wellsCount = null;
    },
    [mutationTypes.getWellsSuccess](state, payload) {
        state.wells = state.wells ? [...state.wells, ...payload.data?.results] : payload.data?.results;
        state.wellsCount = payload.data?.count;
    },
    [mutationTypes.getWellsFailure](state) {
        state.wells = null;
        state.wellsCount = null;
    },
    [mutationTypes.getAvailableRecipientsStart]() {
    },
    [mutationTypes.getAvailableRecipientsSuccess](state, payload) {
        state.availableRecipients = payload.data;
    },
    [mutationTypes.getAvailableRecipientsFailure](state) {
        state.availableRecipients = null;
    },
    [mutationTypes.getUserTagsStart]() {
    },
    [mutationTypes.getUserTagsSuccess](state, payload) {
        state.userTags = payload.data;
    },
    [mutationTypes.getUserTagsFailure](state) {
        state.userTags = null;
    },
    [mutationTypes.getUserRoomsStart]() {
    },
    [mutationTypes.getUserRoomsSuccess](state, payload) {
        state.userRooms = payload.data;
    },
    [mutationTypes.getUserRoomsFailure](state) {
        state.userRooms = null;
    },
    [mutationTypes.getMessageReadingsStart](state) {
        state.messageReadings = null;
    },
    [mutationTypes.getMessageReadingsSuccess](state, payload) {
        state.messageReadings = payload.data.readings;
    },
    [mutationTypes.getMessageReadingsFailure]() {
    },
    [mutationTypes.getTypesOfResearchStart]() {
    },
    [mutationTypes.getTypesOfResearchSuccess](state, payload) {
        state.typesOfResearch = payload.data;
    },
    [mutationTypes.getTypesOfResearchFailure](state) {
        state.typesOfResearch = null;
    }
};

const getters = {
    [getterTypes.isAllWells]: state => {
        if (state.wells) {
            return state.wells.length === state.wellsCount;
        }
        return false;
    },
    [getterTypes.users]: state => {
        return state.users;
    }
};

const actions = {
    async [actionTypes.getClusterTypes](context) {
        context.commit(mutationTypes.getClusterTypesStart);
        return handleAction(
            guides.getClusterTypes(),
            response => {
                context.commit(mutationTypes.getClusterTypesSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getClusterTypesFailure, response);
            }
        );
    },
    async [actionTypes.getUsers](context, payload) {
        if (!payload) {
            payload = {
                query: ''
            };
        }
        context.commit(mutationTypes.getUsersStart);
        return handleAction(
            guides.getUsers(payload),
            response => {
                context.commit(mutationTypes.getUsersSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getUsersFailure, response);
            }
        );
    },
    async [actionTypes.getUser](context, payload) {
        context.commit(mutationTypes.getUserStart);
        return handleAction(
            guides.getUser(payload),
            response => {
                context.commit(mutationTypes.getUserSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getUserFailure, response);
            }
        );
    },
    async [actionTypes.getAvailableRecipients](context, payload) {
        context.commit(mutationTypes.getAvailableRecipientsStart);
        return handleAction(
            guides.getAvailableRecipients(payload),
            response => {
                context.commit(mutationTypes.getAvailableRecipientsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getAvailableRecipientsFailure, response);
            }
        );
    },
    async [actionTypes.getUserTags](context) {
        context.commit(mutationTypes.getUserTagsStart);
        return handleAction(
            guides.getUserTags(),
            response => {
                context.commit(mutationTypes.getUserTagsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getUserTagsFailure, response);
            }
        );
    },
    async [actionTypes.getUserRooms](context, payload) {
        context.commit(mutationTypes.getUserTagsStart);
        return handleAction(
            guides.getUserRooms(payload),
            response => {
                context.commit(mutationTypes.getUserRoomsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getUserRoomsFailure, response);
            }
        );
    },
    async [actionTypes.getProjects](context) {
        context.commit(mutationTypes.getProjectsStart);
        return handleAction(
            guides.getProjects(),
            response => {
                context.commit(mutationTypes.getProjectsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getProjectsFailure, response);
            }
        );
    },
    async [actionTypes.getWells](context, payload) {
        context.commit(mutationTypes.getWellsStart, payload);
        return handleAction(
            guides.getWells(payload),
            response => {
                context.commit(mutationTypes.getWellsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getWellsFailure, response);
            }
        );
    },
    async [actionTypes.getMessageReadings](context, payload) {
        if (payload) {
            context.commit(mutationTypes.getMessageReadingsStart, payload);
            return handleAction(
                guides.getMessageReadings(payload),
                response => {
                    context.commit(mutationTypes.getMessageReadingsSuccess, response);
                },
                response => {
                    context.commit(mutationTypes.getMessageReadingsFailure, response);
                }
            );
        }
    },
    async [actionTypes.getTypesOfResearch](context, payload) {
        context.commit(mutationTypes.getTypesOfResearchStart);
        return handleAction(
            guides.getTypesOfResearch(payload),
            response => {
                context.commit(mutationTypes.getTypesOfResearchSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getTypesOfResearchFailure, response);
            }
        );
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
