import WebSocketAsPromised from 'websocket-as-promised';
import eventHub from '@/store/events/eventHub.js';
import {format} from 'date-fns';
import store from '@/store';

let wsProtocol = location.protocol === 'https:' ? 'wss' : 'ws';
const wsp = new WebSocketAsPromised(`${wsProtocol}://${window.location.host}/ws/gateway/`, {
    packMessage: data => JSON.stringify(data),
    unpackMessage: data => JSON.parse(data.toString()),
    attachRequestId: (data, requestId) => Object.assign({rid: requestId}, data),
    extractRequestId: data => data && data.rid
});

wsp.onClose.addListener(() => {
        eventHub.$emit('wsl-response-error', {
            message: 'Ошибка получения данных, проверьте ваше соединение с интернетом',
            type: 'error'
        });
    }
);

let promise = wsp.open();

function handleAction(path, successCallback, failedCallback) {
    console.log('PATH:', path);
    return promise
        .then(() => wsp.sendRequest(path))
        .then(response => {
            console.log('RESPONSE:', response);
            if (response.error?.code >= 400) {
                eventHub.$emit('wsl-response-error', {
                    message: response.error.code + '<br>' +
                        'Ошибка получения данных, обратитесь к администратору.<br>' +
                        "<span style='font-size: 12px;'>" +
                            format(new Date(), 'dd.MM.yyyy HH:mm') + " " +
                            store.state.auth?.user.last_name + " " + store.state.auth?.user.first_name +
                        "</span>",
                    type: 'error'
                });
            }
            successCallback(response);
        })
        .catch(error => {
            console.log('ERROR:', error);
            failedCallback();
        });
}

export {
    handleAction,
    wsp
};
