<template>
    <div>
        <div class='ranges'>
            <div class='ranges-block'>
                <span class='ranges-block__title'>Период 1</span>
                <div class='ranges-block__dates'>
                    <div class='input-block select-block'>
                        <label class='input-block__label'>
                            <span>От</span>
                            <em>*</em>
                        </label>
                        <el-date-picker
                            v-model='filters.from0'
                            type='date'
                            format='dd.MM.yyyy'
                            placeholder='дд.мм.гггг'
                            value-format='yyyy-MM-dd'
                            :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                        >
                        </el-date-picker>
                    </div>
                    <div class='input-block select-block'>
                        <label class='input-block__label'>
                            <span>До</span>
                            <em>*</em>
                        </label>
                        <el-date-picker
                            v-model='filters.to0'
                            type='date'
                            format='dd.MM.yyyy'
                            placeholder='дд.мм.гггг'
                            value-format='yyyy-MM-dd'
                            :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                        >
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class='ranges-block'>
                <span class='ranges-block__title'>Период 2</span>
                <div class='ranges-block__dates'>
                    <div class='input-block select-block'>
                        <label class='input-block__label'>
                            <span>От</span>
                            <em>*</em>
                        </label>
                        <el-date-picker
                            v-model='filters.from1'
                            type='date'
                            format='dd.MM.yyyy'
                            placeholder='дд.мм.гггг'
                            value-format='yyyy-MM-dd'
                            :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                        >
                        </el-date-picker>
                    </div>
                    <div class='input-block select-block'>
                        <label class='input-block__label'>
                            <span>До</span>
                            <em>*</em>
                        </label>
                        <el-date-picker
                            v-model='filters.to1'
                            type='date'
                            format='dd.MM.yyyy'
                            placeholder='дд.мм.гггг'
                            value-format='yyyy-MM-dd'
                            :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                        >
                        </el-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class='users'>
            <div class='input-block'>
                <label class='input-block__label'>
                    <span>Пользователи</span><em>*</em>
                </label>
                <div @click='openUsersModal'>
                    <span class='input-block__edit-icon svg-icon-edit search-form__button'></span>
                    <input class='input-block__input' type='text' :value='usersAmount' disabled />
                </div>
            </div>
        </div>
        <div class='control'>
            <button
                class='button control__button'
                type='submit'
                :disabled='isDisabledButton'
                @click='getResults'
            >
                <span>Показать</span>
            </button>
        </div>
        <users v-if='isShowUsers' :filtered-users='filteredUsers' :selected-users='selectedUsers'
               @close-users='isShowUsers = false' v-on:select-user='selectUser' v-on:remove-user='removeUser'
               v-on:get-users='getUsers' />

    </div>
</template>

<script>
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import Users from './Users';
import {declOfNum} from '@/helpers/declension';
import {mapState} from 'vuex';

export default {
    name: 'GeoProductAnalyticFilter',
    data() {
        return {
            isShowUsers: false,
            filters: {
                from0: '',
                to0: '',
                from1: '',
                to1: '',
                users: []
            },
            filteredUsers: [],
            selectedUsers: []

        };
    },
    components: {
        Users
    },
    computed: {
        usersAmount: function() {
            return this.selectedUsers.length ? 'Выбрано ' + this.selectedUsers.length + ' ' + declOfNum(this.selectedUsers.length, ['пользователь', 'пользователя', 'пользователей']) : 'Все пользователи GEO';
        },
        ...mapState({
            users: state => state.guides?.users?.results || []
        }),
        isDisabledButton: function() {
            return !(this.filters.from0 && this.filters.to0 && this.filters.from1 && this.filters.to1);
        }
    },
    props: {},
    async created() {
        await this.$store.dispatch(guidesActionTypes.getUsers);
        this.filteredUsers = this.users.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
    },
    methods: {
        openUsersModal() {
            this.isShowUsers = true;
        },
        selectUser(selectedUser) {
            this.selectedUsers.push(selectedUser);
            this.filteredUsers = this.filteredUsers.filter(function(el) {
                return el.id !== selectedUser.id;
            });
        },
        removeUser(removedUser) {
            this.filteredUsers.push(removedUser);
            this.filteredUsers = this.filteredUsers.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
            this.selectedUsers = this.selectedUsers.filter(function(el) {
                return el.id !== removedUser.id;
            });
        },
        async getUsers(user) {
            await this.$store.dispatch(guidesActionTypes.getUsers, {query: user});
            this.filteredUsers = this.users.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
        },
        getResults() {
            this.filters = Object.assign(this.filters, {
                users: this.selectedUsers.map(({ id }) => id)
            })
            this.$emit('get-results', this.filters);
        }
    }
};
</script>

<style scoped>
.ranges {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 20px;
}

.ranges-block {
    background-color: #ECF1F4;
    border-radius: 8px;
    padding: 16px;
    flex: 1;
    text-align: center;
}

.ranges-block__title {
    font-size: 16px;
    font-weight: 500;
}

.ranges-block__dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    margin-top: 12px;
    text-align: left;
}

.input-block {
    margin: 0;
}

.users {
    display: flex;
    margin-bottom: 24px;
}

.users .input-block {
    width: 50%;
    padding-right: 20px;
}

.input-block__input {
    cursor: pointer;
}

.input-block__edit-icon {
    top: 28px;
    left: unset;
    right: 32px;
}

.control {
    width: 50%;

    display: flex;
    padding-right: 20px;
}

.control__button {
    width: 100%;
    margin-bottom: 48px;
}
</style>
