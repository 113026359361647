// let uploadProgress = []
// let progressBar = document.getElementById('progress-bar')

let mixin = {
    data() {
        return {
            uploadProgress: [],
            progress: 0,
            uploadUrl: '/api/v1/messages/',
            params: {}
        };
    },
    methods: {
        initFileLoader(element, highlightCallback, unhighlightCallback) {
            ['dragenter', 'dragover','drop'].forEach(eventName => {
                element.addEventListener(eventName, (e) => e.preventDefault(), false)
                document.body.addEventListener(eventName, (e) => e.preventDefault(), false)
            });

            ['dragenter', 'dragover'].forEach(eventName => {
                element.addEventListener(eventName, highlightCallback, false)
            });

            [ 'drop'].forEach(eventName => {
                element.addEventListener(eventName, unhighlightCallback, false)
            });

            element.addEventListener('drop', this.handleDrop, false)
        },

        handleDrop(e) {
            let dt = e.dataTransfer
            let files = dt.files

            this.handleFiles(files)
        },

        initializeProgress(numFiles) {
            this.progress = 0
            this.uploadProgress = []

            for(let i = numFiles; i > 0; i--) {
                this.uploadProgress.push(0)
            }
        },

        updateProgress(fileNumber, percent) {
            this.uploadProgress[fileNumber] = percent
            let total = this.uploadProgress.reduce((tot, curr) => tot + curr, 0) / this.uploadProgress.length
            console.debug('update', fileNumber, percent, total)
            this.progress = total
        },

        handleFiles(files) {
            files = [...files]
            this.initializeProgress(files.length)
            files.forEach(this.uploadFile)
            files.forEach(this.previewFile)
        },

        previewFile(file) {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function() {
                let img = document.createElement('img')
                img.src = reader.result
                // document.getElementById('gallery').appendChild(img)
            }
        },

        uploadFile(file) {

            this.fileList.push({
                name: file.name,
                percentage: 0,
                raw: file,
            });
        }
    }
};

export default mixin;
