<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>
                Добавить решение
            </div>
            <div class='log-information__close-button base-icon-close' @click='closeSolution'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='input-block'>
                <label class='input-block__label'>
                    <span>Текст решения</span>
                    <em>*</em>
                </label>
                <el-input v-model='model.data' type='textarea' :rows='4' required='required'></el-input>
            </div>
            <div class='input-block'>
                <el-upload
                    class="upload-demo"
                    action='/api/v1/messages/'
                    :auto-upload="false"
                    ref='upload'
                    :file-list='fileList'
                    :show-file-list='false'
                    :on-change='handleChange'
                >
                    <div>
                        <span class='chat-header__search-button svg-icon-pin'></span>
                        <span class='upload-file' slot="trigger">Добавить файл</span>
                    </div>
                </el-upload>
            </div>
            <div v-if='fileList'>
                <ul class='filelist'>
                    <li v-for='(file, index) in fileList' :key='index'>{{ file.name }}</li>
                </ul>
            </div>
            <div class='popup__buttons'>
                <button class='button small' type='submit' @click='createSolution' :disabled='isDisabled'>
                    <span>Добавить решение</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mapState} from 'vuex';

export default {
    name: 'GeoLogRoomSolution',
    data() {
        return {
            model: {
                type_of_message: 'solution_without_request',
                room: '',
                parent_message: '',
                data: '',
                responsible: '',
                issue_message: '',
                deadline: '',
                related_message: '',
                recipients: [],
                struct: ''
            },
            fileList: [],
            recipient: '',
            date: '',
            time: '',
        };
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getAvailableRecipients, this.selectedRoom.id);
    },
    computed: {
        ...mapState({
            selectedRoom: state => state.lists?.selectedRoom,
        }),
        isDisabled: function() {
            return !(this.model.data);
        }
    },
    methods: {
        async createSolution() {
            this.model = Object.assign(this.model, {
                room: this.selectedRoom.id,
                files: this.fileList,
            });
            await this.$store.dispatch(listsActionTypes.createMessage, this.model);
            await this.$store.dispatch(listsActionTypes.getRoomSolutions, {id: this.selectedRoom.id});
            this.$emit('on-message-sent', {model: this.model});
            this.$emit('close-solution');
        },
        closeSolution() {
            this.$emit('close-solution');
        },
        handleChange(file) {
            this.showPopover = false;
            this.fileList.push(file);
        },
    }
};
</script>

<style scoped>
.upload-demo {
    text-align: left;
}
.upload-file {
    color: #0096EB;
    font-size: 16px;
    font-weight: 500;
}
.filelist {
    color: black;
    font-size: 14px;
    font-weight: 500;
    cursor: auto;
    opacity: 0.7;
}
</style>
