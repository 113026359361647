import store from '@/store';

function getUserTag(id) {
    let userTag = store.state.lists?.roomInfo?.user_tags.find(item => item.user === id);
    return userTag ? userTag.tag.name : '-';
}

function getUserAvatar(userpic) {
    return userpic ? `url(${userpic})` : `url(${require('@/assets/img/avatar-placeholder.svg')})`;
}

export {getUserTag, getUserAvatar};
