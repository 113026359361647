<template>
    <div :id='"messageId-" + message.id'>
        <div class='user' v-if='isShowAuthor'>
            <router-link :to="`/user-profile/${message.author.id}`">
                <div
                    class='user__avatar'
                    :style="{
                        backgroundImage: $getUserAvatar(message.author.userpic)
                    }"
                ></div>
            </router-link>
            <div class='user__ct'>
                <div class='user__name'>{{ message.author.full_name }}</div>
                <div class='user__post'>{{ $getUserTag(message.author.id) }}</div>
            </div>
        </div>
        <div class='message-cloud' @contextmenu.prevent='showMenu'>
            <div class='message-cloud__wrap'>
                <div class='message-cloud__content'>
                    <div v-html='formattedMessage'></div>
                    <div class='message-cloud__quote' v-if='message.parent_message'>
                        {{ message.parent_message.data }}
                    </div>
                </div>
                <div class='message-cloud__meta'>
                    <div class='message-cloud__time'>{{ getTime(message.created_at) }}</div>
                    <div
                        v-if='user.id === message.author.id'
                        class='message-cloud__message-status'
                        v-bind:class='{
                            "svg-icon-read": message.read,
                            "svg-icon-delivered": !message.read,
                            "svg-icon-timelapse": !message.id
                        }'
                    >
                    </div>
                </div>
            </div>
        </div>
        <message-menu
            v-if='isShowMenu'
            :is-request='false'
            @close-menu='isShowMenu = false'
            @show-history='showHistory(message.id)'
            @show-forward='showForward(message.id)'
            @reply='reply(message)'
        ></message-menu>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';
import MessageMenu from './MessageMenu';
import {mapState} from 'vuex';
import linkifyHtml from 'linkify-html';


export default {
    name: 'GeoLogTextMessage',
    data() {
        return {
            isShowMenu: false
        };
    },
    mixins: [Dates],
    components: {
        MessageMenu
    },
    computed: {
        ...mapState({
            user: state => state.auth?.user
        }),
        formattedMessage: {
            get() {
                const options = { defaultProtocol: 'http', target: '_blank' };
                let message = linkifyHtml(this.message.data, options);
                if (this.message.recipients.length) {
                    this.message.recipients.forEach(recipient => {
                        let pattern = new RegExp(`@${recipient.full_name}`);
                        message = message.replace(
                            pattern,
                            `<span style='color:#0071B2'>@${recipient.full_name}</span>`
                        );
                    });
                    return message;
                }
                return message;
            }
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        isShowAuthor: {
            type: Boolean,
            required: true
        }
    },
    async created() {
    },
    methods: {
        reply(message) {
            this.$emit('reply', message);
        },
        showHistory(id) {
            this.$emit('show-history', id);
        },
        showForward(id) {
            this.$emit('show-forward', id);
        },
        showMenu(e) {
            let chatWrap = e.target.closest('.chat-wrap').getBoundingClientRect();
            let koeffX = e.clientX - chatWrap.left;
            let koeffY = e.clientY - chatWrap.top;

            this.isShowMenu = true;
            this.$nextTick(() => {
                this.$root.$emit('set-menu', koeffY, koeffX, chatWrap);
            });
        }
    }
};
</script>

<style>
.message-cloud {
    cursor: pointer;
}
</style>
