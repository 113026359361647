<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Проект</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Название проекта</span>
                        <em>*</em>
                    </label>
                    <input
                        v-model='model.name'
                        class='input-block__input'
                        type='text'
                        name='name'
                        placeholder=''
                        required='required'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Кластер</span><em>*</em> </label>
                    <el-select v-model='model.cluster' :disabled='isProjectModalEdit'>
                        <el-option v-for='item in clusters' :key='item.value' :label='item.name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-user-block main'>
                    <label class='input-block__label'><span>Руководитель проекта</span><em>*</em> </label>
                    <div
                        class='user__avatar select-user'
                        :style="{
                        backgroundImage: `url(${userpic})`
                    }"
                    ></div>
                    <el-select v-model='model.owner' filterable remote :remote-method="getUsers" placeholder='Иванов И.И.'>
                        <el-option v-for='item in users' :key='item.id' :label='item.full_name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-user-block'>
                    <label class='input-block__label'><span>Руководитель проекта +1</span><em>*</em> </label>
                    <el-select v-model='model.additional_owners' filterable multiple  remote :remote-method="getUsers" placeholder='Иванов И.И.'>
                        <el-option v-for='item in users' :key='item.id' :label='item.full_name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-status-block' v-if='isProjectModalEdit'>
                    <b class=''>Активный</b>
                    <el-switch
                        v-model='model.status'
                        class=''
                        active-value='ACTIVE'
                        inactive-value='PASSIVE'
                    ></el-switch>
                </div>
            </div>
            <div class='popup__buttons'>
                <button
                    class='button small'
                    type='submit'
                    @click='update'
                    :disabled='isDisabled'
                    v-if='isProjectModalEdit'
                >
                    <span>Сохранить</span>
                </button>
                <button
                    class='button small'
                    type='submit'
                    @click='create'
                    :disabled='isDisabled'
                    v-if='!isProjectModalEdit'
                >
                    <span>Добавить</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mapGetters, mapState} from 'vuex';
import {getterTypes as modalGetterTypes, mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import eventHub from '@/store/events/eventHub.js';

export default {
    name: 'GeoProjectModal',
    data() {
        return {
            model: {
                name: '',
                cluster: '',
                status: '',
                owner: '',
                additional_owners: []
            }
        };
    },
    computed: {
        ...mapState({
            selectedProject: state => state.lists?.selectedProject,
            clusters: state => state.lists?.clusters || [],
            users: state => state.guides?.users?.results || []
        }),
        ...mapGetters({
            isProjectModalEdit: modalGetterTypes.projectModalEdit
        }),
        isDisabled: function() {
            return !(this.model.name && this.model.cluster && this.model.owner && this.model.additional_owners.length);
        },
        userpic: function() {
            let user = this.users.find(item => item.id === this.model.owner)
            return this.$getUserAvatar(user?.userpic);
        }
    },
    async created() {
        if (this.isProjectModalEdit) {
            let {id, name, cluster, status, owner_id: owner, additional_owners} = {...this.selectedProject};
            this.model = Object.assign({}, {id, name, cluster, status, owner, additional_owners});
        }
        await this.$store.dispatch(guidesActionTypes.getUsers);
        eventHub.$once('project-create-error', this.alert);
        eventHub.$once('project-create-success', this.message);
    },
    methods: {
        async create() {
            await this.$store.dispatch(listsActionTypes.createProject, this.model);
        },
        async update() {
            await this.$store.dispatch(listsActionTypes.updateProject, this.model);
        },
        alert(params) {
            this.$alert(params.message, params.title, {
                confirmButtonText: 'Закрыть'
            });
        },
        message(params) {
            this.$message({
                dangerouslyUseHTMLString: true,
                message: params.message,
                type: params.type,
                showClose: true,
                duration: 5000
            });
            this.closeModal();
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.projectModalClose);
        },
        async getUsers(query = "") {
            await this.$store.dispatch(guidesActionTypes.getUsers, {query: query});
        }
    }
};
</script>

<style scoped>
.el-select {
    width: 100%;
}

.el-input .el-input__inner {
    height: 48px !important;
    border-radius: 8px;
}

.el-select-dropdown__item {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 10px 12px;
    border-radius: 0;
}

.el-select-dropdown__item.selected {
    background-color: #f2f4f5;
    display: flex;
    align-items: center;
    min-height: 48px;
    border-radius: 0;
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606266 !important;
    font-weight: normal !important;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
}
.select-status-block {
    display: flex;
    justify-content: space-between;
}
.select-user {
    top: 22px;
    left: 12px;
    position: absolute;
}
::v-deep .select-user-block.main input {
    text-indent: 40px;
}
</style>
