<template>
    <div>
        Messages
    </div>
</template>

<script>


export default {
    name: 'GeoLogMessages',
    data() {
        return {

        };
    },
    components: {

    },
    computed: {

    },
    async created() {

    },
    methods: {

    }
};
</script>

<style scoped></style>
