<template>
        <main class='main-block without-padding'>
            <div class='log-tasks-row row'>
                <list
                    @get-more-rooms='getMoreRooms'
                    @select-room='selectRoom'
                    @change-room-types='changeRoomTypes'
                    @toggle-filters='toggleFilters'
                />
                <filters v-if='isShowFilters' @get-rooms='getRooms' @close-filters='isShowFilters = false' />
                <messages
                    :recipients='recipients'
                    :issueId='this.$route.params.issueId'
                    :typing='typing'
                    :is-loading='isLoading'
                    @get-rooms='getRooms'
                    @show-info='isShowInfo = true'
                    @show-search='isShowSearch = true'
                    @show-history='showHistory'
                    @show-forward='showForward'
                    @show-recipients='showRecipients'
                    @search-recipients='searchRecipients'
                    @get-messages='getMessages'
                    @create-request='toggleRequest'
                    @close-room='isCloseRoom = true'
                    @create-solution='toggleSolution'
                    @create-table='toggleTable'
                    @create-issue-answer='createIssueAnswer'
                    @on-message-sent='onMessageSent'
                    @message-changed='setMessageData'
                    v-if='selectedRoom && selectedRoom.id'
                />
                <info v-if='isShowInfo' @close-info='isShowInfo = false' @show-participants='showParticipants' :room-id='selectedRoom.id' />
                <search-message v-if='isShowSearch' @close-show-search='isShowSearch = false' :isLoading='isLoading' />
                <participants
                    v-if='isShowParticipants'
                    :selectedRoomId='selectedRoom.id'
                    @close-participants='closeParticipants'
                    @back-participants='backParticipants'
                />
                <recipients
                    :recipientFio='recipientFio'
                    :selectedRoomId='selectedRoom.id'
                    v-if='isShowRecipients'
                    @close-recipients='isShowRecipients = false'
                    @select-recipient='selectRecipient'
                />
                <history
                    :selectedMessageId='selectedMessageId'
                    v-if='isShowHistory'
                    @close-history='isShowHistory = false'
                />
                <forward
                    :selectedRoomId='selectedRoom.id'
                    :selectedMessageId='selectedMessageId'
                    v-if='isShowForward'
                    @close-forward='isShowForward = false'
                    @get-rooms='getRooms'
                    @on-message-sent='onMessageSent'
                />
                <request
                    v-if='isShowRequest'
                    @close-request='isShowRequest = false'
                    @get-rooms='getRooms'
                    @on-message-sent='onMessageSent'
                />
                <solution
                    v-if='isShowSolution'
                    @close-solution='isShowSolution = false'
                    @get-rooms='getRooms'
                    @on-message-sent='onMessageSent'
                />
                <chat-table
                    v-if='isShowTable'
                    @close-table='toggleTable'
                    @on-message-sent='onMessageSent'
                    :message='messageData'
                />
                <close-room v-if='isCloseRoom' @close-room='closeRoom' />
                <issue-answer
                    :selectedIssueId='selectedIssueId'
                    v-if='isIssueAnswer'
                    @close-issue-answer='isIssueAnswer = false'
                    @on-message-sent='onMessageSent'
                    @get-rooms='getRooms'
                />
            </div>
            <image-popup v-if='isImagePopupOpened'></image-popup>
            <video-popup v-if='isVideoPopupOpened'></video-popup>
        </main>

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as listsActionTypes, mutationTypes} from '@/store/modules/lists';
import Messages from '@/views/log/rooms/components/Messages';
import List from '@/views/log/rooms/components/List';
import Info from '@/views/log/rooms/components/forms/Info';
import SearchMessage from '@/views/log/rooms/components/forms/SearchMessage';
import History from '@/views/log/rooms/components/forms/History';
import Forward from '@/views/log/rooms/components/forms/Forward';
import Request from '@/views/log/rooms/components/forms/Request';
import Solution from '@/views/log/rooms/components/forms/Solution';
import ChatTable from '@/views/log/rooms/components/forms/Table';
import IssueAnswer from '@/views/log/rooms/components/forms/IssueAnswer';
import Filters from '@/views/log/rooms/components/forms/Filters';
import Recipients from '@/views/log/rooms/components/forms/Recipients';
import CloseRoom from '@/views/log/rooms/components/forms/CloseRoom';
import Participants from '@/views/log/rooms/components/forms/Participants';
import WebSocketAsPromised from 'websocket-as-promised';
import ImagePopup from '@/components/ImagePopup';
import VideoPopup from '@/components/VideoPopup';
import {getterTypes as modalGetterTypes} from '@/store/modules/modals';

export default {
    name: 'GeoLogRooms',
    data() {
        return {
            params: {
                rooms: {
                    query: '',
                    limit: 20,
                    offset: 0
                },
                messages: {
                    query: '',
                    limit: 20,
                    offset: 0
                }
            },
            recipients: [],
            recipientFio: '',
            isShowInfo: false,
            isShowSearch: false,
            isShowRequest: false,
            isShowSolution: false,
            isShowTable: false,
            isIssueAnswer: false,
            isMyRooms: false,
            isShowFilters: false,
            isShowHistory: false,
            isShowForward: false,
            isShowRecipients: false,
            isCloseRoom: false,
            isShowParticipants: false,
            isLoading: true,
            selectedIssueId: null,
            selectedMessageId: null,
            wsp: {},
            typing: '',
            messageData: ''
        };
    },
    components: {
        Messages,
        List,
        Info,
        SearchMessage,
        History,
        Forward,
        Request,
        Solution,
        ChatTable,
        IssueAnswer,
        Filters,
        Recipients,
        CloseRoom,
        Participants,
        ImagePopup,
        VideoPopup
    },
    computed: {
        ...mapState({
            messages: state => state.lists?.messages || [],
            rooms: state => state.lists?.rooms || [],
            roomsAmount: state => state.lists?.roomsAmount,
            selectedRoom: state => state.lists?.selectedRoom,
            roomInfo: state => state.lists?.roomInfo,
            currentUser: state => state.auth?.user
        }),
        ...mapGetters({
            isImagePopupOpened: modalGetterTypes.imagePopupOpened,
            isVideoPopupOpened: modalGetterTypes.videoPopupOpened,
        }),
        roomsAction: function() {
            return this.isMyRooms ? 'getPersonalRooms' : 'getWorkRooms';
        },
        updateRoomsAction: function() {
            return this.isMyRooms ? 'updatePersonalRooms' : 'updateWorkRooms';
        },
        roomId: function() {
            return Number(this.$route.params.id);
        }
    },
    watch: {
        '$route.params.id': {
            handler: async function(id) {
                await this.selectRoom(Number(id));
            }
        },
    },
    mounted() {
        this.$root.$on('rooms-changed', ( ) => {
            this.updateRooms();
        });
        this.$root.$on('messages-changed', ( ) => {
            this.getMessages('', false);
        })
    },
    beforeDestroy() {
        this.$root.$off("rooms-changed");
        this.$root.$off("messages-changed");
    },
    async created() {
        await this.getRooms();

    },
    methods: {
        async onMessageSent() {
            await this.getMessages('', false);
            this.$emit('message-is-received');
        },

        async updateRooms() {
            await this.$store.dispatch(listsActionTypes[this.updateRoomsAction], this.params.rooms);
            await this.$store.dispatch(listsActionTypes.getRoomInfo, this.selectedRoom.id);
        },

        async getRooms(query = '') {
            this.params.rooms = Object.assign(this.params.rooms, {
                query: query,
                offset: 0
            });
            await this.$store.dispatch(listsActionTypes[this.roomsAction], this.params.rooms);
            if (this.rooms.length) {
                if (this.roomId) {
                    await this.getMissingRoom();
                    await this.selectRoom(this.roomId);
                    let room = this.$el.querySelector('div.chat-list > div.chat-task.current.success');
                    room.scrollIntoView();
                } else {
                    await this.selectRoom(this.selectedRoom?.id);
                }
            }
        },
        async getMissingRoom() {
                let existRoom = this.rooms.find((room) => {
                    return room.id === this.roomId;
                });
                if (!existRoom) {
                    this.params.rooms = Object.assign(this.params.rooms, {
                        offset: 0,
                        limit: this.roomsAmount
                    });
                    await this.$store.dispatch(listsActionTypes[this.roomsAction], this.params.rooms);
                    // await this.getMissingRoom();
                }

        },
        async getMoreRooms($state) {
            if (this.rooms.length === this.roomsAmount){
                $state.complete();
                return;
            }
            if (!this.isShowFilters) {
                let oldRoomsLength = this.rooms.length;
                this.params.rooms = Object.assign(this.params.rooms, {
                    offset: this.params.rooms.offset + 20
                });
                await this.$store.dispatch(listsActionTypes[this.roomsAction], this.params.rooms);
                if (oldRoomsLength === this.rooms.length) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
            } else {
                $state.complete();
            }

        },
        async selectRoom(id) {
            this.isLoading = true;
            await this.leaveRoom();
            this.$store.commit(mutationTypes.selectRoom, id);

            await this.joinRoom();
            await this.getMessages();
            await this.updateRooms();
            this.$root.$emit('room-is-selected');
            this.isLoading = false;
        },
        async getMessages(query = '', reloadMessages = true) {
            this.params.messages = Object.assign(this.params.messages, {
                query: query,
                offset: 0,
                reloadMessages: reloadMessages
            });
            await this.$store.dispatch(listsActionTypes.getRoomMessages, {
                id: this.selectedRoom?.id,
                ...this.params.messages
            });
            this.$emit('messages-are-received');
        },
        async joinRoom() {
            let wsProtocol = location.protocol === 'https:' ? 'wss' : 'ws';

            this.wsp = new WebSocketAsPromised(`${wsProtocol}://${window.location.host}/ws/gateway/`, {
                packMessage: data => JSON.stringify(data),
                unpackMessage: data => JSON.parse(data.toString()),
                attachRequestId: (data, requestId) => Object.assign({rid: requestId}, data),
                extractRequestId: data => data && data.rid
            });

            await this.wsp.open();
            await this.wsp.sendRequest({
                path: `rooms?id=${this.selectedRoom.id}`,
                method: 'join'
            });
            this.wsp.onMessage.addListener((response) => {
                response = JSON.parse(response);
                if (response.cls === 'rooms/typing' && response.user?.username !== this.user?.username) {
                    let writer = this.roomInfo.participants.find((user) => {
                        return user.id === response.user?.id;
                    });
                    this.typing = writer ? writer.full_name : response.user.username;
                    this.timer = setTimeout(() => {
                        this.typing = '';
                    }, 1000);
                } else if (response.cls === 'messages') {
                    this.getMessages('', false);
                }
            });
        },
        async leaveRoom() {
            if (this.wsp.isOpened) {
                await this.wsp.sendRequest({
                    path: `rooms?id=${this.selectedRoom.id}`,
                    method: 'leave'
                });
                this.wsp.close();
            }
        },
        setMessageData(message) {
            this.messageData = message;
        },
        async changeRoomTypes() {
            this.isMyRooms = !this.isMyRooms;
            await this.getRooms();
        },
        createIssueAnswer(id) {
            this.selectedIssueId = id;
            this.isIssueAnswer = !this.isShowSolution;
        },
        toggleFilters() {
            this.isShowFilters = !this.isShowFilters;
        },
        showHistory(id) {
            this.selectedMessageId = id;
            this.isShowHistory = true;
        },
        showForward(id) {
            this.selectedMessageId = id;
            this.isShowForward = true;
        },
        showRecipients() {
            this.isShowRecipients = true;
        },
        searchRecipients(fio) {
            this.recipientFio = fio;
        },
        selectRecipient(recipient) {
            this.recipients.push(recipient.id);
            this.$root.$emit('recepient-is-selected', recipient);
            this.isShowRecipients = false;
            this.recipientFio = null;
        },
        toggleRequest() {
            this.isShowRequest = !this.isShowRequest;
            this.isShowSolution = false;
        },
        toggleSolution() {
            this.isShowSolution = !this.isShowSolution;
            this.isShowRequest = false;
        },
        toggleTable() {
            this.isShowTable = !this.isShowTable;
        },
        showParticipants() {
            this.isShowInfo = false;
            this.isShowParticipants = true;
        },
        closeParticipants() {
            this.isShowInfo = false;
            this.isShowParticipants = false;
        },
        backParticipants() {
            this.isShowInfo = true;
            this.isShowParticipants = false;
        },
        async closeRoom() {
            this.isCloseRoom = false;
            await this.getRooms();
        }
    }
};
</script>

<style scoped></style>
