<template>
    <div class='chat-message center'>
        <div v-if='closeComment' class='new-chat-message'>Чат закрыт с комментарием “{{ closeComment }}“</div>
        <div v-else class='new-chat-message'>Чат закрыт</div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogCloseMessage',
    data() {
        return {
            isShowMenu: false
        };
    },
    mixins: [Dates],
    components: {},
    computed: {},
    props: {
        closeComment: {
            type: String,
            required: false
        }
    },
    async created() {
    },
    methods: {}
};
</script>

<style></style>
