<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>
                Ответить на запрос
            </div>
            <div class='log-information__close-button base-icon-close' @click='closeIssueAnswer'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='input-block input-block'>
                <label class='input-block__label'>
                    <span>Текст решения</span>
                    <em>*</em>
                </label>
                <el-input v-model='model.data' type='textarea' :rows='4' required='required'></el-input>
            </div>
            <div class='input-block input-block'>
                <el-upload
                    class="upload-demo"
                    action='/api/v1/messages/'
                    :auto-upload="false"
                    ref='upload'
                    :file-list='fileList'
                    :show-file-list='false'
                    :on-change='handleChange'
                >
                    <div>
                        <span class='chat-header__search-button svg-icon-pin'></span>
                        <span class='upload-file' slot="trigger">Добавить файл</span>

                    </div>
                </el-upload>
            </div>
            <div v-if='fileList'>
                <ul class='filelist'>
                    <li v-for='(file, index) in fileList' :key='index'>{{ file.name }}</li>
                </ul>
            </div>
            <div class='popup__buttons'>
                <button class='button small' type='submit' @click='createIssueAnswer' :disabled='isDisabled'>
                    <span>Ответить на запрос</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mapState} from 'vuex';

export default {
    name: 'GeoLogIssueAnswer',
    data() {
        return {
            model: {
                type_of_message: 'solution',
                room: '',
                parent_message: '',
                data: '',
                responsible: '',
                issue_message: '',
                deadline: '',
                related_message: '',
                recipients: [],
                struct: ''
            },
            fileList: [],
            recipient: '',
            date: '',
            time: '',
        };
    },
    async created() {
    },
    props: {
        selectedIssueId: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            selectedRoom: state => state.lists?.selectedRoom,
        }),
        isDisabled: function() {
            return !(this.model.data);
        }
    },
    methods: {
        async createIssueAnswer() {
            this.model = Object.assign(this.model, {
                room: this.selectedRoom.id,
                issue_message: this.selectedIssueId,
                files: this.fileList,
            });
            console.log(this.model)
            await this.$store.dispatch(listsActionTypes.createMessage, this.model);
            this.$emit('on-message-sent', {model: this.model});
            this.$emit('close-issue-answer');
        },
        closeIssueAnswer() {
            this.$emit('close-issue-answer');
        },
        handleChange(file) {
            this.showPopover = false;
            this.fileList.push(file);
        },
    }
};
</script>

<style>
.upload-demo {
    text-align: left;
}
.upload-file {
    color: #0096EB;
    font-size: 16px;
    font-weight: 500;
}
.filelist {
    color: black;
    font-size: 14px;
    font-weight: 500;
    cursor: auto;
    opacity: 0.7;
}
</style>
