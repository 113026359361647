var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"messageId-" + _vm.message.id}},[(_vm.isShowAuthor)?_c('div',{staticClass:"user"},[_c('router-link',{attrs:{"to":("/user-profile/" + (_vm.message.author.id))}},[_c('div',{staticClass:"user__avatar",style:({
                    backgroundImage: _vm.$getUserAvatar(_vm.message.author.userpic)
                })})]),_c('div',{staticClass:"user__ct"},[_c('div',{staticClass:"user__name"},[_vm._v(_vm._s(_vm.message.author.full_name))]),_c('div',{staticClass:"user__post"},[_vm._v(_vm._s(_vm.$getUserTag(_vm.message.author.id)))])])],1):_vm._e(),_vm._l((_vm.message.messagefile_set),function(file){return _c('div',{key:file.id},[_c('div',{staticClass:"message-cloud__content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.formattedMessage)}}),(_vm.message.parent_message)?_c('div',{staticClass:"message-cloud__quote"},[_vm._v(" "+_vm._s(_vm.message.parent_message.data)+" ")]):_vm._e()]),(file.file_content_type.includes("image"))?_c('div',{staticClass:"image-cloud landscape",style:({
                    backgroundImage: ("url(" + (file.preview) + ")")
                }),on:{"click":function($event){return _vm.openImage(file)}}},[_c('div',{staticClass:"image-cloud__meta"},[_c('div',{staticClass:"image-cloud__time"},[_vm._v(_vm._s(_vm.getTime(_vm.message.created_at))+" ")]),(_vm.user.id === _vm.message.author.id)?_c('div',{staticClass:"message-cloud__message-status",class:{
                        "svg-icon-read": _vm.message.read,
                        "svg-icon-delivered": !_vm.message.read,
                        "svg-icon-timelapse": !_vm.message.id
                    }}):_vm._e()])]):(file.file_content_type.includes("video"))?_c('div',{staticClass:"image-cloud landscape",style:({
                    backgroundImage: ("url(" + (file.preview) + ")")
                }),on:{"click":function($event){return _vm.openVideo(file)}}},[_c('div',{staticClass:"image-cloud__meta"},[_c('div',{staticClass:"image-cloud__time"},[_vm._v(_vm._s(_vm.getTime(_vm.message.created_at))+" ")]),(_vm.user.id === _vm.message.author.id)?_c('div',{staticClass:"message-cloud__message-status",class:{
                        "svg-icon-read": _vm.message.read,
                        "svg-icon-delivered": !_vm.message.read,
                        "svg-icon-timelapse": !_vm.message.id
                    }}):_vm._e()])]):(file.file_content_type.includes("audio"))?_c('div',{staticClass:"message-cloud audio-cloud",on:{"click":function($event){return _vm.openAudio(file)}}},[_c('div',{staticClass:"message-cloud__wrap"},[_vm._m(0,true),_vm._m(1,true),_c('div',{staticClass:"message-cloud__meta"},[_c('div',{staticClass:"message-cloud__time"},[_vm._v(_vm._s(_vm.getTime(_vm.message.created_at))+" ")]),_c('div',{staticClass:"message-cloud__message-status svg-icon-read"})])])]):_c('div',{staticClass:"message-cloud",on:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu.apply(null, arguments)}}},[_c('div',{staticClass:"message-cloud__wrap"},[_c('div',{staticClass:"message-cloud__content"},[_c('div',{staticClass:"message-cloud__attachments",attrs:{"v-if":_vm.message.messagefile_set.length}},_vm._l((_vm.message.messagefile_set),function(file){return _c('a',{key:file.id,staticClass:"file-block",attrs:{"href":file.file_data,"download":file.file_name}},[_vm._m(2,true),_c('div',{staticClass:"file-block__meta"},[_c('div',{staticClass:"file-block__name"},[_vm._v(_vm._s(file.file_name))]),_c('div',{staticClass:"file-block__size"},[_vm._v(_vm._s(_vm.fileSize(file.file_size)))])])])}),0)]),_c('div',{staticClass:"message-cloud__meta"},[_c('div',{staticClass:"message-cloud__time"},[_vm._v(_vm._s(_vm.getTime(_vm.message.created_at)))]),(_vm.user.id === _vm.message.author.id)?_c('div',{staticClass:"message-cloud__message-status",class:{
                        "svg-icon-read": _vm.message.read,
                        "svg-icon-delivered": !_vm.message.read,
                        "svg-icon-timelapse": !_vm.message.id
                    }}):_vm._e()])])])])}),(_vm.isShowMenu)?_c('message-menu',{attrs:{"is-request":false},on:{"close-menu":function($event){_vm.isShowMenu = false},"show-history":function($event){return _vm.showHistory(_vm.message.id)},"show-forward":function($event){return _vm.showForward(_vm.message.id)},"reply":function($event){return _vm.reply(_vm.message)}}}):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-cloud__play-button"},[_c('i',{staticClass:"base-icon-video"}),_c('i',{staticClass:"base-icon-pause"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-cloud__content"},[_c('div',{staticClass:"voice-wave"},[_c('div',{staticStyle:{"width":"0%"}})]),_c('div',{staticClass:"message-cloud__duration"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-block__icon"},[_c('div',[_c('div',{staticClass:"base-icon-file"})])])}]

export { render, staticRenderFns }