<template>
    <div class='col-2 project-list-l3-col'>
        <div class='projects-list-l3'>
            <div class='edit-heading'>
                <div class='edit-heading__heading h4'>
                    <span >{{ areaName }}</span>
                    <a class='edit-heading__edit-link svg-icon-edit' href='#' v-if='isAreaSelected && canCreateEditLicenseArea' @click.prevent='editLicenseArea'></a>
                </div>
            </div>
            <ul class='projects-list-l3__items' v-if='isAreaSelected'>
                <li
                    class='projects-list-l3__item'
                    v-for='(well, index) in wells'
                    :key='index'
                    :class="{
                        'current': selectedWell && well.id === selectedWell.id,
                        'success': well.status === 'ACTIVE',
                        'alert': well.status === 'PASSIVE',
                    }"
                    v-on:click='selectWell(well.id)'
                >
                    <span class='projects-list-l3__content'>
                      <span>{{ well.name }}</span><i class='svg-icon-arrow-right'></i>
                    </span>
                    <span class="projects-list-l3__desc" v-if='well.field'>{{ well.field }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {can} from '@/helpers/permissions';

export default {
    name: 'WellsList',
    computed: {
        ...mapState({
            wells: state => state.lists?.wells || [],
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            selectedWell: state => state.lists?.selectedWell,
        }),
        areaName: function () {
            return this.selectedLicenceArea?.name || '';
        },
        isAreaSelected: function () {
            return !!this.selectedLicenceArea;
        },
        canCreateEditLicenseArea: function() {
            return can('createEditLicenseArea');
        }
    },
    async created() {
    },
    methods: {
        selectWell(id) {
            this.$emit('select-well', id);
        },
        editLicenseArea() {
            this.$store.commit(modalMutationTypes.licenseAreaModalOpen, 'edit');
        }
    }
};
</script>

<style scoped>

</style>
