<template>
    <div class='log-information col-3 create-table'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>Создать таблицу
            </div>
            <div class='log-information__close-button base-icon-close' @click='closeTable'>
            </div>
        </div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Заголовок таблицы</span>
                        <em>*</em>
                    </label>
                    <input class='input-block__input title-input' type='text' required='required'
                           v-model='data.title' />
                </div>
                <div class='table-inputs'>
                    <div class='table-inputs__labels'>
                        <div class='table-inputs__col-1'>Параметры
                        </div>
                        <div class='table-inputs__col-2'>Значения
                        </div>
                    </div>
                    <div class='table-inputs__table'>
                        <div class='table-inputs__row' v-for='(row, rowIndex) in data.rows' :key='row.id'>
                            <input class='input-block__input' type='text' required='required'
                                   v-for='(cell, cellIndex) in row.cells'
                                   :key='cellIndex'
                                   @input='updateTable(rowIndex)'
                                   v-model='cell.value'
                                   v-bind:class="[cellIndex % 2 ? 'value-block' : 'key-block']"
                            />
                        </div>
                    </div>
                </div>
                <div class='log-information__buttons'>
                    <button class='button small' type='submit' @click='createTable' :disabled='isDisabled'>
                        <span>Создать</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mapState} from 'vuex';

export default {
    name: 'GeoLogRoomTable',
    data() {
        return {
            model: {
                type_of_message: 'struct',
                file_name: '',
                file: [],
                room: '',
                parent_message: '',
                data: '',
                responsible: '',
                issue_message: '',
                deadline: '',
                related_message: '',
                recipients: [],
                struct: {}
            },
            title: '',
            data: {
                column_titles: [
                    'Параметры',
                    'Значения'
                ],
                title: '',
                rows: [
                    {
                        cells: [
                            {
                                type: 'TEXT',
                                value: ''
                            },
                            {
                                type: 'TEXT',
                                value: ''
                            }
                        ]
                    },
                    {
                        cells: [
                            {
                                type: 'TEXT',
                                value: ''
                            },
                            {
                                type: 'TEXT',
                                value: ''
                            }
                        ]
                    },
                    {
                        cells: [
                            {
                                type: 'TEXT',
                                value: ''
                            },
                            {
                                type: 'TEXT',
                                value: ''
                            }
                        ]
                    },
                    {
                        cells: [
                            {
                                type: 'TEXT',
                                value: ''
                            },
                            {
                                type: 'TEXT',
                                value: ''
                            }
                        ]
                    },
                    {
                        cells: [
                            {
                                type: 'TEXT',
                                value: ''
                            },
                            {
                                type: 'TEXT',
                                value: ''
                            }
                        ]
                    }
                ]
            }
        };
    },
    props: {
        message: {
            type: String,
            required: false
        }
    },
    async mounted() {
    },
    computed: {
        ...mapState({
            selectedRoom: state => state.lists?.selectedRoom
        }),
        isDisabled: function() {
            return !(this.data.title);
        }
    },
    methods: {
        async createTable() {
            this.model = Object.assign(this.model, {
                room: this.selectedRoom.id,
                data: this.message,
                struct: Object.assign(this.model.struct, {data: this.data})
            });
            await this.$store.dispatch(listsActionTypes.createMessage, this.model);
            this.$emit('on-message-sent', {model: this.model});
            this.$emit('close-table');
        },
        closeTable() {
            this.$emit('close-table');
        },
        updateTable(index) {
            if (this.data.rows.length - 1 === index) {
                this.data.rows.push({
                    cells: [
                        {
                            type: 'TEXT',
                            value: ''
                        },
                        {
                            type: 'TEXT',
                            value: ''
                        }
                    ]
                });
            }
        }
    }
};
</script>

<style scoped>
.input-block__input {
    border: none;
}

.title-input {
    border: 1px solid #CCD9E0;
}

.key-block {
}

.value-block {
    border-left: 1px solid;
    border-radius: 0 8px 8px 0;
    border-color: #CCD9E0;
}

.value-block:focus-visible {
    border-color: #CCD9E0;
}


</style>
