<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Новая задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='form-label'>ПАРАМЕТРЫ ЗАДАЧИ</div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Кластер</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='cluster'
                        placeholder=''
                        required='required'
                        :value='selectedCluster.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Проект</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='project'
                        placeholder=''
                        required='required'
                        :value='selectedProject.name'
                        :disabled='true'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Лицензионный участок</span><em>*</em> </label>
                    <el-select v-model='model.license_areas' multiple @change='getWellsByAreas'>
                        <el-option v-for='item in licenseAreas' :key='item.value' :label='item.name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Скважина</span><em>*</em> </label>
                    <el-select v-model='model.wells' multiple>
                        <el-option v-for='item in wellsByAreas' :key='item.value' :label='item.name' :value='item.id'>
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Наименование</span><em>*</em> </label>
                    <input
                        v-model='model.title'
                        class='input-block__input'
                        type='text'
                        name='name'
                        placeholder=''
                        required='required'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Тип работ</span><em>*</em> </label>
                    <el-select v-model='model.type_of_research' filterable>
                        <el-option
                            v-for='item in typesOfResearch'
                            :key='item.id'
                            :label='item.full_name'
                            :value='item.id'
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class='form-label'>KPI</div>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Бюджет (тысяч рублей без НДС)</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='number'
                        name='budget'
                        required='required'
                        v-model='model.budget'
                    />
                </div>
                <div class='input-block period-block'>
                    <label class='input-block__label'><span>Сроки</span><em>*</em> </label>
                    <div class='period-cols'>
                        <div class='period-cols__col'>
                            <el-date-picker
                                v-model='model.start_date'
                                type='daterange'
                                start-placeholder='С'
                                end-placeholder='По'
                                format='dd.MM.yyyy'
                                value-format='yyyy-MM-ddTHH:mm:ss'
                                :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Качество</span>
                        <em>*</em>
                    </label>
                    <el-input v-model='model.quality_parameters' type='textarea' :rows='4' required='required'>
                    </el-input>
                </div>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>HSE</span>
                        <em>*</em>
                    </label>
                    <el-input v-model='model.hse_parameters' type='textarea' :rows='4' required='required'></el-input>
                </div>
                <div class='form-label el-row--flex'>
                    <span>УЧАСТНИКИ</span>
                    <span class='edit-block__edit base-icon-edit_mono is-justify-end' @click='editParticipantsStep'></span>
                </div>
                <div class='edit-block'>
                    <div class='search-results'>
                        <div class='user' v-for='participant in fewParticipants' :key='participant.id'>
                            <router-link :to="`/user-profile/${participant.id}`" class='el-row--flex is-align-middle'>
                                <div
                                    class='user__avatar'
                                    :style='{
                                    backgroundImage: $getUserAvatar(participant.userpic)
                                }'
                                ></div>
                                <div class='user__ct'>
                                    <div class='user__name'>{{ participant.full_name }}</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class='popup__buttons'>
                <button class='button small secondary' type='button' @click='prevStep'>
                    <span>Назад</span>
                </button>
                <button class='button small' type='submit' @click='nextStep' :disabled='isDisabled'>
                    <span>Далее</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';

export default {
    name: 'CommonTaskModalStep5',
    data() {
        return {
            date: ''
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            licenseAreas: state => state.lists?.licenseAreas || [],
            wellsByAreas: state => state.lists?.wellsByAreas || [],
            typesOfResearch: state => state.guides?.typesOfResearch?.results || [],
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject
        }),
        isDisabled: function() {
            return !(
                this.model.title &&
                this.model.license_areas &&
                this.model.wells &&
                this.model.type_of_research &&
                this.model.budget &&
                this.model.start_date &&
                this.model.quality_parameters &&
                this.model.hse_parameters &&
                this.model.participants.length
            );
        },
        fewParticipants() {
            return this.model.participants.slice(0, 5);
        }
    },
    async created() {
    },
    methods: {
        async getWellsByAreas() {
            await this.$store.dispatch(listsActionTypes.getWellsByAreas, this.model.license_areas);
            this.model.wells = [];
        },
        nextStep() {
            [this.model.start_date, this.model.end_date] = this.model.start_date;
            this.model.user_tags = this.model.user_tags.map((tagId, index) => {
                return {
                    tag_id: tagId,
                    user_id: this.model.participants[index].id
                };
            });
            this.$emit('createTask');
        },
        prevStep() {
            this.$emit('setStep', 'step4');
        },
        editParticipantsStep() {
            this.$emit('setStep', 'step3');
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.commonTaskModalClose);
        }
    }
};
</script>
