import statistics from '@/api/statistics';
import {handleAction} from '@/api/ws';
import {getterTypes as authGetterTypes} from './auth';

const state = {
    projectFacts: null,
    userFacts: null
};

export const mutationTypes = {
    getProjectFactsStart: '[statistics] getProjectFactsStart',
    getProjectFactsSuccess: '[statistics] getProjectFactsSuccess',
    getProjectFactsFailure: '[statistics] getProjectFactsFailure',
    getUserFactsStart: '[statistics] getUserFactsStart',
    getUserFactsSuccess: '[statistics] getUserFactsSuccess',
    getUserFactsFailure: '[statistics] getUserFactsFailure'
};

export const actionTypes = {
    getProjectFacts: '[statistics] getProjectFacts',
    getUserFacts: '[statistics] getUserFacts'
};

export const getterTypes = {
    getCurrentUserFacts: '[statistics] getCurrentUserFacts',
    getProjectFacts: '[statistics] getProjectFacts',
    getAllFacts: '[statistics] getAllFacts'
};

const mutations = {
    [mutationTypes.getProjectFactsStart]() {
    },
    [mutationTypes.getProjectFactsSuccess](state, payload) {
        state.projectFacts = payload.data;
    },
    [mutationTypes.getProjectFactsFailure](state) {
        state.projectFacts = null;
    },
    [mutationTypes.getUserFactsStart]() {
    },
    [mutationTypes.getUserFactsSuccess](state, payload) {
        state.userFacts = payload.data;
    },
    [mutationTypes.getUserFactsFailure](state) {
        state.userFacts = null;
    }
};

const getters = {
    [getterTypes.getCurrentUserFacts]: (state, rootState) => {
        let items = {};
        if (state.projectFacts) {
            items = state.projectFacts.results.find((item) => {
                if (item.user) {
                    return item.user.id === rootState[authGetterTypes.currentUserId];
                }
            });
        }
        return items || {};
    },
    [getterTypes.getProjectFacts]: (state) => {
        let items = {};
        if (state.projectFacts) {
            items = state.projectFacts.results.find((item) => {
                return !item.user;
            });
        }
        return items || {};
    },
    [getterTypes.getAllFacts]: (state) => {
        if (state.projectFacts) {
            return state.projectFacts.results;
        }
        return [];
    }
};

const actions = {
    async [actionTypes.getProjectFacts](context, payload) {
        context.commit(mutationTypes.getProjectFactsStart);
        return handleAction(
            statistics.getProjectFacts(payload),
            response => {
                context.commit(mutationTypes.getProjectFactsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getProjectFactsFailure, response);
            }
        );
    },
    async [actionTypes.getUserFacts](context, payload) {
        context.commit(mutationTypes.getUserFactsStart);
        return handleAction(
            statistics.getUserFacts(payload),
            response => {
                context.commit(mutationTypes.getUserFactsSuccess, response);
            },
            response => {
                context.commit(mutationTypes.getUserFactsFailure, response);
            }
        );
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
