<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3 warning'>
                Поиск в чате
            </div>
            <div class='log-information__close-button base-icon-close' @click='closeShowSearch'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div class='search-form'>
                    <div class='search-form__input-block'>
                        <div class='search-form__button base-icon-search'></div>
                        <input class='search-form__input' v-model='query' type='text' @input='search' />
                    </div>
                    <div class='search-form__reset' @click='cancel'>Отмена
                    </div>
                </div>
                <div class='search-results' v-loading="isLoading || isSearching">
                    <div class='form-label'>Найдено {{ messages.length }} сообщений
                    </div>
                    <div class='message-block' v-bind:class="[currentMessageId === message.id ? 'current-message' : '']" v-for='message in messages' :key='message.id'
                         @click.prevent.stop='openMessage(message)'>
                        <div class='user'>
                            <div
                                class='user__avatar'
                                :style='{
                                    backgroundImage: $getUserAvatar(message.author.userpic)
                                }'
                            >
                            </div>
                            <div class='user__ct'>
                                <div class='user__name'>{{ message.author.full_name }}
                                </div>
                                <div class='user__post'>{{ $getUserTag(message.author.id) }}
                                </div>
                                <div class='user__date'>
                                    <p>{{ getDateTime(message.created_at)}}</p>
                                </div>
                                <div>
                                    <p>{{ message.data }}</p>
                                </div>
                            </div>

                        </div>
                        <div class='log-information__divider'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mapState} from 'vuex';
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogRoomSearchMessage',
    data() {
        return {
            query: '',
            isSearching: false,
            currentMessageId: null,
            params: {
                messages: {
                    query: '',
                    limit: 20,
                    offset: 0
                }
            },
            messages: []
        };
    },
    mixins: [Dates],
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
    },
    components: {},
    computed: {
        ...mapState({
            selectedRoom: state => state.lists?.selectedRoom,
            messagesSearch: state => state.lists?.messagesSearch || []
        })
    },
    mounted: function() {
        this.$root.$on('room-is-selected', () => {
            this.currentMessageId = null;
            this.search();
        });
    },
    beforeDestroy() {
        this.$root.$off("room-is-selected");
    },
    methods: {
        closeShowSearch() {
            this.$emit('close-show-search');
        },
        async search() {
            this.messages = [];
            if (!this.query.length) {
                return;
            }
            this.isSearching = true;
            let params = Object.assign(this.params.messages, {query: this.query});
            await this.$store.dispatch(listsActionTypes.getRoomMessagesSearch, {
                id: this.selectedRoom?.id,
                ...params
            });
            this.messages = this.messagesSearch;
            this.isSearching = false;
        },
        cancel() {
            this.query = '';
            this.messages = [];
        },
        openMessage(message) {
            this.currentMessageId = message.id;
            this.$root.$emit('open-message', message);
        }
    }
};
</script>

<style scoped>
.message-block {
    cursor: pointer;
}

.log-information .user {
    padding: 14px;
    margin: 0;
    align-items: flex-start;
}

.log-information__divider {
    margin: 0;
}
.user__date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #B3BCC2;
}
.current-message {
    background: #DEE5E9;
}
</style>
