<template>
    <header class='header'>
        <div class='site-logo'>
            <div class='site-logo__link' href='#'>GEO<span>Лог</span></div>
        </div>
        <div class='header__center-buttons'>
            <router-link :to='{name: "rooms"}' class='button extra-small bordered task' exact-active-class='current'>
                <i class='svg-icon-blank'></i><span>Задачи</span>
            </router-link>
            <router-link :to='{name: "requests"}' class='button extra-small bordered requests' exact-active-class='current'>
                <i class='svg-icon-blank'></i><span>Запросы</span>
            </router-link>
            <a href='#' class='button extra-small bordered msg disabled-block-opacity'>
                <i class='svg-icon-blank'></i><span>Сообщения</span>
            </a>
        </div>
    </header>
</template>

<script>
export default {
    name: 'GeoLogHeader',
    computed: {},
    methods: {}
};
</script>

<style scoped></style>
