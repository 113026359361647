<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__back base-icon-arrow-back' @click='backParticipants'></div>
            <div class='log-information__title log-tasks__title h3'>Контакты</div>
            <div class='log-information__close-button base-icon-close' @click='closeParticipants'></div>
        </div>
        <div class='form-label'>КОНТАКТЫ</div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div class='search-form'>
                    <div class='search-form__input-block'>
                        <div class='search-form__button base-icon-search'></div>
                        <input
                            class='search-form__input'
                            placeholder='Поиск'
                            type='text'
                            v-model='participant'
                        />
                    </div>
                </div>
                <div
                    class='user contact-user'
                    v-for='participant in filteredParticipants'
                    :key='participant.id'
                    @click.prevent='addParticipant(participant.id)'
                >
                    <div
                        class='user__avatar'
                        :style='{
                            backgroundImage: $getUserAvatar(participant.userpic)
                        }'
                    />
                    <div class='user__ct'>
                        <div class='user__name'>{{ participant.full_name }}</div>
                    </div>
                    <div
                        class='draggable-input__delete base-icon-delete-2 close-button'
                        v-if='selectedParticipants.includes(participant.id)'
                        @click.stop.prevent='removeParticipant(participant.id)'
                    ></div>
                </div>
                <div class="log-information__buttons">
                    <button class="button small" type="submit" @click.prevent='submitParticipants'><span>Выбрать </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes, actionTypes as listActionTypes} from '@/store/modules/lists';

export default {
    name: 'GeoLogRoomParticipants',
    data() {
        return {
            participant: '',
            filteredParticipants: '',
            selectedParticipants: []
        };
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getUsers);
        let self = this;
        this.filteredParticipants = this.availableRecipients.filter(function(availableUser) {
            return !self.currentParticipants.find(function(currentUser) {
                return availableUser.id === currentUser.id
            })
        })
    },
    props: {
        selectedRoomId: {
            type: Number,
            required: true
        }
    },
    watch: {
        participant: function() {
            this.filteredParticipants = this.participant
                ? this.filteredParticipants.filter(participant => {
                    return participant.full_name.includes(this.participant);
                })
                : this.availableRecipients;
        }
    },
    computed: {
        ...mapState({
            availableRecipients: state => state.guides?.users?.results || [],
            currentParticipants: state => state.lists?.roomInfo?.participants || []
        }),
    },
    methods: {
        addParticipant(id) {
            if (!this.selectedParticipants.includes(id)) {
                this.selectedParticipants.push(id);
            }
        },
        removeParticipant(id) {
            this.selectedParticipants = this.selectedParticipants.filter(participantId => {
                return participantId !== id;
            });
        },
        async submitParticipants() {
            await this.$store.dispatch(listActionTypes.addParticipants, {
                id: this.selectedRoomId,
                participants: this.selectedParticipants
            });
            await this.$store.dispatch(listsActionTypes.getRoomInfo, this.selectedRoomId);
            this.backParticipants();
        },
        closeParticipants() {
            this.$emit('close-participants');
        },
        backParticipants() {
            this.$emit('back-participants');
        }
    }
};
</script>

<style>
.contact-user {
    cursor: pointer;
}

.close-button {
    color: #eb5757 !important;
}
</style>
