<template>
    <div class='log-information col-3'>
        <DocumentsInfo
            v-show='documentsInfoIsShow'
            v-on:close-info='documentsInfoIsShow = false'
        ></DocumentsInfo>
        <SolutionsInfo
            v-show='solutionsInfoIsShow'
            v-on:close-info='solutionsInfoIsShow = false'
        ></SolutionsInfo>
        <MediaInfo
            v-show='mediaInfoIsShow'
            v-on:close-info='mediaInfoIsShow = false'
        ></MediaInfo>
        <div class='log-information__top log-tasks__top'
             v-show='!documentsInfoIsShow && !solutionsInfoIsShow && !mediaInfoIsShow'>
            <div class='log-information__title log-tasks__title h3 warning'>
                Информация
                <div class='log-information__status'></div>
            </div>
            <div class='log-information__close-button base-icon-close' @click='closeInfo'></div>
        </div>
        <div class='log-information__wrap' v-show='!documentsInfoIsShow && !solutionsInfoIsShow && !mediaInfoIsShow'>
            <div class='log-information__scroll-wrap'>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Название</div>
                    <div class='log-information__meta-value'>{{ roomInfo.title }}</div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Статус</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.status === 'ACTIVE' ? 'Активно' : 'Неактивно' }}
                    </div>
                </div>
                <div v-if='roomInfo.last_solution && canViewCommonInfo'>
                    <div class='log-information__title-block'>
                        <div class='log-information__title-value'>Последнее Решение</div>
                        <a class='log-information__title-link' @click='solutionsInfoIsShow = true'>Все</a>
                    </div>
                    <div class='chat-message'>
                        <div class='user'>
                            <router-link
                                :to='`/user-profile/${roomInfo.last_solution.author.id}`'>
                                <div
                                    class='user__avatar'
                                    :style='{
                                        backgroundImage: $getUserAvatar(roomInfo.last_solution.author.userpic)
                                    }'
                                ></div>
                            </router-link>
                            <div class='user__ct'>
                                <div class='user__name'>
                                    {{ roomInfo.last_solution.author.full_name }}
                                </div>
                                <div class='user__post'>{{ $getUserTag(roomInfo.last_solution.author.id) }}</div>
                            </div>
                        </div>
                        <div class='message-cloud'>
                            <div class='message-cloud__wrap'>
                                <div class='message-cloud__content'>
                                    <div class='message-cloud__solution-block'>
                                        <div class='message-cloud__solution-title'>
                                            Решение №{{ roomInfo.last_solution.id }}
                                        </div>
                                        <div class='message-cloud__solution-date'>
                                            {{ getDate(roomInfo.last_solution.created_at) }}
                                        </div>
                                        <div class='message-cloud__solution-text'>
                                            {{ roomInfo.last_solution.data }}
                                        </div>
                                    </div>
                                </div>
                                <div class='message-cloud__meta'>
                                    <div class='message-cloud__time'>15:15</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if='roomInfo.private_rooms && roomInfo.private_rooms.length && canViewCommonInfo'>
                    <div class='log-information__title-block'>
                        <div class='log-information__title-value'>Приватные Чаты</div>
                        <a class='log-information__title-link' href='#'>Все</a>
                    </div>
                    <div class='chat-message'>
                        <div class='user'>
                            <div class='user__avatar' style='background-image: url(/src/assets/img/avatar1.jpg)'></div>
                            <div class='user__ct'>
                                <div class='user__name'>Антон Гаврилов</div>
                                <div class='user__post'>Руководитель проекта</div>
                            </div>
                        </div>
                        <div class='message-cloud'>
                            <div class='message-cloud__wrap'>
                                <div class='message-cloud__chat-status'>
                                    <i class='svg-icon-private'></i><span>Приватный чат завершен</span>
                                </div>
                                <div class='message-cloud__enter-chat'>Войти</div>
                                <div class='message-cloud__content'>
                                    <div class='message-cloud__quote'>
                                        <div class='message-cloud__request-in-quote noaccess'>
                                            <div class='message-cloud__request-title'><span>Запрос № 1</span></div>
                                            <div class='message-cloud__request-in-quote-sep base-icon-arrow-back'></div>
                                            <div class='message-cloud__request-in-quote-author'>Оксана Иванова</div>
                                        </div>
                                        <a href='#'>@Денис Лачугин</a> необходимо прикрепить файлы по ито...
                                    </div>
                                    <div class='message-cloud__solution-block'>
                                        <div class='message-cloud__solution-title'>Решение № {{ message.number_by_type
                                            }}
                                        </div>
                                        <div class='message-cloud__solution-date'>
                                            {{ getDate(message.content_object.issue.created_at) }}
                                        </div>
                                        <div class='message-cloud__solution-text'>
                                            Прикладываю документы по исследованию
                                        </div>
                                        <div class='message-cloud__attachments'>
                                            <div class='file-block'>
                                                <div class='file-block__icon'>
                                                    <div class='base-icon-file'></div>
                                                </div>
                                                <div class='file-block__meta'>
                                                    <div class='file-block__name'>Название документа .pdf</div>
                                                    <div class='file-block__size'>1.0 MB</div>
                                                </div>
                                            </div>
                                            <div class='file-block verifying'>
                                                <div class='file-block__icon'>
                                                    <div class='svg-icon-verify'></div>
                                                </div>
                                                <div class='file-block__meta'>
                                                    <div class='file-block__name'>Название документа .pdf</div>
                                                    <div class='file-block__size'>1.0 MB</div>
                                                </div>
                                            </div>
                                            <div class='file-block verified'>
                                                <div class='file-block__icon'>
                                                    <div class='base-icon-verify-done'></div>
                                                </div>
                                                <div class='file-block__meta'>
                                                    <div class='file-block__name'>Название документа .pdf</div>
                                                    <div class='file-block__size'>1.0 MB</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='author-avatars'>
                                    <div class='author-avatars__item'>
                                        <div class='img' style='background-image: url(../img/avatar1.jpg)'></div>
                                    </div>
                                    <div class='author-avatars__item'>
                                        <div class='img' style='background-image: url(../img/avatar2.jpg)'></div>
                                    </div>
                                    <div class='author-avatars__item'>
                                        <div class='img' style='background-image: url(../img/avatar3.jpg)'></div>
                                    </div>
                                    <div class='author-avatars__item'>
                                        <div class='img' style='background-image: url(../img/avatar4.jpg)'></div>
                                    </div>
                                    <div class='author-avatars__item'>
                                        <div class='img' style='background-image: url(../img/avatar1.jpg)'></div>
                                    </div>
                                    <div class='author-avatars__item'>
                                        <div class='img' style='background-image: url(../img/avatar2.jpg)'></div>
                                    </div>
                                </div>
                                <div class='message-cloud__meta'>
                                    <div class='message-cloud__time'>15:15 – 17:25</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='log-information__title-block'>
                    <div class='log-information__title-value'>О Проекте</div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Скважина</div>
                    <div class='log-information__meta-value'>{{ roomInfo.well ? roomInfo.well.name : '-' }}</div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Лицензионный участок</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.well && roomInfo.well.license_area ? roomInfo.well.license_area.name : '-' }}
                    </div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Проект</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.project ? roomInfo.project.name : '-' }}
                    </div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Кластер</div>
                    <div class='log-information__meta-value'>
                        {{ roomInfo.cluster ? roomInfo.cluster.name : '-' }}
                    </div>
                </div>
                <div class='log-information__title-block' v-if='canViewCommonInfo'>
                    <div class='log-information__title-value'>КPI</div>
                </div>
                <div class='log-information__meta' v-if='canViewCommonInfo'>
                    <div class='log-information__meta-label'>Бюджет</div>
                    <div class='log-information__meta-value'>{{ roomInfo.budget ? roomInfo.budget + ' ₽' : '-' }}</div>
                </div>
                <div class='log-information__meta'>
                    <div class='log-information__meta-label'>Срок исследования</div>
                    <div class='log-information__meta-period'>
                        <div class='log-information__meta-period-from'>
                            <div class='log-information__meta-period-label'>Начало</div>
                            <div class='log-information__meta-value'>
                                {{ roomInfo.start_date ? getDate(roomInfo.start_date) : '-' }}
                            </div>
                        </div>
                        <div class='log-information__meta-period-to'>
                            <div class='log-information__meta-period-label'>Окончание</div>
                            <div class='log-information__meta-value'>
                                {{ roomInfo.end_date ? getDate(roomInfo.end_date) : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class='log-information__meta' v-if='canViewCommonInfo'>
                    <div class='log-information__meta-label'>Качество</div>
                    <div class='log-information__meta-text'>
                        {{ roomInfo.quality_parameters ? roomInfo.quality_parameters : '-' }}
                    </div>
                </div>
                <div class='log-information__meta' v-if='canViewCommonInfo'>
                    <div class='log-information__meta-label'>HSE</div>
                    <div class='log-information__meta-text'>
                        {{ roomInfo.hse_parameters ? roomInfo.hse_parameters : '-' }}
                    </div>
                </div>
                <div class='log-information__title-block' v-if='roomMedia.length > 0 && canViewCommonInfo'>
                    <div class='log-information__title-value'>Медиа</div>
                    <a class='log-information__title-link' @click='mediaInfoIsShow = true'>Все</a>
                </div>
                <div class='log-information__images row' v-if='roomMedia.length > 0 && canViewCommonInfo'>
                    <div class='col-3' v-for='media in roomMedia' :key='media.id'>
                        <div class='log-information__image-item' :style='{
                                    backgroundImage: `url(${media.preview})`
                                }'
                        ></div>
                    </div>
                </div>
                <div class='log-information__title-block' v-if='roomDocuments.length > 0 && canViewCommonInfo'>
                    <div class='log-information__title-value'>Документы</div>
                    <a class='log-information__title-link ' @click='documentsInfoIsShow = true'>Все</a>
                </div>
                <div v-if='canViewCommonInfo && roomDocuments.length'>
                    <a
                        class='file-block'
                        v-for='(item, dIndex) in roomDocuments.slice(0, 3)'
                        :key='dIndex'
                        :href='item.file_data'
                        :download='item.file_name'
                    >
                        <div class='file-block__icon'>
                            <div class='base-icon-file'></div>
                        </div>
                        <div class='file-block__meta'>
                            <div class='file-block__name'>{{ item.file_name }}</div>
                            <div class='file-block__size'>{{ fileSize(item.file_size) }}</div>
                        </div>
                    </a>
                </div>

                <div class='log-information__title-block' v-if='links.count > 0 && canViewCommonInfo'>
                    <div class='log-information__title-value'>Ссылки</div>
                    <a class='log-information__title-link' href='#' @click.prevent="selectDetailsInfo('Ссылки')">Все</a>
                </div>
                <div v-if='links.count > 0 && canViewCommonInfo'>
                    <div class='link-block' v-if='canViewCommonInfo'>
                        <div class='link-block__icon base-icon-mdi_link'></div>
                        <a class='link-block__link' href='#'>https://www.youtube.com/watch?v=5qKYrajRNwo</a>
                    </div>
                    <div class='link-block' v-if='canViewCommonInfo'>
                        <div class='link-block__icon base-icon-mdi_link'></div>
                        <a class='link-block__link' href='#'>https://www.youtube.com/watch?v=5qKYrajRNwo</a>
                    </div>
                </div>

                <div class='log-information__title-block' v-if='canViewCommonInfo'>
                    <div class='log-information__title-value'>Участники</div>
                    <a
                        class='log-information__title-link'
                        href='#'
                        @click.prevent='showParticipants'
                        v-if='canAddParticipantsByResearch'
                    >
                        <i class='base-icon-add_circle'></i>Добавить</a
                    >
                </div>
                <div v-if='canViewCommonInfo'>
                    <div class='user' v-for='participant in roomInfo.participants' :key='participant.id'>
                        <router-link :to='`/user-profile/${participant.id}`'>
                            <div
                                class='user__avatar'
                                :style='{
                                    backgroundImage: $getUserAvatar(participant.userpic)
                                }'
                            ></div>
                        </router-link>
                        <div class='user__ct'>
                            <div class='user__name'>{{ participant.full_name }}</div>
                            <div class='user__post'>{{ getUserTag(participant.id) }}</div>
                        </div>
                        <div class='user__buttons' v-if='canAddParticipantsByResearch'>
                            <div class='user__chat base-icon-message'></div>
                            <div
                                v-if='roomInfo.participants.length > 2'
                                class='user__remove base-icon-delete-1'
                                @click='confirmRemoveParticipant(participant)'
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='popup confirm-popup' v-if='showConfirm'>
            <div class='popup__overlay'></div>
            <div class='popup__block'>
                <div class='popup__title'>
                    Вы действительно хотите удалить пользователя “{{ removedParticipant.full_name }}” из приватного
                    обсуждения?
                </div>
                <div class='popup__buttons'>
                    <button class='button' type='submit' @click.prevent='removeParticipant(removedParticipant.id)'>
                        <span>Да, удалить </span>
                    </button>
                    <button class='button bordered brand' type='button' @click.prevent='showConfirm = false'>
                        <span>Нет, вернуться в чат</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Dates from '@/mixins/Dates';
import DocumentsInfo from './DocumentsInfo';
import SolutionsInfo from './SolutionsInfo';
import MediaInfo from './MediaInfo';
import {can} from '@/helpers/permissions';
import {actionTypes as listsActionTypes, actionTypes as listActionTypes} from '@/store/modules/lists';

export default {
    name: 'GeoLogRoomInfo',
    data() {
        return {
            documentsInfoIsShow: false,
            solutionsInfoIsShow: false,
            mediaInfoIsShow: false,
            links: '',
            showConfirm: false,
            removedParticipant: {}
        };
    },
    mixins: [Dates],
    components: {DocumentsInfo, SolutionsInfo, MediaInfo},
    props: {
        roomId: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            roomInfo: state => state.lists?.roomInfo || [],
            roomDocuments: state => state.lists?.roomDocuments?.results || [],
            roomMedia: state => state.lists?.roomMedia?.results || []
        }),
        canAddParticipantsByResearch: function() {
            return can('addParticipantsByResearch');
        },
        canViewCommonInfo: function() {
            return can('viewCommonInfo');
        }
    },
    watch: {
        '$route.params.id': {
            handler: function() {
                this.getData(this.$route.params.id);
            }
        }
    },
    mounted() {
        this.getData(this.roomId);
    },
    methods: {
        async getData(roomId) {
            await this.$store.dispatch(listsActionTypes.getRoomDocuments, roomId);
            await this.$store.dispatch(listsActionTypes.getRoomMedia, roomId);
            await this.$store.dispatch(listsActionTypes.getRoomSolutions, {id: roomId});
        },
        showParticipants() {
            this.$emit('show-participants');
        },
        closeInfo() {
            this.$emit('close-info');
        },
        confirmRemoveParticipant(participant) {
            this.removedParticipant = participant;
            this.showConfirm = true;
        },
        async removeParticipant(id) {
            await this.$store.dispatch(listActionTypes.removeParticipants, {
                id: this.roomInfo.id,
                participants: [id]
            });
            await this.$store.dispatch(listsActionTypes.getRoomInfo, this.roomInfo.id);
            this.showConfirm = false;
        },
        fileSize(size) {
            if (size <= 1024) {
                return size + ' B';
            }
            return parseFloat(size / 1024).toFixed(2) + ' KB';
        },
        getUserTag(id) {
            let userTag = this.roomInfo.user_tags.find(item => item.user === id);
            return userTag ? userTag.tag.name : '-';
        }
    }
};
</script>

<style scoped>
.log-information__title-link {
    cursor: pointer;
}
</style>
