<template>
    <div class='image-popup'>
        <div class='image-popup__overlay'>
        </div>
        <div class='image-popup__button close-button base-icon-close' @click='close'>
        </div>
<!--        <div class='image-popup__button prev-button base-icon-arrow-left'>-->
<!--        </div>-->
<!--        <div class='image-popup__button next-button base-icon-arrow-right'>-->
<!--        </div>-->
        <div class='image-popup__image'>
            <video-player class='vjs-custom-skin'
                          v-bind:class='{audio: videoPopupFile.type === "audio/mp3"}'
                          ref='videoPlayer'
                          :options='playerOptions'
                          :playsinline='true'>
            </video-player>
        </div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {mapState} from 'vuex';

export default {
    name: 'GeoVideoPopup',
    data() {
        return {
            playerOptions: {
                width: '600',
                autoplay: false,
                muted: false,
                language: 'ru',
                sources: [],
                controls: true
            }
        };
    },
    mixins: [Dates],
    mounted() {
        this.playerOptions.sources.push({src: this.videoPopupFile.url, type: this.videoPopupFile.type});
    },
    computed: {
        ...mapState({
            videoPopupFile: state => state.modals?.videoPopupFile
        }),
        player() {
            return this.$refs.videoPlayer.player;
        }
    },
    methods: {
        close() {
            this.$store.commit(modalMutationTypes.videoPopupClose);
        }
    }
};
</script>

<style scoped>
.vjs-custom-skin {
    margin: auto;
}

>>>.audio .video-js {
    background-color: transparent !important;
}
>>>.audio .video-js {
    background-color: transparent !important;
}
>>>.audio .vjs-big-play-button {
    display: none !important;
}
 >>>.audio .vjs-control-bar {
     display: flex!important;
     visibility: visible!important;
     opacity: 1 !important;
 }
 >>>.vjs-big-play-button {
     top: 50%!important;
     left: 50%!important;
     margin-top: -1em;
     margin-left: -1.5em;
 }
</style>
