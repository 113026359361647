<template>
    <div class='log-information col-3'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3 warning'>
                <i class="el-icon-back" @click="closeInfo" style='cursor: pointer'></i>
                &nbsp;Документы
            </div>
        </div>
        <div class='log-information__wrap'>
            <a
                class='file-block'
                v-for='(item, dIndex) in roomDocuments.results'
                :key='dIndex'
                :href='item.file_data'
                :download='item.file_name'
            >
                <div class='file-block__icon'>
                    <div class='base-icon-file'></div>
                </div>
                <div class='file-block__meta'>
                    <div class='file-block__name'>{{ item.file_name }}</div>
                    <div class='file-block__size'>{{ fileSize(item.file_size) }}</div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogDocumentsInfo',
    data() {
        return {};
    },
    mixins: [Dates],
    computed: {
        ...mapState({
            roomDocuments: state => state.lists?.roomDocuments || [],
        })
    },
    methods: {
        closeInfo() {
            this.$emit('close-info');
        },
        fileSize(size) {
            if (size <= 1024) {
                return size + ' B';
            }
            return parseFloat(size / 1024).toFixed(2) + ' KB';
        },
    }
};
</script>
