<template>
    <div class='input-block'>
        <label class='input-block__label'><span>ЧОНА</span></label>
        <div class='input-block__buttons'>
            <label class='chips-selector' v-for='well in wells' :key='well.id'>
                <input :id='well.id' type='checkbox' v-model='selected' :value='well.id' @change='select(well.id)'/>
                <span> <i class='base-icon-delivered'></i>{{ well.name }} </span>
            </label>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoLogWellsFilter',
    data() {
        return {
            selected: []
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            wells: state => state.guides?.wells || []
        })
    },
    methods: {
        select(id) {
            this.selected = [id];
            this.model.wells = id;
        }
    }
};
</script>
