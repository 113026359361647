const getClusterTypes = () => {
    return {
        path: 'lists/cluster-types',
        method: 'get'
    };
};

//FIXME add limit & offset
const getUsers = ({query}) => {
    return {
        path: `users?query=${query}&limit=${100}`,
        method: 'list'
    };
};

const getUser = id => {
    return {
        path: `users?id=${id}`,
        method: 'get'
    };
};

const getAvailableRecipients = id => {
    return {
        path: `rooms/available-recipients?id=${id}`,
        method: 'get'
    };
};

const getUserTags = () => {
    return {
        path: 'lists/user-tags',
        method: 'list'
    };
};

const getUserRooms = id => {
    return {
        path: `users/rooms?id=${id}`,
        method: 'get'
    };
};

const getProjects = () => {
    return {
        path: 'lists/projects',
        method: 'list'
    };
};

const getWells = ({query, limit, offset}) => {
    return {
        path: `lists/wells?query=${query}&limit=${limit}&offset=${offset}`,
        method: 'list'
    };
};

const getMessageReadings = id => {
    return {
        path: `messages/readings?id=${id}`,
        method: 'get'
    };
};

const getTypesOfResearch = type => {
    return {
        path: type ? 'lists/types-of-research/?chat_type=0100000000' : 'lists/types-of-research',
        method: 'list'
    };
};

export default {
    getClusterTypes,
    getUsers,
    getUser,
    getProjects,
    getWells,
    getMessageReadings,
    getAvailableRecipients,
    getUserTags,
    getUserRooms,
    getTypesOfResearch
};
