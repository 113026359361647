<template>
    <div class='statistics-table-wrapper'>
        <div v-if='Object.keys(items).length !== 0'>
            <table class='table statistics-table'>
                <thead>
                <tr>
                    <th class='statistics-table_th'></th>
                    <th colspan='2'>Входящие запросы
                        <el-tooltip
                            placement='top' effect='light' popper-class='statistics-table__popper'>
                            <div slot='content'>Учитываются решенные сотрудником<br>запросы и запросы с истекшим сроком
                                без<br>решения за период
                            </div>
                            <i v-bind:class="[isIssueInTooltip ? 'svg-icon-help-full' : 'svg-icon-help']"
                               @mouseover='isIssueInTooltip = true' @mouseout='isIssueInTooltip = false'></i>
                        </el-tooltip>
                    </th>
                    <th colspan='2'>Исходящие запросы
                        <el-tooltip
                            placement='top' effect='light' popper-class='statistics-table__popper'>
                            <div slot='content'>Учитываются поставленные сотрудником<br>решенные запросы и запросы с
                                истекшим<br>сроком без решения за период
                            </div>
                            <i v-bind:class="[isIssueOutTooltip ? 'svg-icon-help-full' : 'svg-icon-help']"
                               @mouseover='isIssueOutTooltip = true' @mouseout='isIssueOutTooltip = false'></i>
                        </el-tooltip>
                    </th>
                    <th colspan='2'>Задачи
                        <el-tooltip
                            placement='top' effect='light' popper-class='statistics-table__popper'>
                            <div slot='content'>Учитываются решенные задачи и задачи<br>с истекшим сроком без решения за
                                период
                            </div>
                            <i v-bind:class="[isRoomTooltip ? 'svg-icon-help-full' : 'svg-icon-help']"
                               @mouseover='isRoomTooltip = true' @mouseout='isRoomTooltip = false'></i>
                        </el-tooltip>
                    </th>
                </tr>
                <tr>
                    <td>Сотрудник</td>
                    <td>Всего, шт <i
                        v-bind:class="[
                            order === 'issue_deadline_in' ?
                            'svg-icon-sort-up' : order === '-issue_deadline_in' ?
                            'svg-icon-sort-down' : 'svg-icon-sort-default'
                        ]"
                        class='sort-icon' @click='changeOrder("issue_deadline_in")'></i></td>
                    <td>Выполнены в срок <i
                        v-bind:class="[
                            order === 'issue_not_expired_in' ?
                            'svg-icon-sort-up' : order === '-issue_not_expired_in' ?
                            'svg-icon-sort-down' : 'svg-icon-sort-default'
                        ]"
                        class='svg-icon-sort-default sort-icon' @click='changeOrder("issue_not_expired_in")'></i></td>
                    <td>Всего, шт <i
                        v-bind:class="[
                            order === 'issue_deadline_out' ?
                            'svg-icon-sort-up' : order === '-issue_deadline_out' ?
                            'svg-icon-sort-down' : 'svg-icon-sort-default'
                        ]"
                        class='svg-icon-sort-default sort-icon' @click='changeOrder("issue_deadline_out")'></i></td>
                    <td>Выполнены в срок <i
                        v-bind:class="[
                            order === 'issue_not_expired_out' ?
                            'svg-icon-sort-up' : order === '-issue_not_expired_out' ?
                            'svg-icon-sort-down' : 'svg-icon-sort-default'
                        ]"
                        class='svg-icon-sort-default sort-icon' @click='changeOrder("issue_not_expired_out")'></i></td>
                    <td>Всего, шт <i
                        v-bind:class="[
                            order === 'room_closed' ?
                            'svg-icon-sort-up' : order === '-room_closed' ?
                            'svg-icon-sort-down' : 'svg-icon-sort-default'
                        ]"
                        class='svg-icon-sort-default sort-icon' @click='changeOrder("room_closed")'></i></td>
                    <td>Закрыты в срок <i
                        v-bind:class="[
                            order === 'room_closed_planned' ?
                            'svg-icon-sort-up' : order === '-room_closed_planned' ?
                            'svg-icon-sort-down' : 'svg-icon-sort-default'
                        ]"
                        class='svg-icon-sort-default sort-icon' @click='changeOrder("room_closed_planned")'></i></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(row, index) in items' :key='`row-${index}`'>
                    <td>{{ row.user ? row.user.full_name : 'Проектные показатели' }}</td>
                    <td>{{ row.issue_deadline_in }}</td>
                    <td v-html='getPercent(row.issue_not_expired_in, row.issue_deadline_in)'></td>
                    <td>{{ row.issue_deadline_out }}</td>
                    <td v-html='getPercent(row.issue_not_expired_out, row.issue_deadline_out)'></td>
                    <td>{{ row.room_closed }}</td>
                    <td v-html='getPercent(row.room_closed_planned, row.room_closed + row.room_not_closed_planned)'></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else><span class='form-label'>Нет данных</span></div>
    </div>
</template>

<script>
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoStatisticsTable',
    data() {
        return {
            isIssueInTooltip: false,
            isIssueOutTooltip: false,
            isRoomTooltip: false,
            order: ''
        };
    },
    components: {},
    mixins: [Dates],
    computed: {},
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    async created() {
    },
    methods: {
        getPercent(x, y) {
            if (!y) {
                return 0;
            }
            let result = (x / y * 100).toFixed(1);
            return result <= 50 ? `<span class='danger'>${result}%</span>` :
                result < 70 ? `<span class='warninig'>${result}%</span>` : `<span class='success'>${result}%</span>`;
        },
        changeOrder(param) {
            if (!this.order.includes(param)) {
                this.order = param;
            } else {
                if (this.order.includes("-")) {
                    this.order = "";
                } else {
                    this.order = "-" + param;
                }
            }
            this.$emit('change-order', this.order);
        }
    }
};
</script>

<style scoped>
.statistics-table-wrapper {

}

.statistics-table {
    width: 100%;
    border-collapse: separate;
    text-align: center;
    border-spacing: 0;
    margin-bottom: 20px;
}

.statistics-table thead {
    font-weight: 700;
    font-size: 20px;
}

.statistics-table tr th, tr td {
    padding: 16px;
    border-right: 1px solid #CCD9E0;
    border-bottom: 1px solid #CCD9E0;
}

.statistics-table tr th {
    border-top: 1px solid #CCD9E0;
}

.statistics-table tr th:first-child, tr td:first-child {
    border-left: 1px solid #CCD9E0;
}

.statistics-table tr:first-child th:first-child {
    border-top-left-radius: 8px;
}

.statistics-table tr:first-child th:last-child {
    border-top-right-radius: 8px;
}

.statistics-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.statistics-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.statistics-table_th {
    min-width: 300px;
}

>>> .statistics-table .danger {
    color: #EB3333;
}

>>> .statistics-table .warning {
    color: #FFA10A;
}

>>> .statistics-table .success {
    color: #09B37B;
}

.svg-icon-help {
    transition: 0.1s;
    transition-delay: 0.3s;
}

.sort-icon {
    cursor: pointer;
}
</style>
