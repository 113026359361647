<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Новая задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='form-label'>РОЛИ УЧАСТНИКОВ</div>
                <div v-for='(participant, index) in model.participants' :key='participant.id'>
                    <div class='user'>
                        <div
                            class='user__avatar'
                            :style="{
                                backgroundImage: $getUserAvatar(participant.userpic)
                            }"
                        ></div>
                        <div class='user__ct'>
                            <div class='user__name'>{{ participant.full_name }}</div>
                        </div>
                        <div class='user__buttons' v-if='participant.id !== currentUser.id'>
                            <div
                                class='user__remove base-icon-delete-1'
                                @click='removeParticipant(participant, index)'
                            ></div>
                        </div>
                    </div>
                    <div class='input-block select-block with-indent'>
                        <label class='input-block__label'><span>Роль участника</span> </label>
                        <!--                        <input type='hidden' v-model='model.user_tags[index]["user_id"]'>-->
                        <el-select v-model='model.user_tags[index]' filterable>
                            <el-option v-for='item in userTags' :key='item.id' :label='item.name' :value='item.id'>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class='popup__buttons'>
                <button class='button small secondary' type='button' @click='prevStep'>
                    <span>Назад</span>
                </button>
                <button class='button small' type='submit' @click='nextStep' :disabled='isDisabled'>
                    <span>Далее</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';

export default {
    name: 'AdmTaskModalStep4',
    data() {
        return {};
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            userTags: state => state.guides?.userTags?.results || [],
            currentUser: state => state.auth?.user
        }),
        isDisabled: function() {
            return this.model.user_tags.length <= 1 || this.model.user_tags.includes(undefined) || (this.model.user_tags.length !== this.model.participants.length);
        }
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getUserTags);

    },
    methods: {
        removeParticipant(user, index) {
            this.model.participants = this.model.participants.filter(function(el) {
                return el.id !== user.id;
            });
            this.model.user_tags.splice(index, 1);
        },
        nextStep() {
            this.$emit('setStep', 'step5');
        },
        prevStep() {
            this.$emit('setStep', 'step3');
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.admTaskModalClose);
        }
    }
};
</script>
