<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='form-label'>ПАРАМЕТРЫ ЗАДАЧИ</div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Кластер</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='cluster'
                        placeholder=''
                        required='required'
                        :value='roomInfo.cluster ? roomInfo.cluster.name : "-"'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'>
                        <span>Проект</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='text'
                        name='project'
                        placeholder=''
                        required='required'
                        :value='roomInfo.project ? roomInfo.project.name : "-"'
                        :disabled='true'
                    />
                </div>
                <div class='input-block input-block__noedit'>
                    <label class='input-block__label'><span>Наименование</span><em>*</em> </label>
                    <input
                        v-model='roomInfo.title'
                        class='input-block__input'
                        type='text'
                        name='name'
                        placeholder=''
                        required='required'
                        :disabled='true'
                    />
                </div>
                <div class='input-block period-block'>
                    <label class='input-block__label'><span>Сроки</span><em>*</em> </label>
                    <div class='period-cols'>
                        <div class='period-cols__col'>
                            <el-date-picker
                                v-model='date'
                                type='daterange'
                                start-placeholder='С'
                                end-placeholder='По'
                                format='dd.MM.yyyy'
                                value-format='yyyy-MM-ddTHH:mm:ss'
                                :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class='popup__buttons with-border'>
                <button class='button small' type='submit' @click='updateRoom'>
                    <span>Сохранить</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';

export default {
    name: 'RoomModal',
    data() {
        return {
            model: {},
            date: []
        };
    },
    computed: {
        ...mapState({
            roomInfo: state => state.lists?.roomInfo || {}
        })
    },
    components: {},
    async created() {
        this.model = Object.assign(this.model,  {
            id:  this.roomInfo.id,
            title: this.roomInfo.title,
            status: this.roomInfo.status,
            well: this.roomInfo.well?.id,
            type_of_research: this.roomInfo.type_of_research?.id,
            budget: this.roomInfo.budget,
            quality_parameters: this.roomInfo.quality_parameters,
            hse_parameters: this.roomInfo.hse_parameters,
            cluster: this.roomInfo.cluster?.id,
            project: this.roomInfo.project?.id,
            participants:  this.roomInfo.participants?.map(({id}) => id),
            user_tags:  this.roomInfo.user_tags?.map((userTag) => {
                return {
                    tag_id: userTag.tag.id,
                    user_id: userTag.user
                };
            }),
            administrators:  this.roomInfo.administrators?.map(({id}) => id),
            wells:  this.roomInfo.wells?.map(({id}) => id),
            license_areas:  this.roomInfo.license_areas?.map(({id}) => id),
        });
        this.date = [this.roomInfo.start_date, this.roomInfo.end_date];
    },
    methods: {
        async updateRoom() {
            [this.model.start_date, this.model.end_date] = this.date;
            await this.$store.dispatch(listsActionTypes.updateTask, this.model);
            await this.$store.dispatch(listsActionTypes.getRoomInfo, this.roomInfo?.id);
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-edit-room');
        }
    }
};
</script>
