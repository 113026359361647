<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Кластер</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Название кластера</span>
                        <em>*</em>
                    </label>
                    <input
                        v-model='model.name'
                        class='input-block__input'
                        type='text'
                        name='name'
                        placeholder=''
                        required='required'
                    />
                </div>
                <div class='input-block select-block'>
                    <label class='input-block__label'><span>Тип кластера</span><em>*</em> </label>
                    <el-select v-model='model.cluster_type' :disabled='isClusterModalEdit'>
                        <el-option
                            v-for='item in clusterTypes'
                            :key='item.value'
                            :label='item.name'
                            :value='item.internal_name'
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class='input-block select-user-block'>
                    <label class='input-block__label'><span>Руководитель кластера</span><em>*</em> </label>
                    <div
                        class='user__avatar select-user'
                        :style='{
                        backgroundImage: `url(${userpic})`
                    }'
                    ></div>
                    <el-select v-model='model.owner' filterable remote placeholder='Иванов И.И.'
                               :remote-method='getUsers'>
                        <el-option v-for='item in users' :key='item.value' :label='item.full_name'
                                   :value='item.id'></el-option>
                    </el-select>
                </div>
            </div>
            <div class='popup__buttons'>
                <button
                    class='button small'
                    type='submit'
                    @click='update'
                    :disabled='isDisabled'
                    v-if='isClusterModalEdit'
                >
                    <span>Сохранить</span>
                </button>
                <button
                    class='button small'
                    type='submit'
                    @click='create'
                    :disabled='isDisabled'
                    v-if='!isClusterModalEdit'
                >
                    <span>Добавить</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {getterTypes as modalGetterTypes, mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import eventHub from '@/store/events/eventHub.js';

export default {
    name: 'GeoClusterModal',
    data() {
        return {
            model: {
                id: '',
                name: '',
                cluster_type: '',
                owner: ''
            }
        };
    },
    computed: {
        ...mapState({
            selectedCluster: state => state.lists?.selectedCluster,
            clusterTypes: state => state.guides?.clusterTypes || [],
            users: state => state.guides?.users?.results || []
        }),
        ...mapGetters({
            isClusterModalEdit: modalGetterTypes.clusterModalEdit
        }),
        isDisabled: function() {
            return !(this.model.name && this.model.cluster_type && this.model.owner);
        },
        userpic: function() {
            let user = this.users.find(item => item.id === this.model.owner);
            return this.$getUserAvatar(user?.userpic);
        }
    },
    async created() {
        if (this.isClusterModalEdit) {
            let {id, name, cluster_type, owner_id: owner} = {...this.selectedCluster};
            this.model = Object.assign({}, {id, name, cluster_type, owner});
        }
        await this.$store.dispatch(guidesActionTypes.getClusterTypes);
        await this.$store.dispatch(guidesActionTypes.getUsers);
        eventHub.$once('cluster-create-error', this.alert);
        eventHub.$once('cluster-create-success', this.message);
    },
    methods: {
        create() {
            this.$store.dispatch(listsActionTypes.createCluster, this.model);
        },
        update() {
            this.$store.dispatch(listsActionTypes.updateCluster, this.model);
        },
        alert(params) {
            this.$alert(params.message, params.title, {
                confirmButtonText: 'Закрыть'
            });
        },
        message(params) {
            console.log(params)
            this.$message({
                dangerouslyUseHTMLString: true,
                message: params.message,
                type: params.type,
                showClose: true,
                duration: 5000
            });
            this.closeModal();
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.clusterModalClose);
        },
        async getUsers(query = '') {
            await this.$store.dispatch(guidesActionTypes.getUsers, {query: query});
        }

    }
};
</script>

<style scoped>
.el-select {
    width: 100%;
}

.el-input .el-input__inner {
    height: 48px !important;
    border-radius: 8px;
}

.el-select-dropdown__item {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 10px 12px;
    border-radius: 0;
}

.el-select-dropdown__item.selected {
    background-color: #f2f4f5;
    display: flex;
    align-items: center;
    min-height: 48px;
    border-radius: 0;
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606266 !important;
    font-weight: normal !important;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
}

.select-user {
    top: 22px;
    left: 12px;
    position: absolute;
}

::v-deep .select-user-block input {
    text-indent: 40px;
}
</style>
