<template>
    <div class='log-tasks col-3'>
        <div class='log-tasks__top'>
            <div class='log-tasks__title h3'>Задачи</div>
            <label class='chips-selector'>
                <input type='checkbox' v-model='isMyRooms' @change='changeRoomTypes' /><span
            ><i class='base-icon-delivered'></i>Мои проекты</span
            >
            </label>
            <div class='filter-button' @click='toggleFilters'><i class='svg-icon-filter'></i><span>Фильтр</span></div>
        </div>
        <div class='search-form' v-show='false'>
            <div class='search-form__input-block'>
                <div class='search-form__button base-icon-search'></div>
                <input class='search-form__input' placeholder='Поиск' type='text' @input='searchRooms' />
            </div>
        </div>
        <div class='chat-list' v-if='rooms.length'>
            <div
                class='chat-task'
                v-for='room in rooms'
                :key='room.id'
                :ref="'room-' + room.id"
                :class="{
                    current: selectedRoom && room.id === selectedRoom.id,
                    unread: room.unread_messages_count || room.unread_issues_count,
                    success: room.status === 'ACTIVE',
                    alert: room.status === 'PASSIVE'
                }"
                @click='selectRoom(room.id)'
            >
                <div class='chat-task__top'>
                    <div class='chat-task__status'></div>
                    <div class='chat-task__caption'>
                        {{ room.project_title }}  {{ room.well_titles ? '|' : '' }}
                        {{ room.well_titles }}
                    </div>
                    <div class='chat-task__date'>{{ room.last_message ? getDate(room.last_message.created_at) : '' }}</div>
                </div>
                <div class='chat-task__title'>{{ room.title }}</div>
                <div class='chat-task__desc'>{{ room.last_message ? room.last_message.data : '' }}</div>
                <div class='chat-task__meta'>
                    <div class='chat-task__new-msg-count' v-if='room.unread_issues_count'>
                        <i class='svg-icon-down'></i><span>{{ room.unread_issues_count }}</span>
                    </div>
                    <div class='chat-task__msg-count' v-if='room.unread_messages_count'>
                        <i class='svg-icon-cloud'> </i><span>{{ room.unread_messages_count }}</span>
                    </div>
                </div>
            </div>
            <infinite-loading @infinite='getMoreRooms'>
                <div slot='no-more'></div>
                <div slot='no-results'></div>
            </infinite-loading>
        </div>
        <div class='chat-wrap__empty-list h4' v-else>Задачи не найдены</div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Dates from '@/mixins/Dates';

export default {
    name: 'GeoLogList',
    data() {
        return {
            isMyRooms: false
        };
    },
    mixins: [Dates],
    props: {},
    components: {},
    computed: {
        ...mapState({
            rooms: state => state.lists?.rooms || [],
            selectedRoom: state => state.lists?.selectedRoom
        })
    },
    async created() {

    },
    methods: {
        async getMoreRooms($state) {
            await this.$emit('get-more-rooms', $state);
        },
        selectRoom(id) {
            this.$router.push(`/log/rooms/${id}`);
        },
        searchRooms(event) {
            this.$emit('get-rooms', event.target.value);
        },
        changeRoomTypes() {
            this.$emit('change-room-types');
        },
        toggleFilters() {
            this.$emit('toggle-filters');
        }
    }
};
</script>

<style scoped>
.chat-task {
    cursor: pointer;
    min-height: 82px;
}

.chat-task__desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-wrap__empty-list {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #667985;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.filter-button {
    cursor: pointer;
}
</style>
