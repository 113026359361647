const getProjectFacts = ({id, from, to, users = [], order = ""}) => {
    return {
        path: `statistics/project-facts?project=${id}&from=${from}&to=${to}&users=${users}&order=${order}`,
        method: 'list'
    };
};

const getUserFacts = ({from0, to0, from1, to1, users = []}) => {
    return {
        path: `statistics/user-facts?from0=${from0}&to0=${to0}&from1=${from1}&to1=${to1}&users=${users}`,
        method: 'list'
    };
};

export default {
    getProjectFacts,
    getUserFacts
};
