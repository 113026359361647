<template>
    <div class="projects-list">
        <div
            class="projects-list__item"
            v-for="(project, index) in projects"
            :key="index"
            :class="{current: selectedProject && project.id === selectedProject.id}"
            @click="selectProject(project.id)"
            @contextmenu.prevent="showMenu($event, project.id)"
        >
            <div class="edit-heading">
                <div class="edit-heading__heading h4">
                    <span>{{ project.name }}</span>
                    <a
                        class="edit-heading__edit-link svg-icon-edit"
                        href="#"
                        @click.prevent="editProject()"
                        v-if="selectedProject && project.id === selectedProject.id && canCreateEditProject"
                    >
                    </a>
                </div>
            </div>
            <div class="projects-list__status">
                {{ project.status === 'ACTIVE' ? 'Активный проект' : 'Неактивный проект' }}
            </div>
        </div>
        <div
            ref="popoverMenu"
            class="popover-menu"
            v-if="viewMenu"
            v-bind:style="{top: top, left: left}"
            @focusout="closeMenu"
        >
            <ul>
                <li class="dropdown__item dropdown__type-right-click" v-if='canCreateEditProject'>
                    <a href="#" @click.prevent="editProject()"><span>Редактировать</span></a>
                </li>
                <li class="dropdown__item dropdown__type-right-click">
                    <a href="#"><span>Добавить в избранное</span></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {mutationTypes as modaMutationTypes} from '@/store/modules/modals';
import {can} from '@/helpers/permissions';

export default {
    name: 'ProjectsList',
    data() {
        return {
            viewMenu: false,
            showReply: false,
            top: '0px',
            left: '0px'
        };
    },
    props: {
        projects: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState({
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject
        }),
        canCreateEditProject: function() {
            return can('createEditProject');
        }
    },
    methods: {
        selectProject(id) {
            this.$emit('select-project', id);
        },
        editProject() {
            this.$store.commit(modaMutationTypes.projectModalOpen, 'edit');
        },
        setMenu: function(top, left) {
            let largestHeight = window.innerHeight - this.$refs.popoverMenu.offsetHeight;
            let largestWidth = window.innerWidth - this.$refs.popoverMenu.offsetWidth - 25;

            if (top > largestHeight) top = largestHeight;
            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },
        showMenu(e, id) {
            this.selectProject(id);
            this.viewMenu = true;
            this.$nextTick(() => {
                this.setMenu(e.y, e.x);
            });
        },
        closeMenu: function() {
            this.viewMenu = false;
        }
    }
};
</script>

<style scoped>
.popover-menu {
    position: fixed;
    display: flex;
    align-items: flex-end;
    background-color: #ffffff;
    flex-wrap: wrap;
    z-index: 99;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.08);
}
</style>
