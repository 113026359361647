<template>
    <main class='main-block'>
        <breadcrumbs title='Аналитическая сводка по моей работе'></breadcrumbs>
        <div class='analytics'>
            <div class='analytics__wrap'>
                <div class='analytics__content'>
                    <div class='content__title'>
                        <h2>Факт выполнения запросов и задач за период</h2>
                    </div>
                    <div class='content__filters'>
                        <div class='input-block select-block dropdown-filter'>
                            <label class='input-block__label'>
                                <span>Кластер</span>
                            </label>
                            <div class='select-block__button'>
                                <div class='select-block__button__label'>
                                    {{ filters.cluster_id ? selectedCluster : '' }}
                                </div>
                                <i class='base-icon-arrow-down'></i>
                            </div>
                            <div class='dropdown'>
                                <div
                                    class='dropdown__item dropdown__type-select'
                                    v-for='(item, index) in clustersArray'
                                    :key='index'
                                    @click='selectCluster(item)'
                                    @change='selectCluster(item)'
                                >
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class='input-block select-block dropdown-filter'
                             v-bind:class='{"input-block__noedit": !filters.cluster_id}'>
                            <label class='input-block__label'>
                                <span>Проект</span>
                                <em>*</em>
                            </label>
                            <div class='select-block__button'>
                                <div class='select-block__button__label'>
                                    {{ filters.project_id ? selectedProject : '' }}
                                </div>
                                <i class='base-icon-arrow-down'></i>
                            </div>
                            <div class='dropdown'>
                                <div
                                    class='dropdown__item dropdown__type-select'
                                    v-for='(item, index) in projectsArray'
                                    :key='index'
                                    v-on:click='selectProject(item)'
                                >
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class='input-block select-block'>
                            <label class='input-block__label'>
                                <span>От</span>
                                <em>*</em>
                            </label>
                            <el-date-picker
                                v-model='filters.from'
                                type='date'
                                format='dd.MM.yyyy'
                                placeholder='дд.мм.гггг'
                                value-format='yyyy-MM-dd'
                                :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                            >
                            </el-date-picker>
                        </div>
                        <div class='input-block select-block'
                             v-bind:class='{"input-block__noedit": !filters.from}'>
                            <label class='input-block__label'>
                                <span>До</span>
                                <em>*</em>
                            </label>
                            <el-date-picker
                                v-model='filters.to'
                                type='date'
                                format='dd.MM.yyyy'
                                placeholder='дд.мм.гггг'
                                value-format='yyyy-MM-dd'
                                :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div class='content__nav'>
                        <button class='button primary btn-show-analytic' type='button' :disabled='isEmptyFilters'
                                @click='loadAnalytics'>
                            <span>Показать</span>
                        </button>
                    </div>
                    <div class='content__table'>
                        <analytics-table :userItems='userItems' :projectItems='projectItems' />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Dates from '@/mixins/Dates';
import Breadcrumbs from '../../components/Breadcrumbs';
import {mapGetters, mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {actionTypes as statisticsActionTypes} from '@/store/modules/statistics';
import {getterTypes as statisticsGetterTypes} from '@/store/modules/statistics';
import AnalyticsTable from './components/AnalyticsTable';


export default {
    name: 'GeoAnalytics',
    data() {
        return {
            filters: {
                cluster_id: '',
                project_id: '',
                from: '',
                to: ''
            },
            selectedCluster: '',
            selectedProject: '',
            userItems: {},
            projectItems: {}
        };
    },
    components: {Breadcrumbs, AnalyticsTable},
    mixins: [Dates],
    computed: {
        ...mapState({
            clusters: state => state.lists?.clusters || [],
            projects: state => state.lists?.projects || [],
            currentUser: state => state.auth?.user || {},
        }),
        ...mapGetters({
            getCurrentUserFacts: statisticsGetterTypes.getCurrentUserFacts,
            getProjectFacts: statisticsGetterTypes.getProjectFacts,
        }),
        isEmptyFilters: function() {
            return !this.filters.cluster_id || !this.filters.project_id || !this.filters.from || !this.filters.to;
        },
        clustersArray: function() {
            return this.clusters.map(item => {
                return {id: item.id, name: item.name};
            });
        },
        projectsArray: function() {
            return this.projects.map(item => {
                return {id: item.id, name: item.name};
            });
        }
    },
    async created() {
        await this.$store.dispatch(listsActionTypes.getClusters);
    },
    methods: {
        async selectCluster(item) {
            this.selectedCluster = item.name;
            Object.assign(this.filters, {
                cluster_id: item.id,
                project_id: null
            });
            await this.$store.dispatch(listsActionTypes.getProjects, item.id);
        },
        async selectProject(item) {
            this.selectedProject = item.name;
            Object.assign(this.filters, {
                project_id: item.id
            });
        },
        async loadAnalytics() {
            let params = Object.assign({}, this.filters, {id: this.filters.project_id, users: this.currentUser.id});
            await this.$store.dispatch(statisticsActionTypes.getProjectFacts, params);
            this.userItems = this.getCurrentUserFacts;
            this.projectItems = this.getProjectFacts;
        }
    }
};
</script>

<style scoped>
.analytics__wrap {
    border-radius: 8px;
    background-color: #FFFFFF;
    margin-top: 20px;
}

.analytics__content {
    padding: 20px 76px;
    width: 70%;
}

.content__filters {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content__nav {
    margin: 0 10px;
    display: flex;
}

.content__table {
    margin: 50px 10px;
}

.input-block:not(:last-child) {
    margin-right: 20px;
}

.dropdown-filter {
    min-width: 284px;
}

.input-block__noedit .el-date-editor >>> input {
    background-color: #ECF1F4;
}

.btn-show-analytic {
    min-width: 284px;
}
</style>
