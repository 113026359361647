<template>
    <div class='log-chat-col col'>
        <div class='chat-header'>
            <div class='chat-header__left'>
                <div class='chat-header__title h4'>
                    {{ selectedRoom ? selectedRoom.title : '-' }}
                    <a
                        class='edit-heading__edit-link svg-icon-edit'
                        href='#'
                        @click.prevent='isEditRoom = !isEditRoom'
                        v-if='canAddParticipantsByResearch'
                    ></a>
                </div>
                <div class='chat-header__sub-title'>
                    {{ selectedRoom.project_title }} {{ selectedRoom.well_titles ? '|' : '' }}
                    {{ selectedRoom.well_titles }}
                </div>
            </div>
            <div class='chat-header__right'>
                <div class='chat-header__search-button svg-icon-search' @click='showSearch'></div>
                <div class='chat-header__info-button svg-icon-info' @click='showInfo'></div>
            </div>
        </div>
        <div class='chat-wrap' v-loading='isLoading || isSearching'>
            <div
                ref='messagesContainer'
                class='chat-wrap__scroll-wrap'
                v-show='messages.length && !isLoading'
            >
                <infinite-loading
                    :identifier="selectedRoom ? selectedRoom.id : 'infLoadingID'"
                    direction='top'
                    @infinite='getMoreMessages'
                    ref='infiniteLoading'
                >
                    <div slot='no-more'></div>
                    <div slot='no-results'></div>
                </infinite-loading>
                <div
                    class='chat-message'
                    v-for='(message, index) in messages'
                    :key='message.id'
                    :class='{me: user.id === message.author.id}'
                >
                    <div
                        class='chat-message'
                        v-if='
                            index === 0 ||
                                getDate(messages[index].created_at) !== getDate(messages[index - 1].created_at)
                        '
                    >
                        <div class='message-start-date'>{{ getFullMonthDate(message.created_at) }}</div>
                    </div>
                    <text-message
                        v-if="message.type_of_message === 'text_message'"
                        :message='message'
                        :isShowAuthor='isShowAuthor(messages, index)'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                    />
                    <binary-message
                        v-if="message.type_of_message === 'binary_message'"
                        :message='message'
                        :isShowAuthor='isShowAuthor(messages, index)'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                    />
                    <issue-message
                        v-if="message.type_of_message === 'issue'"
                        :message='message'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                        @create-issue-answer='createIssueAnswer(message)'
                        :isShowAuthor='isShowAuthor(messages, index)'
                    />
                    <solution-message
                        v-if="message.type_of_message === 'solution'"
                        :message='message'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                        :isShowAuthor='isShowAuthor(messages, index)'
                    />
                    <solution-without-request-message
                        v-if="message.type_of_message === 'solution_without_request'"
                        :message='message'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                        :isShowAuthor='isShowAuthor(messages, index)'
                    />
                    <forward-message
                        v-if="message.type_of_message === 'forward_message'"
                        :message='message'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                        :isShowAuthor='isShowAuthor(messages, index)'
                    />
                    <struct-message
                        v-if="message.type_of_message === 'struct'"
                        :message='message'
                        @show-history='showHistory'
                        @show-forward='showForward'
                        @reply='answer'
                        :isShowAuthor='isShowAuthor(messages, index)'
                    />
                    <div class='chat-message' v-if='!messages[index + 1]'>
                        <close-message
                            v-if="selectedRoom.status === 'PASSIVE'"
                            :closeComment='selectedRoom.close_comment'
                        >
                        </close-message>
                    </div>
                    <div class='chat-message' v-if='typing && !messages[index + 1]'>
                        <typing-message :typing='typing' />
                    </div>
                    <div class='add-file-area' v-if='dragFile'>
                        <div class='add-file-area__placeholder h3'>Перетащите сюда файлы<br>для быстрой отправки
                        </div>
                    </div>
                </div>
                <!--                <div class='chat-anchors'>-->
                <!--                    <div class='chat-anchors__item' v-if='privateMessagesCount'>-->
                <!--                        <div class='chat-anchors__count'>{{ privateMessagesCount }}</div>-->
                <!--                        <i class='svg-icon-anc'></i>-->
                <!--                    </div>-->
                <!--                    <div class='chat-anchors__item down' v-if='unreadMessagesCount' @click='scrollDown'>-->
                <!--                        <div class='chat-anchors__count'>{{ messages.length }}</div>-->
                <!--                        <i class='base-icon-arrow-down'></i>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
            <div class='chat-wrap__empty-message h4' v-show='!messages.length && !isLoading'>
                Здесь пока никто<br />ничего не писал
            </div>

            <div class='chat-message' v-show='!messages.length'>
                <close-message
                    v-if="selectedRoom.status === 'PASSIVE'"
                    :closeComment='selectedRoom.close_comment'
                    v-show='!messages.length'
                ></close-message>
            </div>
        </div>
        <div class='chat-type-message'>
            <div class='chat-type-message__forwarded' v-if='showReply'>
                <div class='chat-type-message__forwarded-label'>Переслано от {{ reply.author.full_name }}</div>
                <div class='chat-type-message__forwarded-text'>{{ reply.data }}</div>
                <div class='chat-type-message__forwarded-close base-icon-delete-2' @click='closeReply'></div>
            </div>
            <div class='chat-type-message__forwarded' v-for='(item, fileKey) in fileList' :key='fileKey'>
                <div class='chat-type-message__forwarded-label'>Прикрепленный файл</div>
                <div class='chat-type-message__forwarded-text'>{{ item.name }}</div>
                <div class='chat-type-message__forwarded-close base-icon-delete-2' @click='removeFile(item.uid)'></div>
            </div>
            <el-popover v-model='showPopover' popper-class='chat-menu' placement='top-start' :visible-arrow='false'
                        trigger='click' :disabled='!canCreateMessage || selectedRoom.status === "PASSIVE"'>
                <div class='dropdown__item dropdown__type-right-click' @click='createRequest'>
                    <a href='#'><i class='svg-icon-rqst'></i><span>Отправить запрос</span></a>
                </div>
                <div
                    class='dropdown__item dropdown__type-right-click'
                    @click='createSolution'
                    v-if='canCreateIssueWithoutRequest'
                >
                    <a href='#'><i class='svg-icon-answer'></i><span>Добавить решение</span></a>
                </div>
                <div class='dropdown__item dropdown__type-right-click'>
                    <el-upload
                        class='upload-demo'
                        action='/api/v1/messages/'
                        :auto-upload='false'
                        ref='upload'
                        :file-list='fileList'
                        :show-file-list='false'
                        :on-change='handleChange'
                    >
                        <a href='#'><i class='svg-icon-add-file'></i><span>Прикрепить файл</span></a>
                    </el-upload>
                </div>
                <!--                <div class='dropdown__item dropdown__type-right-click disabled'>-->
                <!--                    <a href='#' @click.prevent=''><i class='svg-icon-privat-chat'></i><span>Приватный чат</span></a>-->
                <!--                </div>-->
                <div class='dropdown__item dropdown__type-right-click' @click='createTable'>
                    <a href='#' @click.prevent=''><i class='svg-icon-ecxel-1'></i><span>Таблица</span></a>
                </div>
                <!--                <div class='dropdown__item dropdown__type-right-click disabled'>-->
                <!--                    <a href='#' @click.prevent=''><i class='svg-icon-poll'></i><span>Создать опрос</span></a>-->
                <!--                </div>-->
                <div class='dropdown__item dropdown__type-right-click' @click='closeRoom' v-if='canClose'>
                    <a href='#'><i class='svg-icon-close-research'></i><span>Закрыть задачу</span></a>
                </div>
                <button class='chat-type-message__attachment-button svg-icon-action' slot='reference'></button>
            </el-popover>
            <el-input
                class='chat-type-message__input empty'
                placeholder='Введите сообщение'
                v-model='message'
                @keypress.enter.native='createMessage()'
                @keypress.native='showRecipients'
                @input='inputMessage'
                @change='messageChanged'
                :disabled='!canCreateMessage || selectedRoom.status === "PASSIVE"'
            >
            </el-input>
            <div v-if='message.length || fileList.length' class='chat-type-message__voice-button svg-icon-send'
                 @click='createMessage'></div>
            <!--            <div v-else class='chat-type-message__voice-button svg-icon-microphone'></div>-->
        </div>
        <edit-room v-if='isEditRoom' @close-edit-room='isEditRoom = !isEditRoom'></edit-room>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mutationTypes as listsMutationTypes} from '@/store/modules/lists';
import TextMessage from './messages/TextMessage';
import BinaryMessage from './messages/BinaryMessage';
import SolutionMessage from './messages/SolutionMessage';
import SolutionWithoutRequestMessage from './messages/SolutionWithoutRequestMessage';
import IssueMessage from './messages/IssueMessage';
import ForwardMessage from './messages/ForwardMessage';
import StructMessage from './messages/StructMessage';
import CloseMessage from './messages/CloseMessage';
import TypingMessage from './messages/TypingMessage';
import EditRoom from './forms/EditRoom';
import Dates from '@/mixins/Dates';
import FileLoaderMixin from '../../../../mixins/FileLoaderMixin';
import {can} from '@/helpers/permissions';

export default {
    name: 'GeoLogMessages',
    data() {
        return {
            model: {
                type_of_message: 'text_message',
                file_name: '',
                file: [],
                room: '',
                parent_message: '',
                data: '',
                responsible: '',
                issue_message: '',
                deadline: '',
                related_message: '',
                recipients: [],
                struct: ''
            },
            params: {
                messages: {
                    query: '',
                    limit: 20,
                    offset: 0
                }
            },
            showPopover: false,
            message: '',
            reply: {},
            viewMenu: false,
            showReply: false,
            top: '0px',
            left: '0px',
            dragFile: false,
            fileList: [],
            isSelectRecipient: false,
            isEditRoom: false,
            privateMessagesCount: 0,
            unreadMessagesCount: 5,
            isTyping: false,
            timer: '',
            isSearching: false
        };
    },
    components: {
        TextMessage,
        BinaryMessage,
        SolutionMessage,
        SolutionWithoutRequestMessage,
        IssueMessage,
        ForwardMessage,
        StructMessage,
        CloseMessage,
        TypingMessage,
        EditRoom
    },
    computed: {
        ...mapState({
            selectedRoom: state => state.lists?.selectedRoom,
            user: state => state.auth?.user,
            messages: state => state.lists?.messages || []
        }),
        canCreateMessage: function() {
            return can('createMessage');
        },
        canCreateIssueWithRequest: function() {
            return can('createIssueWithRequest');
        },
        canCreateIssueWithoutRequest: function() {
            return can('createIssueWithoutRequest');
        },
        canAddParticipantsByResearch: function() {
            return can('addParticipantsByResearch');
        },
        canClose: function() {
            return this.selectedRoom.is_owner || can('createEditCluster');
        }
    },
    props: {
        recipients: {
            type: Array,
            required: false
        },
        issueId: {
            type: Number,
            required: false
        },
        typing: {
            type: String,
            required: false
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    mixins: [Dates, FileLoaderMixin],
    mounted: function() {
        this.$watch('messages', async function(newMessages, oldMessages) {
            if (!oldMessages.length && newMessages.length) {
                this.scrollDown();
            }
            if (this.issueId) {
                let message = this.$el.querySelector(`#messageId-${this.issueId}`);
                if (message) {
                    this.scrollToEl(message);
                }
            }
        });
        this.$watch('isLoading', async function() {
            this.scrollDown();
        });

        this.$root.$on('open-message', message => {
            if (message.id) {
                let existsMessage = this.$el.querySelector(`#messageId-${message.id}`);
                if (existsMessage) {
                    this.scrollToEl(existsMessage);
                } else {
                    this.searchMessage(message);
                }
            }
        });

        this.$root.$on('recepient-is-selected', recipient => {
            this.message = this.message.replace(/@([^@]*)$/g, '');
            this.message += `@${recipient.full_name} `;
        });

        this.$parent.$on('message-is-received', () => {
            this.scrollDown();
        });

        this.initFileLoader(
            this.$refs.messagesContainer,
            () => (this.dragFile = true),
            () => (this.dragFile = false)
        );
    },
    beforeDestroy() {
        this.$root.$off("open-message");
        this.$root.$off("recepient-is-selected");
        this.$root.$off("message-is-received");
    },
    watch: {
        '$route.params.id': {
            handler: async function() {
                this.params.messages.offset = 0;
            }
        }
    },
    methods: {
        async getMoreMessages($state) {
            let oldMessagesLength = this.messages.length;
            this.params.messages = Object.assign(this.params.messages, {
                offset: this.params.messages.offset + 20,
                reloadMessages: false
            });
            await this.$store.dispatch(listsActionTypes.getRoomMessages, {
                id: this.selectedRoom?.id,
                ...this.params.messages
            });

            if (oldMessagesLength === this.messages.length) {
                $state.complete();
            } else {
                $state.loaded();
            }
        },
        inputMessage() {
            this.sendIsTyping();
            let pattern = new RegExp(/[@][а-яА-Яa-zA-Z]+/gm);
            let recipientFio = this.message
                .match(pattern)
                ?.pop()
                .replace('@', '');
            this.$emit('search-recipients', recipientFio);
        },
        sendIsTyping() {
            if (!this.isTyping) {
                this.$store.dispatch(listsActionTypes.sendIsTyping, {id: this.selectedRoom.id});
                this.isTyping = true;
                this.timer = setTimeout(() => {
                    this.isTyping = false;
                }, 1000);
            }
        },
        async searchMessage(message) {
            this.isSearching = true;
            this.params.messages.limit = message.offset + 19;
            await this.$store.dispatch(listsActionTypes.getRoomMessages, {
                id: this.selectedRoom?.id,
                ...this.params.messages,
                reloadMessages: false
            });
            let existsMessage = this.$el.querySelector(`#messageId-${message.id }`);
            this.scrollToEl(existsMessage);
            this.isSearching = false;
        },

        messageChanged() {
            this.$emit('message-changed', this.message);
        },
        showRecipients(k) {
            if (k.key === '@') {
                this.$emit('show-recipients');
            }
        },
        handleChange(file) {
            this.showPopover = false;
            this.fileList.push(file);
        },
        removeFile(uuid) {
            this.fileList.splice(this.fileList.findIndex(e => e.uuid === uuid) - 1, 1);
        },
        showInfo() {
            this.$emit('show-info');
        },
        showSearch() {
            this.$emit('show-search');
        },
        async createMessage() {
            if (!this.message && !this.fileList.length) {
                return;
            }
            this.model = Object.assign(this.model, {
                data: this.message,
                responsible: this.user.id,
                room: this.selectedRoom.id,
                parent_message: this.reply ? this.reply.id : '',
                recipients: this.fileList.length ? [] : this.recipients,
                files: this.fileList, //TODO: files
                author: {id: this.user.id},
                created_at: new Date(),
                temp: true
            });
            this.$store.commit(listsMutationTypes.addTempMessage, this.model);
            await this.$store.dispatch(listsActionTypes.createMessage, this.model);
            this.$store.commit(listsMutationTypes.removeTempMessage);
            this.$emit('on-message-sent', {model: this.model});
            this.message = '';
            this.fileList = [];
            this.closeReply();
        },
        createRequest() {
            this.showPopover = false;
            this.$emit('create-request');
        },
        async closeRoom() {
            this.showPopover = false;
            this.$emit('close-room');
        },
        createSolution() {
            this.showPopover = false;
            this.$emit('create-solution');
        },
        createTable() {
            this.showPopover = false;
            this.$emit('create-table');
        },
        createIssueAnswer(message) {
            this.showPopover = false;
            this.$emit('create-issue-answer', message.content_object.id);
        },
        isShowAuthor(messages, index) {
            return index === 0 || messages[index].author.id !== messages[index - 1].author.id;
        },

        answer(message) {
            this.reply = message;
            this.showReply = true;
        },
        closeReply() {
            this.reply = {};
            this.showReply = false;
        },
        showHistory(id) {
            this.$emit('show-history', id);
        },
        showForward(id) {
            this.$emit('show-forward', id);
        },
        scrollToEl(el) {
            if (this.$refs.messagesContainer) {
                this.$refs.messagesContainer.scrollTop = el.offsetTop - 20;
                el.classList.add('highlighted-message');
                setTimeout(() => {
                    el.classList.remove('highlighted-message');

                }, 3000);
            }
        },
        scrollDown() {
            this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight;
        }
    }
};
</script>

<style>
.chat-type-message__input.empty.el-input > input {
    border: none !important;
    height: auto !important;
    line-height: normal !important;
    font-size: 16px;
}

.chat-header {
    justify-content: flex-end;
}

.chat-header__left {
    flex: 1;
}

.search-form__input.messages {
    width: 50%;
}

.popover-menu {
    position: fixed;
    display: flex;
    align-items: flex-end;
    background-color: #ffffff;
    flex-wrap: wrap;
}

.el-popover {
    padding: 0 !important;
}

.dropdown__item.dropdown__type-right-click.disabled {
    background-color: #ecf1f4;
    color: #b2c6d2;
}

.el-input__inner {
    background-color: inherit !important;
}

.highlighted-message .message-cloud__wrap{
    animation: highlight 5000ms ease-out;
}

@keyframes highlight {
    10% {
        -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 150, 235, 1);
        -moz-box-shadow: 0px 0px 0px 2px rgba(0, 150, 235, 1);
        box-shadow: 0px 0px 0px 2px rgba(0, 150, 235, 1);
    }
    100% {
        -webkit-box-shadow: unset;
        -moz-box-shadow: unset;
        box-shadow: unset;
    }
}
</style>
