<template>
    <div class='col-2 project-list-l2-col'>
        <ul class='projects-list-l2'>
            <li
                class='projects-list-l2__item'
                v-for='(area, index) in licenseAreas'
                :key='index'
                :class="{'current': selectedLicenceArea && area.id === selectedLicenceArea.id}"
                v-on:click='selectArea(area.id)'
            >
                <span class='projects-list-l2__content'>
                  <span>{{ area.name }}</span><i class='svg-icon-arrow-right'></i>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'LicenceAreasList',
    props: {
        licenseAreas: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState({
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            selectedProject: state => state.lists?.selectedProject,
        })
    },
    methods: {
        selectArea(id) {
            this.$emit('select-licence-area', id);
        }
    }
};
</script>

<style scoped>

</style>
