<template>
    <div>
        <div class='input-block' v-for='(cluster) in clusters' :key='cluster.id'>
            <div v-if='cluster.projects.length'>
                <label class='input-block__label'>
                    <span>{{ cluster.name.toUpperCase() }}</span>
                </label>
                <div class='input-block__buttons'>
                    <label class='chips-selector' v-for='project in cluster.projects' :key='project.id'>
                        <input type='checkbox' v-model='selected' :value='project.id' @change='select(project.id)'/>
                        <span> <i class='base-icon-delivered'></i>{{ project.name }} </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'GeoLogProjectsFilter',
    data() {
        return {
            selected: []
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            clusters: state => state.lists?.clusters || []
        })
    },
    methods: {
        select(id) {
            this.selected = [id];
            this.model.projects = id;
        }
    }
};
</script>
