<template>
    <div class='popup'>
        <div class='popup__overlay'></div>
        <div class='popup__block'>
            <div class='popup__top'>
                <div class='popup__title'>Новая задача</div>
                <div class='popup__close base-icon-close' @click='closeModal'></div>
            </div>
            <div class='popup__wrap'>
                <div class='form-label'>KPI</div>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Бюджет (тысяч рублей без НДС)</span>
                        <em>*</em>
                    </label>
                    <input
                        class='input-block__input'
                        type='number'
                        name='budget'
                        required='required'
                        v-model='model.budget'
                    />
                </div>
                <div class='input-block period-block'>
                    <label class='input-block__label'><span>Сроки</span><em>*</em> </label>
                    <div class='period-cols'>
                        <div class='period-cols__col'>
                            <el-date-picker
                                v-model='model.start_date'
                                type='daterange'
                                start-placeholder='С'
                                end-placeholder='По'
                                format='dd.MM.yyyy'
                                value-format='yyyy-MM-ddTHH:mm:ss'
                                :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>Качество</span>
                        <em>*</em>
                    </label>
                    <el-input v-model='model.quality_parameters' type='textarea' :rows='4' required='required'>
                    </el-input>
                </div>
                <div class='input-block'>
                    <label class='input-block__label'>
                        <span>HSE</span>
                        <em>*</em>
                    </label>
                    <el-input v-model='model.hse_parameters' type='textarea' :rows='4' required='required'>
                    </el-input>
                </div>
            </div>
            <div class='popup__buttons'>
                <button class='button small secondary' type='button' @click='prevStep'>
                    <span>Назад</span>
                </button>
                <button class='button small' type='submit' @click='nextStep' :disabled='isDisabled'>
                    <span>Далее</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
export default {
    name: 'AdmTaskModalStep2',
    data() {
        return {
            date: '',
        };
    },
    props: {
        model: {
            type: Object,
            required: true
        },
    },
    computed: {
        isDisabled: function() {
            return !(this.model.budget && this.model.start_date && this.model.quality_parameters && this.model.hse_parameters);
        }
    },
    async created() {

    },
    methods: {
        nextStep() {
            this.$emit('setStep', 'step3');
        },
        prevStep() {
            this.$emit('setStep', 'step1');
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.admTaskModalClose);
        }
    }
};
</script>
