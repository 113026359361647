<template>
    <div class='log-information col-3 create-request'>
        <div class='log-information__top log-tasks__top'>
            <div class='log-information__title log-tasks__title h3'>Создать запрос</div>
            <div class='log-information__close-button base-icon-close' @click='closeRequest'></div>
        </div>
        <div class='log-information__wrap'>
            <div class='log-information__scroll-wrap'>
                <div class='input-block select-user-block'>
                    <label class='input-block__label'><span>Кому</span><em>*</em> </label>
                    <el-select v-model='model.responsible' filterable>
                        <el-option
                            v-for='item in availableRecipients'
                            :key='item.id'
                            :label='item.full_name'
                            :value='item.id'
                        ></el-option>
                    </el-select>
                </div>
                <div class='input-block'>
                    <label class='input-block__label'><span>Содержание запроса</span><em>*</em></label>
                    <el-input
                        v-model='model.data'
                        type='textarea'
                        :rows='4'
                        required='required'
                    ></el-input>
                </div>
                <div class='form-label'>ОТВЕТИТЬ ДО</div>
                <div class='input-block'>
                    <label class='input-block__label'><span>Дата</span> </label>
                    <el-date-picker
                        v-model='date'
                        type='date'
                        format='dd.MM.yyyy'
                        value-format='yyyy-MM-dd'
                        placeholder='дд.мм.гггг'
                        :picker-options='{
                                    firstDayOfWeek: 1
                                }'
                    >
                    </el-date-picker>
                </div>
<!--                <div class='input-block'>-->
<!--                    <label class='input-block__label'><span>Время</span><em>*</em> </label>-->
<!--                    <el-time-select-->
<!--                        v-model='time'-->
<!--                        :picker-options="{-->
<!--                            start: '00:00',-->
<!--                            step: '00:15',-->
<!--                            end: '23:59'-->
<!--                        }"-->
<!--                        placeholder='Спб (GMT+3)'-->
<!--                    >-->
<!--                    </el-time-select>-->
<!--                </div>-->
                <div class='log-information__buttons'>
                    <button class='button small' type='submit' @click='createRequest' :disabled='isDisabled || !canCreateRequest'>
                        <span>Создать запрос </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {actionTypes as guidesActionTypes} from '@/store/modules/guides';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';
import {mapState} from 'vuex';
import {can} from '@/helpers/permissions';

export default {
    name: 'GeoLogRoomRequest',
    data() {
        return {
            model: {
                type_of_message: 'issue',
                file_name: '',
                file: [],
                room: '',
                parent_message: '',
                data: '',
                responsible: '',
                issue_message: '',
                deadline: '',
                related_message: '',
                recipients: [],
                struct: ''
            },
            date: '',
            time: ''
        };
    },
    async created() {
        await this.$store.dispatch(guidesActionTypes.getAvailableRecipients, this.selectedRoom.id);
    },
    computed: {
        ...mapState({
            availableRecipients: state => state.guides?.availableRecipients || [],
            selectedRoom: state => state.lists?.selectedRoom,
            user: state => state.auth?.user
        }),
        isDisabled: function() {
            return !(this.model.responsible && this.model.data && this.date);
        },
        canCreateRequest: function() {
            return can('createRequest');
        },
    },
    methods: {
        async createRequest() {
            this.model = Object.assign(this.model, {
                room: this.selectedRoom.id,
                deadline: this.date
            });
            await this.$store.dispatch(listsActionTypes.createMessage, this.model);
            this.$emit('on-message-sent', {model: this.model});
            this.$emit('close-request');
        },
        closeRequest() {
            this.$emit('close-request');
        },
    }
};
</script>
