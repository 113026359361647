<template>
    <div>
        <step1 :model='model' v-on:setStep='setStep' v-if="step === 'step1'"></step1>
        <step2 :model='model' v-on:setStep='setStep' v-if="step === 'step2'"></step2>
        <step3 :model='model' v-on:setStep='setStep' v-if="step === 'step3'"></step3>
        <step4 :model='model' v-on:setStep='setStep' v-if="step === 'step4'"></step4>
        <step5 :model='model' v-on:setStep='setStep' v-on:createTask='createTask' v-if="step === 'step5'"></step5>
    </div>
</template>

<script>
import {mutationTypes as modalMutationTypes} from '@/store/modules/modals';
import {mapState} from 'vuex';
import Step1 from '@/views/structure/components/modals/geo-task/Step1';
import Step2 from '@/views/structure/components/modals/geo-task/Step2';
import Step3 from '@/views/structure/components/modals/geo-task/Step3';
import Step4 from '@/views/structure/components/modals/geo-task/Step4';
import Step5 from '@/views/structure/components/modals/geo-task/Step5';
import {actionTypes as listsActionTypes} from '@/store/modules/lists';

export default {
    name: 'GeoTaskModal',
    data() {
        return {
            model: {
                title: '',
                type_of_chat: '',
                status: '',
                type_of_research: '',
                budget: '',
                start_date: '',
                end_date: '',
                quality_parameters: '',
                hse_parameters: '',
                cluster: '',
                project: '',
                license_area: '',
                well: '',
                participants: [],
                user_tags: [],
                administrators: '',
                parent_room: '',
                parent_room_issue: '',
                wells: ''
            },
            step: 'step1'
        };
    },
    computed: {
        ...mapState({
            selectedCluster: state => state.lists?.selectedCluster,
            selectedProject: state => state.lists?.selectedProject,
            selectedLicenceArea: state => state.lists?.selectedLicenceArea,
            selectedWell: state => state.lists?.selectedWell
        })
    },
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        Step5
    },
    async created() {
        this.model = Object.assign(
            {cluster: this.selectedCluster.id},
            {project: this.selectedProject.id},
            {license_area: this.selectedLicenceArea.id},
            {well: this.selectedWell.id},
            {participants: this.model.participants},
            {user_tags: this.model.user_tags},
            {type_of_chat: 'geo'},
            this.model.title
        );
    },
    methods: {
        async createTask() {
            this.closeModal();
            this.model.participants = this.model.participants.map(({id}) => id);
            await this.$store.dispatch(listsActionTypes.createTask, this.model);
            await this.$store.dispatch(listsActionTypes.getWellRooms, this.selectedWell.id);
        },
        setStep(step) {
            this.step = step;
        },
        closeModal() {
            this.$store.commit(modalMutationTypes.geoTaskModalClose);
        }
    }
};
</script>
