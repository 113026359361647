const getClusters = () => {
    return {
        path: 'lists/clusters',
        method: 'list'
    };
};

const getProjects = id => {
    return {
        path: `lists/clusters/projects?id=${id}`,
        method: 'get'
    };
};

const getLicenseAreas = id => {
    return {
        path: `lists/projects/license_areas?id=${id}`,
        method: 'get'
    };
};

const getWells = id => {
    return {
        path: `lists/license-areas/wells?id=${id}`,
        method: 'get'
    };
};

const getWellsByAreas = id => {
    return {
        path: `lists/wells/by-areas?areas=${id}`,
        method: 'get'
    };
};

const getIssues = ({query, limit, offset, input}) => {
    return {
        path: `issues?query=${query}&limit=${limit}&offset=${offset}&in=${input}`,
        method: 'list'
    };
};

const getStatistics = id => {
    return {
        path: `lists/clusters/statistics?id=${id}`,
        method: 'get'
    };
};

const getPersonalRooms = ({limit, offset}) => {
    return {
        path: `rooms/personal?limit=${limit}&offset=${offset}`,
        method: 'get'
    };
};

const getWorkRooms = ({query, limit, offset}) => {
    return {
        path: `rooms/work?query=${query}&limit=${limit}&offset=${offset}`,
        method: 'get'
    };
};

const getRoomMessages = ({id, query, limit, offset}) => {
    return {
        path: `rooms/messages?id=${id}&query=${query}&limit=${limit}&offset=${offset}`,
        method: 'get'
    };
};

const getRoomSolutions = ({ id }) => {
    return {
        path: `rooms/messages?id=${id}&limit=20&offset=0&type_of_message=solution`,
        method: 'get'
    };
};

const getRoomInfo = id => {
    return {
        path: `rooms?id=${id}`,
        method: 'get'
    };
};

const getRoomDocuments = id => {
    return {
        path: `rooms/documents?id=${id}`,
        method: 'get'
    };
};

const getRoomMedia = id => {
    return {
        path: `rooms/documents?id=${id}&ext=video,audio`,
        method: 'get'
    };
};

const getWellRooms = id => {
    return {
        path: `lists/wells/rooms?id=${id}`,
        method: 'get'
    };
};

const getTypeOfResearchRooms = id => {
    return {
        path: `lists/types-of-research/rooms?id=${id}`,
        method: 'get'
    };
};

const getLicenseAreaRooms = id => {
    return {
        path: `lists/license-areas/rooms?id=${id}`,
        method: 'get'
    };
};

const getProjectRooms = id => {
    return {
        path: `lists/projects/rooms?id=${id}`,
        method: 'get'
    };
};

const createCluster = ({name, cluster_type, owner}) => {
    return {
        path: 'lists/clusters',
        method: 'post',
        data: {
            name,
            cluster_type,
            owner
        }
    };
};

const updateCluster = ({id, name, cluster_type, owner}) => {
    return {
        path: `lists/clusters?id=${id}/`,
        method: 'put',
        data: {
            name,
            cluster_type,
            owner
        }
    };
};

const createProject = ({name, cluster, owner, additional_owners}) => {
    return {
        path: 'lists/projects',
        method: 'post',
        data: {
            name,
            cluster,
            status: 'ACTIVE',
            owner,
            additional_owners
        }
    };
};

const updateProject = ({id, name, cluster, status, owner, additional_owners}) => {
    return {
        path: `lists/projects?id=${id}/`,
        method: 'put',
        data: {
            name,
            cluster,
            status,
            owner,
            additional_owners
        }
    };
};

const createLicenseArea = ({name, project}) => {
    return {
        path: 'lists/license-areas',
        method: 'post',
        data: {
            name,
            project
        }
    };
};

const updateLicenseArea = ({id, name, project}) => {
    return {
        path: `lists/license-areas?id=${id}/`,
        method: 'put',
        data: {
            name,
            project
        }
    };
};

const createWell = ({name, field, license_area}) => {
    return {
        path: 'lists/wells',
        method: 'post',
        data: {
            name,
            status: 'ACTIVE',
            field,
            license_area
        }
    };
};

const updateWell = ({id, name, status, field, license_area}) => {
    return {
        path: `lists/wells?id=${id}/`,
        method: 'put',
        data: {
            name,
            status,
            field,
            license_area
        }
    };
};

const addParticipants = ({id, participants}) => {
    return {
        path: `rooms/add-participants?id=${id}`,
        method: 'post',
        data: {
            participants
        }
    };
};

const removeParticipants = ({id, participants}) => {
    return {
        path: `rooms/remove-participants?id=${id}`,
        method: 'post',
        data: {
            participants
        }
    };
};

const createTask = ({
    title,
    type_of_chat,
    type_of_research,
    budget,
    start_date,
    end_date,
    quality_parameters,
    hse_parameters,
    cluster,
    project,
    license_areas,
    well,
    participants,
    user_tags,
    administrators,
    parent_room,
    parent_room_issue,
    wells
}) => {
    return {
        path: 'rooms',
        method: 'post',
        data: {
            title,
            type_of_chat,
            status: 'ACTIVE',
            type_of_research,
            budget,
            start_date,
            end_date,
            quality_parameters,
            hse_parameters,
            cluster,
            project,
            license_areas,
            well,
            participants,
            user_tags,
            administrators,
            parent_room,
            parent_room_issue,
            wells
        }
    };
};

const updateTask = ({
    id,
    title,
    status,
    type_of_research,
    budget,
    start_date,
    end_date,
    quality_parameters,
    hse_parameters,
    cluster,
    project,
    license_areas,
    well,
    participants,
    user_tags,
    administrators,
    parent_room,
    parent_room_issue,
    wells
}) => {
    return {
        path: `rooms?id=${id}`,
        method: 'put',
        data: {
            title,
            status: status,
            type_of_research,
            budget,
            start_date,
            end_date,
            quality_parameters,
            hse_parameters,
            cluster,
            project,
            license_areas,
            well,
            participants,
            user_tags,
            administrators,
            parent_room,
            parent_room_issue,
            wells
        }
    };
};

const createMessage = ({
    type_of_message,
    file_name,
    file,
    room,
    parent_message,
    data,
    responsible,
    issue_message,
    deadline,
    related_message,
    recipients,
    struct
}) => {
    return {
        path: 'messages',
        method: 'post',
        data: {
            type_of_message,
            file_name,
            file,
            room,
            parent_message,
            data,
            responsible,
            issue_message,
            deadline,
            related_message,
            recipients,
            struct
        }
    };
};

const readMessage = ({id, roomId, owner}) => {
    return {
        path: `rooms/message/read?id=${id}&room=${roomId}`,
        method: 'post',
        data: {
            owner
        }
    };
};

const closeRoom = ({id, comment}) => {
    return {
        path: `rooms/close?id=${id}`,
        method: 'post',
        data: {
            comment
        }
    };
};

const sendIsTyping = ({id}) => {
    return {
        path: `rooms/typing?id=${id}`,
        method: 'control'
    };
};

export default {
    getClusters,
    getProjects,
    getLicenseAreas,
    getWells,
    getWellsByAreas,
    getIssues,
    getStatistics,
    getPersonalRooms,
    getWorkRooms,
    getTypeOfResearchRooms,
    getLicenseAreaRooms,
    getRoomMessages,
    getRoomSolutions,
    getRoomInfo,
    getRoomDocuments,
    getRoomMedia,
    getWellRooms,
    getProjectRooms,
    createCluster,
    updateCluster,
    createProject,
    updateProject,
    createLicenseArea,
    updateLicenseArea,
    createWell,
    updateWell,
    createTask,
    updateTask,
    createMessage,
    readMessage,
    closeRoom,
    addParticipants,
    removeParticipants,
    sendIsTyping
};
